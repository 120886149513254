import moment from 'moment'

const sanitiseDateString = (date) =>
  date === '0001-01-01T00:00:00' ? null : date

const sanitiseName = (name) => (name === 'None None' ? '' : name)

const isNumber = (x) => typeof x === 'number'

const transformLine = (line) => {
  const {
    clientManager,
    projectManager,
    projectDirector,
    projectBudget = 0,
    projectStartDate,
    projectEndDate,
    historicExpenses = 0,
    billedToDate = 0,
    historicUnbilledWip,
    wipForPeriod,
    periodExpenses = 0,
    invoiceAmount = 0,
  } = line

  const totalWip =
    isNumber(wipForPeriod) && isNumber(historicUnbilledWip)
      ? wipForPeriod + historicUnbilledWip
      : null

  const wipBalance = isNumber(totalWip)
    ? totalWip + periodExpenses - invoiceAmount
    : null

  const projectedMargin = 20 // temporarily hard-coded 20% profit margin
  const internalBudget = projectBudget / (1 + projectedMargin / 100)
  const profitToDate = billedToDate - historicExpenses
  const periodProfit = invoiceAmount - periodExpenses

  return {
    ...line,
    clientManager: sanitiseName(clientManager),
    projectManager: sanitiseName(projectManager),
    projectDirector: sanitiseName(projectDirector),
    projectStartDate: sanitiseDateString(projectStartDate),
    projectEndDate: sanitiseDateString(projectEndDate),
    totalWip,
    wipBalance,
    internalBudget,
    projectedProfit: projectBudget - internalBudget,
    projectedMargin,
    profitToDate,
    marginToDate: (profitToDate / billedToDate) * 100,
    periodProfit,
    periodMargin: (periodProfit / invoiceAmount) * 100,
  }
}

function generateTotalLine(lines) {
  const sumLine = { jiraKey: 'Total' }
  const columnsToSum = [
    'initialBudget',
    'historicVariations',
    'internalBudget',
    'projectBudget',
    'billedToDate',
    'historicExpenses',
    'wipForPeriod',
    'historicUnbilledWip',
    'totalWip',
    'writeUpOffToDate',
    'expectedVariations',
    'invoiceAmount',
    'periodExpenses',
    'wipBalance',
    'provisionalAmount',
    'writeUpOffAmount',
    'agedDebt',
    'agedDebt3Months',
  ]

  lines.forEach((line, i) => {
    columnsToSum.forEach((columnName) => {
      if (i === 0) {
        sumLine[columnName] = 0
      }
      sumLine[columnName] += line[columnName] || 0
    })
  })

  return sumLine
}

const applyEdits = (editedLines) => (line) => ({
  ...line,
  ...(editedLines[line.projectId] || {}),
})

const getFilter = (filterItem) => {
  const { operatorValue, columnField, value } = filterItem
  switch (operatorValue) {
    case 'contains':
      return (line) =>
        !value || line[columnField].toLowerCase().includes(value.toLowerCase())
    case 'before':
      return (line) => moment(line[columnField]) < moment(value)
    case 'after':
      return (line) => moment(line[columnField]) > moment(value)
    case '=':
      return (line) =>
        Math.round(line[columnField] || 0) === Math.round(Number(value))
    case '<':
      return (line) =>
        Math.round(line[columnField] || 0) < Math.round(Number(value))
    case '>':
      return (line) =>
        Math.round(line[columnField] || 0) > Math.round(Number(value))
    case '<=':
      return (line) =>
        Math.round(line[columnField] || 0) <= Math.round(Number(value))
    case '>=':
      return (line) =>
        Math.round(line[columnField] || 0) >= Math.round(Number(value))
    case 'isnull':
      return (line) => line[columnField] === null
    case 'is':
      return (line) => !value || line[columnField] === Number(value)
    default:
      return () => true
  }
}

const applyFilters = (filterModel) => {
  const { items = [] } = filterModel
  const allFilters = items.map(getFilter)
  return (line) => allFilters.every((f) => f(line))
}

const applySort = (sortModel) => {
  const { field, sort } = sortModel[0] || {} // currently only sorting by one row is supported
  if (sort === null) {
    return () => 0
  }
  return (a, b) => {
    const aField = a[field]
    const bField = b[field]

    const aNull = aField === null || aField === undefined
    const bNull = bField === null || bField === undefined

    const sortOrder = sort === 'asc' ? 1 : -1

    if (aNull || bNull) {
      return sortOrder * (bNull - aNull)
    }
    if (typeof aField === 'string') {
      return sortOrder * aField.localeCompare(bField)
    }
    return sortOrder * (aField - bField)
  }
}

function makeRows(lines, editedLines, filterModel, sortModel, pageSize, page) {
  const newLines = lines
    .filter((line) => line.jiraKey !== 'Total')
    .map(applyEdits(editedLines || {}))
    .map(transformLine)
    .filter(applyFilters(filterModel || {}))
    .sort(applySort(sortModel || []))

  // generate totals (pre-pagination)
  const totalRowCount = newLines.length
  const totalLine = transformLine(generateTotalLine(newLines))

  // pagination
  const startIndex = pageSize * page
  const endIndex = Math.min(startIndex + pageSize, totalRowCount)

  const rows = newLines
    .slice(startIndex, endIndex)
    .concat(totalLine)
    // manually add IDs to line to handle legacy method 'onCellEditCommit', must be set at end
    .map((line, i) => ({ ...line, id: i }))

  // convert to rows for table and return
  return { totalRowCount, rows }
}

const getRowClassName = (params) =>
  params.row.jiraKey === 'Total'
    ? 'totalRow'
    : params.id % 2 === 0
    ? 'evenRow'
    : 'oddRow'

export { makeRows, getRowClassName }
