import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import resetProjectState from './resetProjectState'
import api from '../utils/api'
import LoadingState from '../utils/LoadingState'

const initialState = {
  loadingState: LoadingState.idle,
  data: [],
  updateState: LoadingState.idle,
  updateError: null,
}

export const fetchProject = createAsyncThunk(
  'fetchProject',
  async ({ projectId, getTokenCallback }, { getState }) => {
    return await api.getData(
      api.endpoints(getState().client.name).projects.v1.get2(projectId),
      getTokenCallback
    )
  }
)

export const newProject = createAsyncThunk(
  'newProject',
  async ({ jiraKey, getTokenCallback }, { getState }) => {
    return await api.post(
      api.endpoints(getState().client.name).projects.v2.new(jiraKey),
      getTokenCallback
    )
  }
)

export const updateProject = createAsyncThunk(
  'updateProject',
  async ({ project, getTokenCallback }, { getState }) => {
    return await api.put(
      api.endpoints(getState().client.name).projects.v1.get(project.projectId),
      project,
      getTokenCallback
    )
  }
)

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
    resetUpdateError(state, action) {
      state.updateState = initialState.updateState
      state.updateError = initialState.updateError
    },
  },
  extraReducers: {
    [fetchProject.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchProject.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = state.error
    },
    [fetchProject.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload
    },
    [newProject.pending]: (state, action) => {
      state.updateState = LoadingState.pending
    },
    [newProject.rejected]: (state, action) => {
      state.updateState = LoadingState.rejected
      state.updateError = action.error.message
    },
    [newProject.fulfilled]: (state, action) => {
      state.updateState = LoadingState.fulfilled
      state.data = action.payload
    },
    [updateProject.pending]: (state, action) => {
      state.updateState = LoadingState.pending
    },
    [updateProject.rejected]: (state, action) => {
      state.updateState = LoadingState.rejected
      state.updateError = action.error.message
    },
    [updateProject.fulfilled]: (state, action) => {
      state.updateState = LoadingState.fulfilled
    },
    [resetProjectState]: (state, action) => {
      return initialState
    },
  },
})

export const { reset, resetUpdateError } = slice.actions

export const selectLoadingState = (state) => state.project.loadingState
export const selectProject = (state) => state.project.data
export const selectUpdateError = (state) => state.project.updateError
export const selectUpdateState = (state) => state.project.updateState

export default slice.reducer
