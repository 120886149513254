import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Button, IconButton, Menu, MenuItem } from '@mui/material'

const AuthButton = () => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return isAuthenticated ? (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <img
          src={user.picture}
          alt="Profile"
          style={{ borderRadius: 20 }}
          width="40"
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>{user.name}</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  ) : (
    <Button onClick={loginWithRedirect}>Log In</Button>
  )
}

export default AuthButton
