import { useCallback, useEffect, useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'

import Error from '../primitives/Error'
import Loading from '../primitives/Loading'
import {
  fetchChargeability as fetchChargeabilityAction,
  selectChargeability,
  selectLoadingState as selectChargeabilityLoadingState,
} from '../slices/chargeability'
import {
  fetchEmployees as fetchEmployeesAction,
  selectEmployees,
  selectLoadingState as selectEmployeesLoadingState,
} from '../slices/employees'

import ChargeabilityContent from '../views/ChargeabilityContent'
import LoadingState from '../utils/LoadingState'

const ChargeabilityContainer = () => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const chargeability = useSelector(selectChargeability)
  const employees = useSelector(selectEmployees)
  const chargeabilityLoadingState = useSelector(selectChargeabilityLoadingState)
  const employeesLoadingState = useSelector(selectEmployeesLoadingState)

  const fetchChargeability = useCallback(() => {
    dispatch(fetchChargeabilityAction({ getTokenCallback }))
  }, [dispatch, getTokenCallback])

  const fetchEmployees = useCallback(() => {
    dispatch(fetchEmployeesAction({ getTokenCallback }))
  }, [dispatch, getTokenCallback])

  useEffect(() => {
    if (chargeabilityLoadingState === LoadingState.idle) {
      fetchChargeability()
    }
  }, [fetchChargeability, chargeabilityLoadingState])

  useEffect(() => {
    if (employeesLoadingState === LoadingState.idle) {
      fetchEmployees()
    }
  }, [fetchEmployees, employeesLoadingState])

  document.title = 'Work Analysis'

  const chargeabilityData = useMemo(
    () => (chargeabilityLoadingState === LoadingState.fulfilled && chargeability ? chargeability : null),
    [chargeability, chargeabilityLoadingState]
  )

  const employeeData = useMemo(
    () => (employeesLoadingState === LoadingState.fulfilled && employees ? employees : null),
    [employees, employeesLoadingState]
  )

  if (
    chargeabilityLoadingState === LoadingState.idle ||
    chargeabilityLoadingState === LoadingState.pending ||
    employeesLoadingState === LoadingState.idle ||
    employeesLoadingState === LoadingState.pending
  ) {
    return <Loading />
  } else if (chargeabilityLoadingState === LoadingState.rejected) {
    return <Error error={chargeability} retryCallback={fetchChargeability} />
  } else if (employeesLoadingState === LoadingState.rejected) {
    return <Error error={employees} retryCallback={fetchEmployees} />
  } else {
    return <ChargeabilityContent employeeData={employeeData} {...chargeabilityData} />
  }
}

export default ChargeabilityContainer
