export function deepEqual(obj1, obj2) {
    // If both objects are null or undefined, they are equal
    if (obj1 === obj2) return true;
  
    // If one of the objects is null or undefined, they are not equal
    if (typeof obj1 === 'undefined' || typeof obj2 === 'undefined') return false;
  
    // If the objects are objects, check if they have the same properties and values
    if (typeof obj1 === 'object' && typeof obj2 === 'object') {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      for (let key of keys1) {
        if (!deepEqual(obj1[key], obj2[key])) return false;
      }
      return true;
    }
  
    // If none of the above conditions are met, the objects are not equal
    return false;
  }