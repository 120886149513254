import CategorySlider from '../primitives/CategorySlider'

const ChargeabilityTimeframeSlider = ({ timeframe, onChange }) => {
  const sliderValues = [
    { value: 0, label: 'WEEK' },
    { value: 1, label: 'MONTH' },
    { value: 2, label: 'YEAR' },
  ]

  return (
    <CategorySlider
      sliderValues={sliderValues}
      value={timeframe}
      onChange={onChange}
    />
  )
}

export default ChargeabilityTimeframeSlider
