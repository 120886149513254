import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import clientConfig from './clientConfig'
import config from './config'
import { set as setClient } from './slices/client'
import { userHasRole } from './utils/userUtils'
import {
  fetchAccessRights as fetchAccessRightsAction,
  selectAccessRightsLoadingState,
} from './slices/accessRights'
import LoadingState from './utils/LoadingState'

const defaultDevName = 'Project Hub (Development)'
const defaultTestName = 'Project Hub (Test)'
const defaultProdName = 'Project Hub'

const defaultAppDevLook = {
  light: 'lightPink',
  main: 'pink',
}
const defaultAppTestLook = {
  light: 'awaGreen15',
  main: 'awaGreen',
}
const defaultAppProdLook = {
  light: 'awaCharcoal21',
  main: 'awaCharcoal',
}

const defaultPaletteExtraColours = {
  primaryHighlight: {
    light: 'awaGreyBlue15',
    main: 'awaGreyBlue30',
    dark: 'awaGreyBlue50',
  },
  secondaryHighlight: {
    light: 'awaGreen15',
    main: 'awaGreen30',
    dark: 'awaGreen43',
  },
  editable: {
    light: 'awaCharcoal30',
    main: 'awaCharcoal21',
    dark: 'awaCharcoal5',
  },
  background: {
    light: 'white',
    main: 'awaNeutral15',
    dark: 'awaNeutral30',
  },
  text: {
    light: 'awaCharcoal50',
    main: 'awaCharcoal75',
    dark: 'awaCharcoal',
  },
}

const getContext = (client, path, user) => {
  const { auth0Roles, isDevLook, isTestLook, namespace } = config

  const hasRole = (role) => userHasRole(user, namespace, role)

  const { basicAccess, leadership, operations, reconciliation } = auth0Roles

  const {
    clientAccess = '',
    chargeabilityEnabled = false,
    invoiceSubmissionEnabled = false,
    invoicingApproachEnabled = false,
    jiraIntegrationEnabled = false,
    purchaseOrdersEnabled = false,
    timeCostReportEnabled = false,
    devLook = defaultAppDevLook,
    devName = defaultDevName,
    testName = defaultTestName,
    testLook = defaultAppTestLook,
    prodName = defaultProdName,
    prodLook = defaultAppProdLook,
    logoProps,
    cwbColumns,
    colours,
    palette,
    components,
  } = clientConfig[client]

  const appLook = isDevLook ? devLook : isTestLook ? testLook : prodLook
  const appName = isDevLook ? devName : isTestLook ? testName : prodName

  return {
    path,
    hasReconciliationAccess: hasRole(reconciliation),
    isLeadership: hasRole(leadership),
    isOperations: hasRole(operations),
    isStaffMember: hasRole(basicAccess) && hasRole(clientAccess),
    chargeabilityEnabled,
    invoiceSubmissionEnabled,
    invoicingApproachEnabled,
    jiraIntegrationEnabled,
    purchaseOrdersEnabled,
    timeCostReportEnabled,
    appName,
    logoProps,
    cwbColumns,
    colours,
    palette: { ...defaultPaletteExtraColours, ...palette, appLook },
    components,
  }
}

const ClientContext = createContext({})
export const useClientContext = () => useContext(ClientContext)

export const ClientContextProvider = ({ children, client, path }) => {
  const { user, getAccessTokenSilently: getTokenCallback, isAuthenticated } = useAuth0()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setClient(client))
  }, [client, dispatch])

  // On page load, fetch all access rights the user has access to
  const accessRightsLoadingState = useSelector(selectAccessRightsLoadingState)
  const fetchAccessRights = useCallback(() => {
    dispatch(fetchAccessRightsAction({ getTokenCallback }))
  }, [dispatch, getTokenCallback])

  useEffect(() => {
    // Include 'isAuthenticated' check to only fetch the access rights once the auth0 bearer token is available
    // If not checked, a race condition can occur where the call is made before the token is available.
    // This only occurs for this endpoint call (and not others) as the clientContext object this is included in is not wrapped in 
    // the 'WrappedRoute' component which uses the withAuthenticationRequired call.
    // This issue only occurs where third party cookies are disabled (such as in Brave)
    if (isAuthenticated && (accessRightsLoadingState === LoadingState.idle)) {
      fetchAccessRights()
    }
  }, [fetchAccessRights, accessRightsLoadingState, isAuthenticated])

  const value = useMemo(() => {
    return getContext(client, path, user)
  }, [client, path, user])

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  )
}

// HOCs for tests
const withPresetContext = (value) => (WrappedComponent) =>
  (
    <ClientContext.Provider value={value}>
      <WrappedComponent />
    </ClientContext.Provider>
  )

export const withChargeabilityEnabled = withPresetContext({
  chargeabilityEnabled: true,
})
export const withChargeabilityDisabled = withPresetContext({
  chargeabilityEnabled: false,
})

export const withInvoicingOptionalsEnabled = withPresetContext({
  invoiceSubmissionEnabled: true,
  purchaseOrdersEnabled: true,
})
export const withInvoicingOptionalsDisabled = withPresetContext({
  invoiceSubmissionEnabled: false,
  purchaseOrdersEnabled: false,
})

export const withTimeCostReportEnabled = withPresetContext({
  timeCostReportEnabled: true,
})
export const withTimeCostReportDisabled = withPresetContext({
  timeCostReportEnabled: false,
})
