import {
  AppBar,
  Container,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material'

import Nav from './Nav'
import AuthButton from '../primitives/AuthButton'
import colours from '../styles/colours'
import { useClientContext } from '../clientContext'

const drawerWidth = 60

const navBarProps = {
  drawerWidth,
  iconColor: colours.white,
  paperColor: 'appLook.main',
}

const appBarStyle = {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
}

const titleBarStyle = {
  backgroundColor: 'inherit',
  color: 'inherit',
  flexGrow: 1,
  fontSize: '1.8em',
  letterSpacing: '0.2em',
  textTransform: 'uppercase',
}

const AppFrame = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 64,
  })

  const { appName } = useClientContext()

  return (
    <div
      style={{
        backgroundColor: colours.backgroundDefault,
        width: 'fit-content',
        height: 'fit-content',
        minWidth: '100vw',
        minHeight: '100vh'
      }}
    >
      <Nav {...navBarProps} />
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar position="fixed" style={appBarStyle}>
          <Toolbar>
            <Typography style={titleBarStyle} variant="h5">
              {appName}
            </Typography>
            <AuthButton />
          </Toolbar>
        </AppBar>
      </Slide>
      <Container disableGutters maxWidth={false} sx={{ pl: 8, pt: 8 }}>
        {children}
      </Container>
    </div>
  )
}

export default AppFrame
