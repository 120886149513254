import { useCallback, useEffect, useRef } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import WorkItemDroppable from './WorkItemDroppable'

const NameField = ({ onChange, onChangeSaved, id, value, setEditing }) => {
  const ref = useRef()

  const handleBlur = useCallback(() => {
    setEditing(false)
    onChangeSaved()
  }, [setEditing, onChangeSaved])

  const handleKeyDown = (event) => {
    if (
      event.key === 'Escape' ||
      event.key === 'Esc' ||
      event.key === 'Enter' ||
      event.key === 'Return'
    ) {
      setEditing(false)
      onChangeSaved()
    }
  }

  useEffect(() => {
    // put cursor into element on component mount.
    if (ref.current) {
      ref.current.focus()
    }
  }, [])

  return (
    <TextField
      size="small"
      inputRef={ref}
      onBlur={handleBlur}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={handleKeyDown}
      sx={{
        p: 0,
        width: 300,
        '& input': {
          font: 'inherit',
          fontSize: 14,
          fontWeight: 'bold',
          pb: 0.5,
        },
      }}
      value={value}
      inputProps={{ 'data-testid': `${id}-name-input` }}
    />
  )
}

const LineItemDroppable = ({
  disabled,
  lineItem,
  workItems,
  onChangeName,
  onChangeSaved,
  onDelete,
  onExpandCollapse,
  onToggleBillable,
  onToggleEditing,
}) => {
  const ref = useRef()

  const handleToggleEditing = useCallback(
    (e) => {
      if (!lineItem.isProposalItem) {
        e.stopPropagation()
        onToggleEditing(lineItem.id, !lineItem.isEditing)
      }
    },
    [onToggleEditing, lineItem]
  )

  return (
    <Accordion
      defaultExpanded={true}
      elevation={0}
      expanded={lineItem.isExpanded}
      onChange={(_event, expanded) => {
        onExpandCollapse(lineItem.id, expanded)
      }}
      sx={{
        bgcolor: lineItem.isProposalItem
          ? 'none'
          : lineItem.error
          ? 'error.light'
          : 'editable.main',
        borderRadius: 1,
        border: lineItem.isProposalItem ? '1px solid' : 'none',
        borderColor: lineItem.isProposalItem ? 'editable.dark' : 'none',
        m: 0,
        mt: 1,
        minHeight: 'auto',
        minWidth: 500,
        '&.MuiAccordion-root.Mui-expanded:first-of-type': { mt: 1 },
        '&.Mui-expanded': { m: 0, mt: 1 },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${lineItem.id}-content`}
        id={`${lineItem.id}-header`}
        ref={ref}
        sx={{
          minHeight: 'auto',
          px: 1.75,
          '&.MuiAccordionSummary-root.Mui-expanded': { minHeight: 'auto' },
          '&.MuiAccordionSummary-root.Mui-focusVisible': {
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
          },
          '& .MuiAccordionSummary-content': { m: 0 },
          '& .MuiAccordionSummary-expandIconWrapper': { p: 1 },
        }}
      >
        {lineItem.isEditing ? (
          <NameField
            id={lineItem.id}
            onChange={(e) => onChangeName(lineItem.id, e.target.value)}
            onChangeSaved={onChangeSaved}
            value={lineItem.name}
            setEditing={(editing) => onToggleEditing(lineItem.id, editing)}
          />
        ) : (
          <h3
            style={{ fontSize: 14, margin: 0, paddingTop: 1 }}
            onClick={handleToggleEditing}
          >
            {lineItem.name}{' '}
            <span style={{ fontWeight: 'normal' }}>
              ({workItems.length} {workItems.length === 1 ? 'item' : 'items'})
            </span>
            {lineItem.isProposalItem ? (
              <span style={{ fontWeight: 'normal' }}> (Not Billable)</span>
            ) : null}
          </h3>
        )}
        {!lineItem.isProposalItem ? (
          <Box sx={{ ml: 0.5 }}>
            <IconButton
              aria-label="editName"
              disabled={disabled}
              onClick={handleToggleEditing}
              sx={{ p: 0.375 }}
            >
              <EditIcon style={{ fontSize: 16 }} />
            </IconButton>
            {lineItem.deletable ? (
              <IconButton
                aria-label="delete"
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete(lineItem.id)
                }}
                style={{ fontSize: 16 }}
              >
                <DeleteIcon style={{ fontSize: 16 }} />
              </IconButton>
            ) : null}
            <FormControlLabel
              label="Billable"
              sx={{
                fontSize: 14,
                '& .MuiTypography-root': { fontSize: 'inherit' },
                '& .MuiCheckbox-root': { color: 'secondary.main' },
              }}
              control={
                <Checkbox
                  area-label="billable-toggle"
                  size="small"
                  checked={lineItem.billable}
                  onClick={(e) => {
                    e.stopPropagation()
                    onToggleBillable(lineItem.id, !lineItem.billable)
                  }}
                  sx={{ fontSize: 14, ml: 2, mr: 0.5, p: 0 }}
                />
              }
            />
          </Box>
        ) : null}
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 0 }}>
        <WorkItemDroppable
          direction="horizontal"
          disabled={disabled || !lineItem.isExpanded}
          lineItem={lineItem}
          workItems={workItems}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default LineItemDroppable
