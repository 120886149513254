import config from '../config'

async function makeRequest(method, url, body, getTokenCallback) {
  if (getTokenCallback === undefined || getTokenCallback === null) {
    return Promise.reject('Invalid getTokenCallback.')
  }

  var token = null
  try {
    token = await getTokenCallback()
  } catch (error) {
    return Promise.reject(error.error || error.toString())
  }

  if (!token) {
    return Promise.reject('Invalid authorization token.')
  } else {
    try {
      const response = await fetch(url, {
        method: method,
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: body ? JSON.stringify(body) : undefined,
      })

      var responseText = await response.text()
      if (response.status === 200) {
        try {
          return JSON.parse(responseText)
        } catch {
          return responseText
        }
      } else {
        try {
          return Promise.reject(JSON.parse(responseText))
        } catch {
          return Promise.reject(responseText)
        }
      }
    } catch (error) {
      return Promise.reject(
        'Authorization failed. Please try again in a few seconds.'
      )
    }
  }
}

async function getData(url, getTokenCallback) {
  return await makeRequest('GET', url, null, getTokenCallback)
}

async function post(url, getTokenCallback) {
  return await makeRequest('POST', url, null, getTokenCallback)
}

async function put(url, body, getTokenCallback) {
  return await makeRequest('PUT', url, body, getTokenCallback)
}

const endpoints = (clientName) => {
  const baseUrl = `${config.apiOrigin[clientName]}/api`
  const accessRightUrl = `${baseUrl}/accessright`
  const cwbBaseUrl = `${baseUrl}/companyworkbooks`
  const peopleBaseUrl = `${baseUrl}/people`
  const projects1BaseUrl = `${baseUrl}/projects`
  const projects2BaseUrl = `${baseUrl}/v2/projects`

  return {
    accessRight: {
      all: () => `${accessRightUrl}/all`,
    },
    companyWorkbooks: {
      activate: (workbookId) => `${cwbBaseUrl}/${workbookId}/activate`,
      finalise: (workbookId) => `${cwbBaseUrl}/${workbookId}/finalise`,
      generate: (periodId) => `${cwbBaseUrl}/generate/${periodId}`,
      getForPeriod: (periodId) => `${cwbBaseUrl}/forperiod/${periodId}`,
      getInvoicingApproaches: () => `${cwbBaseUrl}/invoicingApproaches`,
      reactivate: (workbookId) => `${cwbBaseUrl}/${workbookId}/reactivate`,
      reconcile: (workbookId) => `${cwbBaseUrl}/${workbookId}/reconcile`,
      reopen: (workbookId) => `${cwbBaseUrl}/${workbookId}/reopen`,
      review: (workbookId) => `${cwbBaseUrl}/${workbookId}/review`,
      save: (workbookId) => `${cwbBaseUrl}/${workbookId}/save`,
      status: (workbookId) => `${cwbBaseUrl}/status/${workbookId}`,
      setStatus: (workbookId, statusName) =>
        `${cwbBaseUrl}/${workbookId}/setStatus/${statusName}`,
    },
    invoices: {
      save: () => `${baseUrl}/v2/invoices`,
    },
    groups: {
      all: () => `${peopleBaseUrl}/groups`,
    },
    people: {
      currentEmployees: () => `${peopleBaseUrl}/currentEmployees`,
      projectCandidates: (projectId) => `${peopleBaseUrl}/candidatesforproject/${projectId}`,
    },
    periods: {
      all: () => `${baseUrl}/invoicingperiods/all`,
    },
    projects: {
      v1: {
        all: () => `${projects1BaseUrl}/all`,
        get: (projectId) => `${projects1BaseUrl}/${projectId}`,
        get2: (projectId) => `${projects1BaseUrl}/get2/${projectId}`,
        invoiceDetails: (projectId, periodId) =>
          `${projects1BaseUrl}/${projectId}/invoicedetails${
            periodId ? `?periodId=${periodId}` : ''
          }`,
        staffRates: (projectId) =>
          `${projects1BaseUrl}/${projectId}/staffrates`,
      },
      v2: {
        mapping: (projectId) => `${projects2BaseUrl}/${projectId}/mapping`,
        new: (jiraKey) => `${projects2BaseUrl}/${jiraKey}`,
        timeCostReport: (projectId, periodId) =>
          `${projects2BaseUrl}/${projectId}/timecostreport?periodId=${periodId}`,
      },
    },
    work: {
      chargeability: () => `${baseUrl}/work/chargeability`,
    },
  }
}

const dataTransformers = {
  project: (responseBody) => {
    if (
      responseBody &&
      responseBody.projectList &&
      responseBody.projectList.length > 0
    ) {
      return responseBody.projectList[0]
    } else {
      return null
    }
  },
  projects: (responseBody) => {
    return responseBody.projectList
  },
  projectsMap: (responseBody) => {
    var projectMap = {}
    const projects = responseBody.projectList
    if (projects != null && projects.length > 0) {
      for (var project of projects) {
        projectMap[project.projectId] = project
      }
    }
    return projectMap
  },
  timeCostReport: (responseBody) => {
    return responseBody.data
  },
}

const api = { dataTransformers, endpoints, getData, post, put }

export default api
