import moment from 'moment'
import SelectorDropdown from '../primitives/SelectorDropdown'

const PeriodSelector = ({
  periods,
  ...props
}) => {

  const options = periods.map(period => ({
    children: `${moment(period.periodStart).format('D MMMM YYYY')
      } - ${moment(period.periodEnd).format('D MMMM YYYY')
      }`,
    value: period.id
  }))

  return <SelectorDropdown label="Period" options={options} {...props} />
}

export default PeriodSelector
