const root = {
  clear: 'both',
}

const cell = ({ theme }) => ({
  '&.normalCell': {
    color: theme.palette.text.dark,
  },
  '&.editableCell': {
    backgroundColor: theme.palette.primaryHighlight.main,
    borderRight: `1px solid ${theme.palette.primaryHighlight.light}`,
  },
  '&.redCell': {
    color: theme.palette.error.main,
  },
  '&.calculatedCell': {
    backgroundColor: theme.palette.secondary.light,
  },
})

const row = ({ theme }) => ({
  fontSize: '0.6875rem',
  '&.oddRow': {
    backgroundColor: theme.palette.primaryHighlight.light,
    '&:hover': { backgroundColor: theme.palette.primaryHighlight.light },
    '&.Mui-hovered': { backgroundColor: theme.palette.primaryHighlight.light },
  },
  '&.evenRow': {
    backgroundColor: theme.palette.background.light,
    '&:hover': { backgroundColor: theme.palette.background.light },
    '&.Mui-hovered': { backgroundColor: theme.palette.background.light },
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primaryHighlight.main,
    fontWeight: 'bold',
    '&:hover': { backgroundColor: theme.palette.primaryHighlight.main },
    '&.Mui-hovered': { backgroundColor: theme.palette.primaryHighlight.main },
  },
  '&.totalRow': {
    fontSize: '0.8125rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.primaryHighlight.light,
  },
})

const columnHeader = ({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.light,
  fontSize: 12,
  textTransform: 'uppercase',
  lineHeight: '40px',
  paddingLeft: '4px',
  paddingRight: 0,
  boxSizing: 'inherit',
})

const columnHeaders = {
  borderRadius: 0,
}

const columnHeadersInner = ({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
})

const columnHeaderTitle = {
  lineHeight: '16px',
  whiteSpace: 'normal',
}

const columnHeaderTitleContainer = {
  padding: '0 6px',
  '& .MuiIconButton-root': {
    color: 'inherit',
    padding: 0,
    display: 'inline',
  },
}

const columnHeaderTitleContainerContent = {
  display: 'block',
}

const iconButtonContainer = {
  display: 'block',
  visibility: 'visible',
  boxSizing: 'inherit',
  width: 18,
}

const columnSeparator = {
  display: 'none',
}

const panel = ({ theme }) => ({
  '& .MuiFormLabel-root': { color: theme.palette.primary.main },
  '& .MuiInputLabel-root:focus': { color: theme.palette.primary.main },
  '& .MuiInputBase-root:after': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '& .MuiSwitch-root': {
    '& .Mui-checked+.MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.text.dark,
      opacity: 0.38,
    },
  },
  '& .MuiButtonBase-root': { color: theme.palette.primary.main },
  '& .MuiSwitch-colorPrimary.Mui-checked': {
    color: theme.palette.primary.main,
  },
  '& .MuiSwitch-switchBase': { color: theme.palette.background.light },
})

const toolbarContainer = ({ theme }) => ({
  display: 'block',
  '& .MuiButtonBase-root': { color: theme.palette.primary.main },
  '& .MuiBadge-badge': { backgroundColor: theme.palette.primary.main },
})

const MuiDataGrid = {
  styleOverrides: {
    root,
    cell,
    columnHeader,
    columnHeaders,
    columnHeadersInner,
    columnHeaderTitle,
    columnHeaderTitleContainer,
    columnHeaderTitleContainerContent,
    columnSeparator,
    iconButtonContainer,
    panel,
    row,
    toolbarContainer,
  },
}

export default MuiDataGrid
