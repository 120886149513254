import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import ProjectPurchaseOrdersDialog from './ProjectPurchaseOrdersDialog'

import {
  fetchProject,
  selectProject,
  selectLoadingState,
} from '../slices/project'
import LoadingState from '../utils/LoadingState'

const PurchaseOrdersConfigurationContainer = ({ projectId, view: GridRow }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const loadingState = useSelector(selectLoadingState)

  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    if (projectId !== null && loadingState === LoadingState.idle) {
      dispatch(fetchProject({ getTokenCallback, projectId }))
    }
  }, [loadingState, dispatch, getTokenCallback, projectId])

  const label =
    loadingState === LoadingState.idle || loadingState === LoadingState.pending
      ? 'loading...'
      : loadingState === LoadingState.rejected
        ? 'Error. Failed to load.'
        : !project ||
          !project.purchaseOrders ||
          project.purchaseOrders.length === 0
          ? 'no PO number'
          : project.purchaseOrders.length === 1
            ? project.purchaseOrders[0]
            : `${project.purchaseOrders.length} PO numbers`

  const gridRowProps = {
    buttonProps: {
      children: 'PO',
      disabled: loadingState !== LoadingState.fulfilled,
      onClick: () => setDialogOpen(true),
    },
    dialogComponent: () =>
      <ProjectPurchaseOrdersDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />,
    label,
  }

  return <GridRow {...gridRowProps} />
}

export default PurchaseOrdersConfigurationContainer
