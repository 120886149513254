import { Alert } from '@mui/material'

const Info = ({ children, sx }) => (
  <Alert
    severity="info"
    sx={{
      clear: 'both',
      borderRadius: 0,
      border: '1px solid',
      borderColor: 'primaryHighlight.light',
      ...sx,
    }}
  >
    {children}
  </Alert>
)

export default Info
