import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Tooltip,
} from '@mui/material'

import colours from '../styles/colours'

export const RowType = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  line: 'line',
  indentedLine: 'indentedLine',
  subsubtotal: 'subsubtotal',
  subtotal: 'subtotal',
  total: 'total',
}

const Cell = ({
  content,
  colSpan,
  description,
  isFirstCell,
  rowType,
  type,
  width,
}) => {

  const sx = {
    backgroundColor: 'inherit',
    border: 'none',
    color: 'inherit',
    fontSize: 11,
    fontWeight: 'inherit',
    pl: 1,
    pr: 2,
    py: 0.5,
  }

  if (isFirstCell) {
    Object.assign(sx, { width: 500 })
    switch (rowType) {
      case RowType.indentedLine:
        Object.assign(sx, { fontWeight: 'normal', pl: 4 })
        break
      case RowType.h4:
      case RowType.subtotal:
      case RowType.subsubtotal:
        Object.assign(sx, { pl: 4 })
        break
      default:
        Object.assign(sx, { pl: 1.5 })
    }
  } else {
    Object.assign(sx, { textAlign: 'right' })
  }

  switch (type) {
    case 'spacer':
      Object.assign(sx, {
        backgroundColor: colours.backgroundDefault,
        height: '8px',
        minWidth: '8px',
        p: 0,
        width: '8px',
      })
      break
    case 'bold':
      Object.assign(sx, { fontWeight: 'bold' })
      break
    default:
  }

  const cell = (
    <MuiTableCell colSpan={colSpan} sx={sx} width={width}>
      {content}
    </MuiTableCell>
  )

  if (description) {
    return (
      <Tooltip title={description} placement="bottom-start">
        {cell}
      </Tooltip>
    )
  } else {
    return cell
  }
}

const Row = ({ isError, isWarning, isOdd, type, cells }) => {

  const sx = { color: colours.awaGray }

  if (isError) {
    Object.assign(sx, { backgroundColor: 'error.main', color: 'error.contrastText' })
  } else if (isWarning) {
    Object.assign(sx, { backgroundColor: 'warning.main', color: 'warning.contrastText' })
  } else {
    switch (type) {
      case RowType.h2:
        Object.assign(sx, {
          backgroundColor: 'primaryHighlight.main',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        })
        break
      case RowType.h3:
        Object.assign(sx, {
          backgroundColor: colours.awaGray15,
          fontWeight: 'bold',
        })
        break
      case RowType.h4:
        Object.assign(sx, {
          backgroundColor: colours.awaGrayUltralight,
          fontWeight: 'bold',
        })
        break
      case RowType.subsubtotal:
        Object.assign(sx, { fontStyle: 'italic' })
        break
      case RowType.subtotal:
        Object.assign(sx, {
          backgroundColor: colours.awaGrayUltralight,
          fontWeight: 'bold',
        })
        break
      case RowType.total:
        Object.assign(sx, {
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          fontWeight: 'bold',
        })
        break
      default:
        if (isOdd) { Object.assign(sx, { backgroundColor: colours.awaGrayUltralight }) }
    }
  }

  return (
    <MuiTableRow sx={sx}>
      {cells.map((cellProps, idx) => (
        <Cell key={idx} isFirstCell={idx === 0} rowType={type} {...cellProps} />
      ))}
    </MuiTableRow>
  )
}

const ReportTable = ({ alternateRowColours, data }) => (
  <MuiTable stickyHeader sx={{ clear: 'both', pt: 1 }}>
    {data.header ? (
      <MuiTableHead>
        {data.header.rows.map((rowProps, idx) => (
          <Row key={idx} {...rowProps} />
        ))}
      </MuiTableHead>
    ) : null}
    {data.body ? (
      <MuiTableBody>
        {data.body.rows.map((rowProps, idx) => (
          <Row
            key={idx}
            isOdd={alternateRowColours && idx % 2 === 1}
            {...rowProps}
          />
        ))}
      </MuiTableBody>
    ) : null}
  </MuiTable>
)

export default ReportTable
