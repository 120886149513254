import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '../utils/api'
import LoadingState from '../utils/LoadingState'
import { createAllMatrices } from '../utils/chargeabilityUtils'

const initialState = {
  loadingState: LoadingState.idle,
  data: null,
}

export const fetchChargeability = createAsyncThunk(
  'fetchChargeability',
  async ({ getTokenCallback }, { getState }) => {
    const endpoint = api.endpoints(getState().client.name).work.chargeability()
    return await api.getData(
      endpoint,
      getTokenCallback
    )
  }
)

const slice = createSlice({
  name: 'Chargeability',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
  },
  extraReducers: {
    [fetchChargeability.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchChargeability.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error
    },
    [fetchChargeability.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = {
        rawData: action.payload.periods,
        ...createAllMatrices(action.payload.periods),
      }
    },
  },
})

export const { reset } = slice.actions

export const selectChargeability = (state) =>
  state.chargeability.loadingState === LoadingState.fulfilled && state.chargeability.data
    ? state.chargeability.data
    : null

export const selectLoadingState = (state) => state.chargeability.loadingState
export const selectResourceHierarchy = (state) => (state.chargeability.data || {}).resourceHierarchy

export default slice.reducer
