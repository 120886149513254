import moment from 'moment'
import { CellType, ChargeabilityCell } from '../views/ChargeabilityCell'

export const ChargeabilityRowHeaderCells = ({
  rowHeaderValues,
  enableTooltips,
  updateMaxWidth,
}) => {
  return (
    <ChargeabilityCell
      type={CellType.dataRowH1}
      tooltip={
        enableTooltips
          ? `${moment(rowHeaderValues.startDate).format(
            'D MMMM YYYY'
          )} - ${moment(rowHeaderValues.endDate).format('D MMMM YYYY')}`
          : null
      }
      updateMaxWidth={updateMaxWidth}
    >
      {rowHeaderValues.label}
    </ChargeabilityCell>
  )
}
