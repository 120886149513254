import { useMemo } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'
import { Drawer, List } from '@mui/material'

import briefcase from '../assets/briefcase.png'
import lightbulb from '../assets/lightbulb.png' // Free from https://flaticons.net/customize.php?dir=Gadgets&icon=Light-Bulb.png
import present from '../assets/present.png'

import { useClientContext } from '../clientContext'
import NavDropdown from '../primitives/NavDropdown'
import NavDropdownItem from '../primitives/NavDropdownItem'
import routes from '../utils/routes'
import { useChargeabilityAccess } from '../hooks/useChargeabilityAccess'

const defaultLogoStyle = {
  margin: '15px 15px 10px 15px',
  height: 30,
  width: 'auto',
}

const Nav = ({ drawerWidth, iconColor, paperColor }) => {
  const {
    chargeabilityEnabled,
    isStaffMember,
    logoProps,
    path,
    timeCostReportEnabled,
  } = useClientContext()

  // Checks if the user has access to view any form of chargeability
  const chargeabilityAccess = useChargeabilityAccess(chargeabilityEnabled)

  const [periodId = 0] = useQueryParam('periodId', NumberParam)
  const [projectId = 0] = useQueryParam('projectId', NumberParam)

  const redirectQueryParams = useMemo(() => {
    var params = projectId ? `?projectId=${projectId}` : ''
    if (params && periodId) {
      params = params.concat(`&periodId=${periodId}`)
    }
    return params
  }, [periodId, projectId])

  return (
    <Drawer
      variant="permanent"
      sx={{
        flexGrow: 0,
        width: drawerWidth,
        '& .MuiPaper-root': {
          backgroundColor: paperColor,
          color: iconColor,
          width: drawerWidth,
        },
      }}
    >
      <img
        src={logoProps.src}
        alt={logoProps.alt}
        style={{ ...defaultLogoStyle, ...logoProps.style }}
      />
      {isStaffMember && (
        <List>
          <NavDropdown
            id="portfolio-menu"
            iconAlt="Portfolio Menu"
            iconSrc={briefcase}
          >
            <NavDropdownItem
              route={`${path}${routes.companyWorkbook}${redirectQueryParams}`}
              text="Company Workbook"
              subheader="Review, update and approve monthly summaries"
            />
          </NavDropdown>
          <NavDropdown
            id="projects-menu"
            iconAlt="Projects Menu"
            iconSrc={present}
          >
            <NavDropdownItem
              route={`${path}${routes.projectDetails}${redirectQueryParams}`}
              text="Project Details"
              subheader="Configure key project information"
            />
            {timeCostReportEnabled && (
              <NavDropdownItem
                route={`${path}${routes.timeCostReport}${redirectQueryParams}`}
                text="Time-Cost Report"
                subheader="View time and fees charged to the project"
              />
            )}
            <NavDropdownItem
              route={`${path}${routes.invoicing}${redirectQueryParams}`}
              text="Invoicing"
              subheader="View and create invoices"
            />
          </NavDropdown>
          {chargeabilityAccess ? (
            <NavDropdown
              id="insights-menu"
              iconAlt="Insights menu"
              iconSrc={lightbulb}
            >
              <NavDropdownItem
                route={`${path}${routes.chargeability}${redirectQueryParams}`}
                text="Work Analysis"
                subheader="Time Logged Summary"
              />
            </NavDropdown>
          ) : null}
        </List>
      )}
    </Drawer>
  )
}

export default Nav
