import { useMemo } from 'react'

import {
  Autocomplete,
  createFilterOptions,
  Paper,
  TextField,
} from '@mui/material'

const ProjectSelector = ({
  disabled,
  handleProjectSelect,
  projectsByIdMap,
  projectId,
  style,
  sx,
}) => {

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) =>
      `${option.jiraKey}${option.jobNumber}${option.projectName}${option.clientName}`,
  })

  const selectedProject = useMemo(() => {
    if (!projectsByIdMap || !projectId) { return null }
    return projectsByIdMap[projectId]
  }, [projectsByIdMap, projectId])

  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      filterOptions={filterOptions}
      id="project-autocomplete"
      style={style}
      sx={{
        minWidth: 400,
        maxWidth: 780,
        mr: 1,
        mt: 1,
        width: 'calc(100vw - 500px)',
        '& .MuiAutocomplete-input': { fontSize: '14px' },
        ...sx,
      }}
      value={selectedProject}
      onChange={handleProjectSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Project"
          variant="standard"
          size="small"
        />
      )}
      options={Object.values(projectsByIdMap).sort((p1, p2) => {
        return (
          p1.clientName.localeCompare(p2.clientName) ||
          p1.projectName.localeCompare(p2.projectName)
        )
      })}
      getOptionLabel={(project) =>
        `${project.jiraKey}: ${project.projectName} (${project.jobNumber})`
      }
      groupBy={(project) => project.clientName}
      PaperComponent={(props) => (
        <Paper
          sx={{ 
            border: '1px solid',
            borderColor: 'editable.main',
            borderRadius: 0,
            '& .MuiAutocomplete-listbox .MuiAutocomplete-option': { fontSize: '14px' } }}
          {...props}
        />
      )}
    />
  )
}

export default ProjectSelector
