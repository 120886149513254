import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import resetProjectState from './resetProjectState'
import api from '../utils/api'
import LoadingState from '../utils/LoadingState'

const initialState = {
  loadingState: LoadingState.idle,
  data: null,
}

export const fetchTimeCostReport = createAsyncThunk(
  'fetchTimeCostReport',
  async ({ projectId, periodId, getTokenCallback }, { getState }) => {
    return await api.getData(
      api.endpoints(getState().client.name).projects.v2.timeCostReport(projectId, periodId),
      getTokenCallback
    )
  }
)

const slice = createSlice({
  name: 'timeCostReport',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
  },
  extraReducers: {
    [fetchTimeCostReport.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchTimeCostReport.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error
    },
    [fetchTimeCostReport.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload
    },
    [resetProjectState]: (state, action) => {
      return initialState
    },
  },
})

export const { reset } = slice.actions

export const selectTimeCostReportData = (state) => state.timeCostReport.data
export const selectLoadingState = (state) => state.timeCostReport.loadingState

export default slice.reducer
