import { useCallback } from 'react'

import { Button, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Close'

import colours from '../styles/colours'

const StringListEditorItemControl = ({
  id,
  disabled,
  originalValue,
  value,
  onChange,
  onDelete,
}) => {
  const handleChange = useCallback(
    (event) => {
      if (onChange) {
        onChange(event.target.value)
      }
    },
    [onChange]
  )

  const isNew = originalValue === null

  const edited = !isNew && originalValue !== value
  const error = !value

  const helperText = error
    ? 'Please enter a valid value'
    : isNew
    ? 'New'
    : edited
    ? 'Edited'
    : null

  return (
    <>
      <TextField
        fullWidth
        disabled={disabled}
        error={!value}
        helperText={helperText}
        value={value}
        onChange={handleChange}
        inputProps={{ 'data-testid': `${id}-text-input` }}
        data-testid={`${id}-TextField`}
        sx={{
          backgroundColor: isNew
            ? colours.awaLightGreenOld
            : edited
            ? colours.awaLightYellow
            : null,
          color: error && 'error.main',
          float: 'left',
          ml: 1,
          mt: 1,
          width: 300,
        }}
        variant="standard"
      />
      <Button
        aria-label="delete"
        disabled={disabled}
        onClick={onDelete}
        sx={{
          color: colours.muiDefaultBlack,
          float: 'left',
          ml: 1,
          mt: 1,
          width: 24,
        }}
      >
        <DeleteIcon />
      </Button>
    </>
  )
}

export default StringListEditorItemControl
