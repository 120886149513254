import { Fragment } from 'react'
import { Table, TableBody } from '@mui/material'

import SpacerRow from '../primitives/SpacerRow'
import ChargeabilityDataRow from '../views/ChargeabilityDataRow'
import ChargeabilityHeaders from '../views/ChargeabilityHeaders'

const ChargeabilityTable = ({
  resourceLevel,
  showColours,
  aggregatedStructures: { filteredResourceHierarchy, resourceInfo, allRowDataHeaders, allRowDataValues },
  enableTooltips,
  reversed,
  updateMaxWidth,
  cellWidth,
}) => {
  return (
    <Table stickyHeader style={{ width: 'fit-content' }}>
      <ChargeabilityHeaders
        resourceHierarchy={filteredResourceHierarchy}
        resourceLevel={resourceLevel}
        updateMaxWidth={updateMaxWidth}
        cellWidth={cellWidth}
      />
      <TableBody>
        <SpacerRow big />
        {allRowDataValues.map((rowDataValues, rowIdx) => (
          <Fragment key={rowIdx}>
            {!reversed && allRowDataHeaders[rowIdx].isFirstInTimeFrame ? <SpacerRow big /> : null}
            <ChargeabilityDataRow
              rowHeaderValues={allRowDataHeaders[rowIdx]}
              rowDataValues={rowDataValues}
              resourceInfo={resourceInfo}
              hideColours={!showColours}
              currentTimeSpan={rowIdx === 0}
              enableTooltips={enableTooltips}
            />
            {reversed && allRowDataHeaders[rowIdx].isFirstInTimeFrame ? <SpacerRow big /> : null}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  )
}
export default ChargeabilityTable
