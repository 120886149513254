import awaLogo from '../assets/awaraindrop.png'

const awaConfig = {
  clientAccess: 'Awa Staff',
  chargeabilityEnabled: true,
  invoiceSubmissionEnabled: true,
  invoicingApproachEnabled: true,
  jiraIntegrationEnabled: true,
  purchaseOrdersEnabled: true,
  timeCostReportEnabled: true,
  logoProps: {
    alt: 'Awa Environmental Logo',
    src: awaLogo,
  },
  cwbColumns: [
    'jiraKey',
    'clientName',
    'projectName',
    'projectManager',
    'projectDirector',
    'clientManager',
    'initialBudget',
    'historicVariations',
    'projectBudget',
    'billedToDate',
    'historicExpenses',
    'wipForPeriod',
    'historicUnbilledWip',
    'totalWip',
    'periodExpenses',
    'invoiceAmount',
    'wipBalance',
    'invoicingApproach',
    'expectedVariations',
    'percentComplete',
    'provisionalAmount',
    'writeUpOffAmount',
    'writeUpOffToDate',
    'agedDebt',
    'agedDebt3Months',
    'projectStartDate',
    'projectEndDate',
  ],
  colours: {
    // source: https://awa-kiwi.atlassian.net/wiki/spaces/Ops/pages/495922/Colours+fonts+and+logo
    awaGreen130: '#384b19', // secondary.dark
    awaGreen: '#506C24', // secondary.main
    awaGreen75: '#7C915B', // secondary.light
    awaGreen43: '#B4C0A1', // secondaryHighlight.dark
    awaGreen30: '#CBD3BD', // secondaryHighlight.main
    awaGreen15: '#E5E9DE', // secondaryHighlight.light
    awaCharcoal: '#3C3B3C', // text.dark
    awaCharcoal75: '#635E59', // text.main // literal value provided by Brad - not calculated
    awaCharcoal50: '#9E9D9E', // text.light
    awaCharcoal30: '#C5C4C5', // editable.dark
    awaCharcoal21: '#DFDDDB', // editable.main
    awaCharcoal5: '#F2F2F0', // editable.light
    awaGreyBlue130: '#3A4C50', // primary.dark
    awaGreyBlue: '#536D73', // primary.main
    awaGreyBlue75: '#7E9296', // primary.light
    awaGreyBlue50: '#A9B6B9', // primaryHighlight.dark
    awaGreyBlue30: '#CBD3D5', // primaryHighlight.main
    awaGreyBlue15: '#E5E9EA', // primaryHighlight.light
    awaNeutral: '#E2DBD6',
    awaNeutral75: '#E9E4E0',
    awaNeutral50: '#F1EDEB',
    awaNeutral30: '#F6F4F3', // background.dark
    awaNeutral15: '#FBFAF9', // background.main
    awaOrange: '#CB482C', //203,72,44
    awaOrange75: '#D87661', //216,118,97
    awaOrange50: '#E5AE96', //229,174,150
    awaOrange30: '#EFC8C0', //239,200,192
    awaOrange15: '#F7E4DF', //247,228,223
    awaBlue: '#007AA9', //0,122,169
    awaBlue75: '#4A9BBF', //74,155,191
    awaBlue50: '#80BDD4', //128,189,212
    awaBlue30: '#B3D7E5', //179,215,229
    awaBlue15: '#D9EBF2', //217,235,242
    awaYellow: '#E4AE3C', //228,174,60
    awaYellow75: '#EBC26D', //235,194,109
    awaYellow50: '#F2D79E', //242,215,158
    awaYellow30: '#F7E7C5', //247,231,197
    awaYellow15: '#FBF3E2', //251,243,226
    awaLightGreen: '#A0BD46', //160,189,70
    awaLightGreen75: '#B8CE74', //184,206,116
    awaLightGreen50: '#D0DEA3', //208,222,163
    awaLightGreen30: '#E3EBC8', //227,235,200
    awaLightGreen15: '#F1F5E3', //241,245,227
    white: '#FFFFFF', // background.light
    // ===== older awa palette =====
    awaGray: '#3C3B3C', // awaCharcoal // text.dark
    awaGray75: '#6D6C6D', // awaCharcoal75 // text.main
    awaGray50: '#9E9D9E', // awaCharcoal50 // text.light
    awaGray30: '#C5C4C5', // awaCharcoal30 // editable.dark
    awaGray15: '#DFDDDB', // awaCharcoal21 // editable.main
    awaGrayUltralight: '#F0EFEE', //awaCharcoal5 // editable.light
    awaBlueOld: '#536D73', // awaGreyBlue // primary.main
    awaBlueOld45: '#CBD3D5', // awaGreyBlue30 // primaryHighlight.main
    awaLightBlue: '#E5E9EA', // awaGreyBlue15 // primaryHighlight.light
    awaLightYellow: '#FFF2CC', // awaYellow15??
    // awaYellow: '#E4AE3C',
    awaRed: '#FF503C', // awaOrange??
    // awaGreen: '#506C24', // awaGreen // secondary.main
    awaLightGreenOld: '#E5E9DE', // awaGreen15 // secondaryHighlight.light
    backgroundDefault: '#FAFAFA', // awaNeutral15 // background.main
    black: '#000', // awaCharcoal // text.dark
    lightRed: `rgb(126,10,15,0.1)`,
    darkRed: '#750f0f',
    // white: '#ffffff', // white // background.light
    error: '#f44336',
    pink: '#6b235f',
    lightPink: '#e8dce6',
    muiDefaultBlack: '#000000DE', // awaCharcoal // text.dark
    errorLight: '#ff8476', // awaOrange75??
    errorMain: '#ff503C',
    errorDark: '#cc4030',
    warningLight: '#f2b871', // awaYellow75??
    warningMain: '#eda041',
    warningDark: '#bd8034',
    infoLight: '#79aff2', // awaBlue50??
    infoMain: '#408eed', // awaBlue75??
    infoDark: '#2c63a5', // awaBlue??
    successLight: '#b0d37a', // awaLightGreen75??
    successMain: '#9dc85a', // awaLightGreen??
    successDark: '#5e7836', // awaGreen??
  },
  palette: {
    primary: {
      light: 'awaGreyBlue75',
      main: 'awaGreyBlue',
      dark: 'awaGreyBlue130',
    },
    secondary: {
      light: 'awaGreen75',
      main: 'awaGreen',
      dark: 'awaGreen130',
    },
    error: {
      light: 'errorLight',
      main: 'errorMain',
      dark: 'errorDark',
    },
    warning: {
      light: 'awaYellow15',
      main: 'warningMain',
      dark: 'warningDark',
    },
    info: {
      light: 'infoLight',
      main: 'infoMain',
      dark: 'infoDark',
    },
    success: {
      light: 'successLight',
      main: 'successMain',
      dark: 'successDark',
    },
    primaryHighlight: {
      light: 'awaGreyBlue15',
      main: 'awaGreyBlue30',
      dark: 'awaGreyBlue50',
    },
    secondaryHighlight: {
      light: 'awaGreen15',
      main: 'awaGreen30',
      dark: 'awaGreen43',
    },
    editable: {
      light: 'awaCharcoal5',
      main: 'awaCharcoal21',
      dark: 'awaCharcoal30',
    },
    background: {
      light: 'white',
      main: 'awaNeutral15',
      dark: 'awaNeutral30',
    },
    headerBackground: {
      light: 'awaNeutral30',
      main: 'awaNeutral50',
      dark: 'awaNeutral75'
    },
    text: {
      light: 'awaCharcoal50',
      main: 'awaCharcoal75',
      dark: 'awaCharcoal',
    },
    selected: {
      main: 'awaGrayUltralight',
    },
    tab: {
      light: "awaGrayUltralight",
      main: "awaGray15",
      dark: "awaGray30"
    }
  },
}

export default awaConfig
