import { memo } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Box } from '@mui/material'

import WorkItemDraggable from './WorkItemDraggable'

const InnerList = memo(({ disabled, workItems }) => {
  return workItems.map((workItem, index) => (
    <WorkItemDraggable
      key={workItem.jiraKey}
      index={index}
      disabled={disabled}
      workItem={workItem}
    />
  ))
})

const WorkItemDroppable = ({ disabled, direction, lineItem, workItems }) => {

  return (
    <Droppable
      droppableId={lineItem.id}
      direction={direction}
      isDropDisabled={disabled}
    >
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          sx={{
            backgroundColor: snapshot.isDraggingOver ? 'primaryHighlight.main' : 'inherit',
            display: 'flex',
            flexDirection: direction === 'horizontal' ? 'row' : 'column',
            flexGrow: 1,
            minHeight: 10,
            minWidth: 10,
            padding: direction === 'vertical' ? 'auto' : 0,
            transition: 'background-color 0.2s ease',
          }}
          {...provided.droppableProps}
        >
          <InnerList disabled={disabled} workItems={workItems} />
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )
}

export default WorkItemDroppable
