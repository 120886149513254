import config from '../config'
import SelectorButtons from '../primitives/SelectorButtons'

const CompanyWorkbookViewerTypeSelector = ({
  viewerTypes,
  onToggleViewerType,
}) => {

  const { projectManager, projectDirector, clientManager } = config.viewerTypes

  const buttonLabels = {
    [projectManager]: 'PM',
    [projectDirector]: 'PD',
    [clientManager]: 'CM',
  }

  const buttons = Object.entries(buttonLabels).map(([viewerType, label]) => ({
    children: label,
    onClick: () => onToggleViewerType(viewerType),
    selected: viewerTypes.includes(viewerType)
  }))

  return <SelectorButtons buttons={buttons} />
}

export default CompanyWorkbookViewerTypeSelector
