import CloseIcon from '@mui/icons-material/Close'
import { DialogTitle, IconButton } from '@mui/material'

const DialogTitleWithClose = ({ onClose, title }) => (
  <DialogTitle
    onClose={onClose}
    style={{ textTransform: 'uppercase' }}
  >
    {title}
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        color: 'grey[500]',
        m: 0.5,
        position: 'absolute',
        right: '8px',
        top: '8px',
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
)

export default DialogTitleWithClose
