import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import api from '../utils/api'

import LoadingState from '../utils/LoadingState'
import workbookStatus from '../utils/workbookStatus'

const initialState = {
  loadingState: LoadingState.idle,
  data: null,
  saveEditsState: LoadingState.idle,
  saveEditsError: '',
  statusChangeState: LoadingState.idle,
  statusChangeError: '',
  editedLines: {},
}

export const fetchCompanyWorkbook = createAsyncThunk(
  'fetchCompanyWorkbook',
  async (args, thunkApi) => {
    return await api.getData(
      api.endpoints(thunkApi.getState().client.name).companyWorkbooks.getForPeriod(args.periodId),
      args.getTokenCallback
    )
  }
)

export const generateCompanyWorkbook = createAsyncThunk(
  'generateCompanyWorkbook',
  async (args, thunkApi) => {
    return await api.post(
      api
        .endpoints(thunkApi.getState().client.name)
        .companyWorkbooks.generate(args.periodId),
      args.getTokenCallback
    )
  }
)

export const saveCompanyWorkbookEdits = createAsyncThunk(
  'saveCompanyWorkbookEdits',
  async (args, thunkApi) => {
    return await api.put(
      api.endpoints(thunkApi.getState().client.name).companyWorkbooks.save(
        thunkApi.getState().companyWorkbook.data.validWorkbook.id
      ),
      Object.values(thunkApi.getState().companyWorkbook.editedLines),
      args.getTokenCallback
    )
  }
)

// This is a misnomer - it doesn't update the workbook status per se but updates the frontend status of the
// company workbook. TODO - refactor
export const updateCompanyWorkbookStatus = createAsyncThunk(
  'updateCompanyWorkbookStatus',
  async (args, thunkApi) => {
    return await api.getData(
      api.endpoints(thunkApi.getState().client.name).companyWorkbooks.status(
        thunkApi.getState().companyWorkbook.data.latestWorkbook.id
      ),
      args.getTokenCallback
    )
  }
)

/**
 * This thunk attempts to transition a company workbook to a new db status
 * @returns a thunk that calls the setInvoicing endpoint
 */
export const setCompanyWorkbookDatabaseStatus = createAsyncThunk(
  'setCompanyWorkbookDatabaseStatus',
  // Expected args are getTokenCallback and newDatabaseStatus
  async (args, thunkApi) => {
    const state = thunkApi.getState()
    const clientName = state?.client?.name
    const workbookId = state?.companyWorkbook?.data?.validWorkbook?.id
    const newDatabaseStatus = args.newDatabaseStatus
    const url = api
      .endpoints(clientName)
      .companyWorkbooks.setStatus(workbookId, newDatabaseStatus)

    // Intentionally empty body - backend doesn't expect anything for this as all parameters are passed
    // through in the URL
    const body = null
    const response = await api.put(url, body, args.getTokenCallback)
    return { response, newDatabaseStatus }
  }
)

const slice = createSlice({
  name: 'companyWorkbook',
  initialState,
  reducers: {
    companyWorkbookRowEdited(state, action) {
      state.editedLines = {
        ...state.editedLines,
        [action.payload.editedProjectId]: {
          ...state.editedLines[action.payload.editedProjectId],
          ...action.payload.editedLineData,
        },
      }
    },
    reset(state, action) {
      return initialState
    },
    /**
     * Sets the active step based on the currently selected workbook
     */
    setActiveStep(state, action) {
      return { data: { activeStep: action.payload.newActiveStep } }
    },
  },
  extraReducers: {
    // Status setting calls -----------------------------------------
    // setCompanyWorkbookDatabaseStatus
    [setCompanyWorkbookDatabaseStatus.pending]: (state, action) => {},
    [setCompanyWorkbookDatabaseStatus.rejected]: (state, action) => {},
    [setCompanyWorkbookDatabaseStatus.fulfilled]: (state, action) => {
      state.data.validWorkbook.status = action.payload.newDatabaseStatus
    },

    // generateCompanyWorkbook
    [generateCompanyWorkbook.pending]: (state, action) => {
      state.statusChangeState = LoadingState.pending
      state.statusChangeError = ''
    },
    [generateCompanyWorkbook.rejected]: (state, action) => {
      state.statusChangeState = LoadingState.rejected
      state.statusChangeError = action.error.message
    },
    [generateCompanyWorkbook.fulfilled]: (state, action) => {
      state.statusChangeState = LoadingState.fulfilled
      state.data.latestWorkbook = action.payload
    },
    // --------------------------------------------------------------

    [fetchCompanyWorkbook.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchCompanyWorkbook.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error.message
    },
    [fetchCompanyWorkbook.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload
      state.editedLines = {}
    },

    [saveCompanyWorkbookEdits.pending]: (state, action) => {
      state.saveEditsState = LoadingState.pending
      state.saveEditsError = ''
    },
    [saveCompanyWorkbookEdits.rejected]: (state, action) => {
      state.saveEditsState = LoadingState.rejected
      state.saveEditsError = action.error.message
        ? action.error.message
        : action.error
    },
    [saveCompanyWorkbookEdits.fulfilled]: (state, action) => {
      state.saveEditsState = LoadingState.fulfilled
      for (const line of Object.values(state.editedLines)) {
        const idx = state.data.workbookLines.findIndex(
          (l) => l.projectId === line.projectId
        )
        state.data.workbookLines[idx] = {
          ...state.data.workbookLines[idx],
          ...line,
        }
      }
      state.editedLines = {}
    },

    [updateCompanyWorkbookStatus.pending]: (state, action) => {
      // do nothing
    },
    [updateCompanyWorkbookStatus.rejected]: (state, action) => {
      // do nothing
    },
    [updateCompanyWorkbookStatus.fulfilled]: (state, action) => {
      state.data.latestWorkbook.status = action.payload.statusCode
    },
  },
})

export const { companyWorkbookRowEdited, reset, setActiveStep } = slice.actions

export const selectCompanyWorkbookData = (state) => state.companyWorkbook.data
export const selectLoadingState = (state) => state.companyWorkbook.loadingState

export const selectIsWorkbookLoading = createSelector(
  selectLoadingState,
  (loadingState) => loadingState === LoadingState.pending
)

export const selectIsWorkbookReady = createSelector(
  selectLoadingState,
  (loadingState) => loadingState === LoadingState.fulfilled
)

export const selectLatestWorkbook = createSelector(
  selectCompanyWorkbookData,
  (companyWorkbookData) =>
    companyWorkbookData ? companyWorkbookData.latestWorkbook : null
)

export const selectIsWorkbookBuilding = createSelector(
  selectLatestWorkbook,
  (latestWorkbook) =>
    latestWorkbook ? latestWorkbook.status === workbookStatus.building : false
)

export const selectPeriodId = createSelector(
  selectLatestWorkbook,
  (latestWorkbook) => (latestWorkbook ? latestWorkbook.invoicingPeriod : 0)
)

export const selectValidWorkbook = createSelector(
  selectCompanyWorkbookData,
  (companyWorkbookData) =>
    companyWorkbookData ? companyWorkbookData.validWorkbook : null
)

export const selectWorkbookStatus = createSelector(
  selectIsWorkbookBuilding,
  selectValidWorkbook,
  (isBuilding, validWorkbook) =>
    isBuilding
      ? workbookStatus.building
      : validWorkbook
      ? validWorkbook.status
      : workbookStatus.doesNotExist
)

export const selectIsWorkbookFinalised = createSelector(
  selectValidWorkbook,
  (validWorkbook) =>
    validWorkbook ? validWorkbook.status === workbookStatus.finalised : false
)

export const selectEditedLines = (state) => state.companyWorkbook.editedLines

export const selectAreAnyLinesEdited = createSelector(
  selectEditedLines,
  (editedLines) => Object.values(editedLines).length > 0
)

export const selectSaveEditsState = (state) =>
  state.companyWorkbook.saveEditsState
export const selectSaveEditsError = (state) =>
  state.companyWorkbook.saveEditsError
export const selectStatusChangeState = (state) =>
  state.companyWorkbook.statusChangeState
export const selectStatusChangeError = (state) =>
  state.companyWorkbook.statusChangeError

export default slice.reducer
