import { useCallback } from 'react'

import NavigateBefore from '@mui/icons-material/NavigateBefore'
import NavigateNext from '@mui/icons-material/NavigateNext'

import {
  Box,
  IconButton,
  Typography,
} from '@mui/material'

const CompanyWorkbookPaginationControls = ({
  disabled,
  totalRowCount,
  pageSize,
  page,
  onPageChange,
}) => {

  const changePage = useCallback((changeAmount) => {
    const maxPage = Math.floor(totalRowCount / pageSize)
    const newPage = Math.min(Math.max(page + changeAmount, 0), maxPage)
    onPageChange(newPage)
  }, [totalRowCount, pageSize, page, onPageChange])

  const makePageLabel = useCallback(() => {
    const startIndex = pageSize * page
    const endIndex = Math.min(startIndex + pageSize, totalRowCount)
    return `${startIndex + 1}-${endIndex} of ${totalRowCount}`
  }, [totalRowCount, pageSize, page])

  if (disabled) {
    return (
      <Box sx={{ width: '100%', pr: 1 }}>
        <Typography
          sx={{ float: 'right', verticalAlign: 'center', mr: 2, my: 1.5 }}
        >
          Pagination disabled while in export mode. Turn it off to see totals
          and rest of data.
        </Typography>
      </Box>
    )
  } else {
    return (
      <Box style={{ width: '100%' }} sx={{ pr: 1 }}>
        <IconButton
          disabled={page === Math.floor(totalRowCount / pageSize)}
          onClick={() => changePage(1)}
          style={{ float: 'right' }}
          sx={{ mr: 1 }}
        >
          <NavigateNext />
        </IconButton>
        <IconButton
          disabled={page === 0}
          style={{ float: 'right' }}
          sx={{ mr: 1 }}
          onClick={() => changePage(-1)}
        >
          <NavigateBefore />
        </IconButton>
        <Typography
          style={{ float: 'right', verticalAlign: 'center' }}
          sx={{ mr: 2, my: 1.5 }}
        >
          {makePageLabel()}
        </Typography>
      </Box>
    )
  }
}

export default CompanyWorkbookPaginationControls
