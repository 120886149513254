import { useCallback, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import Note from '../primitives/Note'
import useInterval from '../hooks/useInterval'
import {
  fetchCompanyWorkbook,
  updateCompanyWorkbookStatus,
  selectIsWorkbookBuilding,
  selectLatestWorkbook,
} from '../slices/companyWorkbook'

const CompanyWorkbookGenerationMonitor = ({ className, periodId }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()

  const dispatch = useDispatch()
  const isBuilding = useSelector(selectIsWorkbookBuilding)
  const latestWorkbook = useSelector(selectLatestWorkbook)

  const [isPolling, setIsPolling] = useState(false)
  const [startTime, setStartTime] = useState(null)
  const [timeElapsed, setTimeElapsed] = useState(null)

  const fetchWorkbook = useCallback(() => {
    dispatch(
      fetchCompanyWorkbook({
        periodId,
        getTokenCallback,
      })
    )
  }, [dispatch, getTokenCallback, periodId])

  const updateStatus = useCallback(() => {
    dispatch(
      updateCompanyWorkbookStatus({
        getTokenCallback,
      })
    )
  }, [dispatch, getTokenCallback])

  const checkStatus = useCallback(async () => {
    if (isPolling) {
      updateStatus()
    }
  }, [isPolling, updateStatus])

  const updateTimeElapsed = useCallback(() => {
    if (isPolling) {
      const difference = moment.duration(moment().diff(startTime))
      const formattedTime = `${
        difference.hours() > 0 ? difference.hours() + ':' : ''
      }${difference.minutes().toString().padStart(2, '0')}:${difference
        .seconds()
        .toString()
        .padStart(2, '0')}`
      setTimeElapsed(formattedTime)
    }
  }, [isPolling, startTime])

  useEffect(() => {
    if (isBuilding && !isPolling && latestWorkbook) {
      // Get the workbook creation time and use this as that start time.
      // Ensures elapsed time persists after page refreshes
      const createdTime = moment(latestWorkbook.createdStart)
      setStartTime(createdTime)
      setIsPolling(true)
    } else if (!isBuilding && isPolling) {
      setIsPolling(false)
      fetchWorkbook()
    }
  }, [fetchWorkbook, isBuilding, isPolling, latestWorkbook])

  useInterval(checkStatus, 30000)
  useInterval(updateTimeElapsed, 500)

  if (!isBuilding) {
    return null
  }

  return (
    <Note className={className}>
      Generating workbook. This may take a few minutes. Please wait...{' '}
      {timeElapsed && `(Elapsed: ${timeElapsed})`}
    </Note>
  )
}

export default CompanyWorkbookGenerationMonitor
