import SelectorButtons from '../primitives/SelectorButtons'

const CompanyWorkbookViewModeSelector = ({
  editButtonEnabled,
  onViewModeChange,
  viewMode,
}) => {

  const viewModeButtons = [
    {
      children: 'Edit',
      disabled: !editButtonEnabled,
      onClick: () => onViewModeChange('edit'),
      selected: viewMode === 'edit',
      'data-testid': 'viewModeEditButton',
    },
    {
      children: 'View',
      onClick: () => onViewModeChange('view'),
      selected: viewMode === 'view',
      'data-testid': 'viewModeViewButton',
    },
    {
      children: 'Export',
      onClick: () => onViewModeChange('export'),
      selected: viewMode === 'export',
      'data-testid': 'viewModeExportButton',
    },
  ]

  return <SelectorButtons buttons={viewModeButtons} />
}

export default CompanyWorkbookViewModeSelector
