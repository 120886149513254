import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import Loading from './Loading'
import { selectClientName } from '../slices/client'

const Page = ({ children }) => {

  const client = useSelector(selectClientName)
  if (!client) { return <Loading /> }
  return <Box sx={{ p: 3 }}>{children}</Box>
}

export default Page
