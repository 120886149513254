import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: null,
}

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    set: (_, action) => ({
      name: action.payload,
    })
  },
})

export const selectClientName = (state) => state.client.name

export const { set } = slice.actions
export default slice.reducer
