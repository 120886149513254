import { Draggable } from 'react-beautiful-dnd'
import { Box, Tooltip, Typography } from '@mui/material'

import config from '../config'

const maxSummaryLength = 44

function needsTruncation(summary) {
  return summary.length > maxSummaryLength
}

function truncateSummary(summary) {
  return summary.substr(0, maxSummaryLength - 3) + '...'
}

const Link = ({ children, workItem }) => {
  return (
    <a
      href={`${config.jira.baseUrl}/browse/${workItem.jiraKey}`}
      target="_blank"
      rel="noreferrer"
      style={{
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      {children}
    </a>
  )
}

const WorkItemDraggable = ({ disabled, index, workItem }) => {
  return (
    <Draggable
      isDragDisabled={disabled}
      draggableId={workItem.jiraKey}
      index={index}
    >
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            bgcolor: 'background.light',
            border: '1px solid',
            borderColor: 'editable.dark',
            borderRadius: 0.5,
            m: 0.5,
            opacity: workItem.hours === 0 ? 0.5 : 1,
            p: 1,
            width: 144,
          }}
        >
          <Typography
            fontSize={14}
            fontWeight={700}
            sx={{ color: 'secondary.main', float: 'left', mb: 1, mt: 0 }}
          >
            <Link workItem={workItem}>{workItem.jiraKey}</Link>
          </Typography>
          {workItem.hours !== null && workItem.hours !== undefined ? (
            <Typography
              fontSize={12}
              sx={{ float: 'right', m: 0, pr: 0.5, pt: 0.25 }}
            >
              {workItem.hours.toLocaleString('en-NZ', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              })}
              h
            </Typography>
          ) : null}
          {needsTruncation(workItem.summary) ? (
            <Tooltip title={workItem.summary} arrow>
              <Typography fontSize={11} sx={{ clear: 'both', m: 0 }}>
                {truncateSummary(workItem.summary)}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              fontSize={12}
              sx={{ float: 'right', m: 0, pr: 0.5, pt: 0.25 }}
            >
              {workItem.summary}
            </Typography>
          )}
        </Box>
      )}
    </Draggable>
  )
}

export default WorkItemDraggable
