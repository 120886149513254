import moment from 'moment'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '../utils/api'
import LoadingState from '../utils/LoadingState'

const initialState = {
  loadingState: LoadingState.idle,
  data: null,
}

export const fetchPeriods = createAsyncThunk(
  'fetchPeriods',
  async ({ getTokenCallback }, { getState }) => {
    return await api.getData(
      api.endpoints(getState().client.name).periods.all(),
      getTokenCallback
    )
  }
)

const slice = createSlice({
  name: 'periods',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
  },
  extraReducers: {
    [fetchPeriods.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchPeriods.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error
    },
    [fetchPeriods.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload.periods
    },
  },
})

export const { reset } = slice.actions

export const selectCurrentAndPastPeriods = (state) =>
  state.periods.loadingState === LoadingState.fulfilled && state.periods.data
    ? state.periods.data
      .filter((period) =>
        moment(period.periodStart).isSameOrBefore(moment(), 'days')
      )
      .sort((a, b) =>
        moment(a.periodStart).isAfter(moment(b.periodStart)) ? 1 : -1
      )
    : null

export const selectLoadingState = (state) => state.periods.loadingState

export default slice.reducer
