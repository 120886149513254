import { useCallback, useMemo, useState } from 'react'
import { Button as MaterialButton, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { formatKeyString } from '../utils/format'
import { getAllRates, getStaffWithNoRates } from '../utils/rateUtils'
import RateControl, { RateControlHeaderRow } from '../views/RateControl'
import colours from '../styles/colours'

const RatesEditor = ({
  disabled,
  editedRates,
  onChangeEditedRates,
  projectRates,
  projectCandidates,
}) => {

  const [focus, setFocus] = useState({ name: '', date: '' })

  const candidateNames = useMemo(
    () => Object.keys(projectCandidates),
    [projectCandidates]
  )

  const defaultRates = useMemo(() => {
    let defRates = {}
    for (let candidateName of candidateNames) {
      defRates[candidateName] = projectCandidates[candidateName]
    }
    return defRates
  }, [candidateNames, projectCandidates])

  const allRates = useMemo(
    () => getAllRates(projectRates, editedRates),
    [projectRates, editedRates]
  )

  const staffWithNoRates = useMemo(
    () => getStaffWithNoRates(candidateNames, allRates),
    [candidateNames, allRates]
  )

  const handleClickAdd = useCallback(() => {
    onChangeEditedRates({ ...editedRates, '': { '': '' } })
  }, [editedRates, onChangeEditedRates])

  const onChangeFromDate = useCallback((staffName, oldDate) => (newDate) => {
    let currentRate = defaultRates[staffName]
    if (editedRates[staffName]) {
      currentRate = editedRates[staffName][oldDate]
      delete editedRates[staffName][oldDate]
    }
    onChangeEditedRates({
      ...editedRates,
      [staffName]: {
        ...editedRates[staffName],
        [newDate]: currentRate
      }
    })
  }, [defaultRates, editedRates, onChangeEditedRates])

  const onChangeStaffName = useCallback((oldName, fromDate) => (newName) => {
    delete editedRates[oldName][fromDate]
    // complex date handling:
    // default date is two years ago today
    // if newName does not have an existing staff rate (any => new), then always set to default date
    // otherwise, if oldName has an existing staff rate (ex => ex) OR
    // if oldName has a date that's been changed (new but date changed => ex), then keep the current date
    // otherwise (blank => ex or new => ex), set to blank date
    const now = new Date()
    const defaultDate = formatKeyString(now.setFullYear(now.getFullYear() - 2))
    const dateAfterStaffNameChanged = !projectRates[newName]
      ? defaultDate
      : (projectRates[oldName] || fromDate !== defaultDate)
        ? fromDate
        : ''
    onChangeEditedRates({
      ...editedRates,
      [newName]: {
        ...editedRates[newName],
        [dateAfterStaffNameChanged]: defaultRates[newName].toString()
      }
    })
    setFocus({ name: newName, date: fromDate })
  }, [defaultRates, editedRates, onChangeEditedRates, projectRates])

  const onChangeStaffRate = useCallback((staffName, fromDate) => (newRate) => {
    onChangeEditedRates({
      ...editedRates,
      [staffName]: {
        ...editedRates[staffName],
        [fromDate]: newRate
      }
    })
  }, [editedRates, onChangeEditedRates])

  return (
    <div style={{ maxWidth: 600 }}>
      <RateControlHeaderRow />
      {Object.keys(allRates).map((staffName, idx) => {
        const firstDateWithExistingRate = Object.keys(allRates[staffName]).filter(
          date => (projectRates[staffName] || {})[date] !== undefined
        )[0]
        return Object.keys(allRates[staffName]).map((fromDate, i, arr) => {
          const existingRate = (projectRates[staffName] || {})[fromDate]
          const editedRate = ((editedRates || {})[staffName] || {})[fromDate]
          return <RateControl
            key={`${idx}-${i}`}
            autoFocus={staffName === focus.name && fromDate === focus.date}
            disabled={disabled}
            defaultRate={defaultRates[staffName]}
            editedRate={editedRate}
            existingRate={existingRate}
            fromDate={fromDate}
            id={`${idx}-${i}`}
            isStaffFirstEntry={i === 0}
            isStaffLastEntry={i === arr.length - 1}
            onChangeFromDate={onChangeFromDate(staffName, fromDate)}
            onChangeStaffName={onChangeStaffName(staffName, fromDate)}
            onChangeStaffRate={onChangeStaffRate(staffName, fromDate)}
            staffList={candidateNames}
            staffName={(!existingRate || fromDate === firstDateWithExistingRate) ? staffName : ''}
          />
        })
      })}
      {staffWithNoRates.length > 0 && (
        <MaterialButton
          aria-label="add"
          disabled={disabled}
          onClick={handleClickAdd}
          sx={{
            color: colours.muiDefaultBlack,
            mt: 1,
            '& .MuiSvgIcon-root': { fontSize: '14px' },
          }}
        >
          <AddIcon />
          <Typography style={{ fontSize: '14px' }}>Add Staff Rate</Typography>
        </MaterialButton>
      )}
    </div>
  )
}

export default RatesEditor
