import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/material'

import config from '../config'
import { useClientContext } from '../clientContext'
import Error from '../primitives/Error'

import {
  companyWorkbookRowEdited,
  saveCompanyWorkbookEdits,
  selectCompanyWorkbookData,
  selectEditedLines,
  selectWorkbookStatus,
} from '../slices/companyWorkbook'

import { selectMyTeamMembers } from '../utils/compositeSelectors'
import { addSetupProblemFlags, presetViewFilters, presetViewNames } from '../utils/dataGridPresetViewUtils'
import { filterCompanyWorkbookLinesByUser } from '../utils/userUtils'
import workbookStatus from '../utils/workbookStatus'

import CompanyWorkbookDataGrid from '../views/CompanyWorkbookDataGrid'
import CompanyWorkbookHeader from '../views/CompanyWorkbookHeader'
import { getCompletionStatusFilter } from '../views/CompanyWorkbookCompletionStatusSelector'

const CompanyWorkbook = ({
  enableEditWriteOff,
  hiddenColumns,
  handleColumnVisibilityChange,
  viewMode,
  viewerTypes,
  completionStatusTypes,
  presetView,
  myTeamOnly,
}) => {
  const { getAccessTokenSilently: getTokenCallback, user } = useAuth0()
  const { isLeadership, isOperations } = useClientContext()

  const store = useStore()
  const state = store.getState()
  const dispatch = useDispatch()
  const data = useSelector(selectCompanyWorkbookData)
  const editedLines = useSelector(selectEditedLines)
  const status = useSelector(selectWorkbookStatus)

  const myTeam = selectMyTeamMembers(state, user.name)

  const [filteredLines, setFilteredLines] = useState([])

  const handleRowEdit = (editedProjectId, editedLineData) => {
    dispatch(companyWorkbookRowEdited({ editedProjectId, editedLineData }))
  }

  const handleSaveEdits = useCallback(() => {
    dispatch(
      saveCompanyWorkbookEdits({
        getTokenCallback,
      })
    )
  }, [dispatch, getTokenCallback])

  useEffect(() => {
    const workbookLines = presetView === presetViewNames.SETUP_PROBLEMS
      ? addSetupProblemFlags(data.workbookLines || [])
      : (data.workbookLines || [])
    setFilteredLines(
      filterCompanyWorkbookLinesByUser({
        user,
        workbookLines,
        viewerTypes,
        completionStatusFilter: getCompletionStatusFilter(completionStatusTypes),
        presetViewFilter: presetViewFilters[presetView],
        myTeam,
        myTeamOnly,
        isLeadership,
        isOperations,
        viewerTypesFromConfig: config.viewerTypes,
      })
    )
  }, [
    data,
    user,
    viewerTypes,
    completionStatusTypes,
    presetView,
    myTeam,
    myTeamOnly,
    isLeadership,
    isOperations
  ])

  if (!data) {
    return <Error />
  } else if (
    !data.validWorkbook ||
    (!isOperations && status === workbookStatus.review)
  ) {
    return (
      <Box sx={{ clear: 'both', pt: 1 }}>
        <CompanyWorkbookHeader isOps={isOperations} />
      </Box>
    )
  } else {
    return (
      <div style={{ flexGrow: 1 }}>
        <CompanyWorkbookDataGrid
          enableEditWriteOff={enableEditWriteOff}
          isOps={isOperations}
          editedLines={editedLines}
          onRowEdit={handleRowEdit}
          onSaveEdits={handleSaveEdits}
          hiddenColumns={hiddenColumns}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
          viewMode={viewMode}
          workbookLines={filteredLines}
        />
      </div>
    )
  }
}

export default CompanyWorkbook
