import {
  makeHeaderRow,
  makeSpacerRow,
  generateRows,
} from './generateTimeCostReportRows'
import { scopes } from '../views/TimeCostReportScopeSelector'

function consolidateWorkItems(data) {
  let workItemLogs = []
  if (data.contractLineItemLogs) {
    data.contractLineItemLogs.forEach((lineItem) => {
      workItemLogs = workItemLogs.concat(
        lineItem.workItemLogs.map((workItem) => ({
          ...workItem,
          lineItemId: lineItem.id,
        }))
      )
    })
  }

  return workItemLogs
}

function splitWorkItems(workItemLogs, lineItems) {
  const billableItems = workItemLogs.filter((workItem) => {
    if (workItem.lineItemId === -1) return true

    const lineItem = lineItems.find(
      (lineItem) => lineItem.id === workItem.lineItemId
    )
    return lineItem && lineItem.billable
  })
  const unbillableItems = workItemLogs.filter(
    (workItem) =>
      !billableItems.find(
        (projectItem) => projectItem.jiraKey === workItem.jiraKey
      )
  )
  return { billableItems, unbillableItems }
}

function transformTimeCostReport(data, options) {
  let table = {
    header: {
      rows: makeHeaderRow(data.dateRanges, options),
    },
    body: {
      rows: [],
    },
  }

  // calculate sums
  const numWeeks = data.dateRanges.length
  const workItemLogs = consolidateWorkItems(data)
  const { billableItems, unbillableItems } = splitWorkItems(
    workItemLogs,
    data.contractLineItemLogs
  )
  const billableLineItems = data.contractLineItemLogs.filter(
    (item) => item.billable
  )
  const unbillableLineItems = data.contractLineItemLogs.filter(
    (item) => !item.billable
  )

  const { scope } = options
  if (scope === scopes.all || scope === scopes.billable) {
    table.body.rows = generateRows(billableLineItems, billableItems, {
      title: 'BILLABLE ACTIVITY',
      totalLabel: 'BILLABLE TOTALS',
      numWeeks,
      displayAllLineItems: true,
      ...options,
    })
  } else {
    table.body.rows = []
  }

  if (scope === scopes.all) {
    table.body.rows = [...table.body.rows, makeSpacerRow(numWeeks)]
  }

  if (
    (scope === scopes.all || scope === scopes.unbillable) &&
    unbillableItems.length > 0
  ) {
    table.body.rows = [
      ...table.body.rows,
      ...generateRows(unbillableLineItems, unbillableItems, {
        title: 'NON-BILLABLE ACTIVITY',
        totalLabel: 'NON-BILLABLE TOTALS',
        numWeeks,
        displayAllLineItems: true,
        ...options,
      }),
    ]
  }

  return table
}

export default transformTimeCostReport
