import { useCallback, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Error from '../primitives/Error'
import Info from '../primitives/Info'
import Loading from '../primitives/Loading'

import {
  fetchTimeCostReport,
  selectTimeCostReportData,
  selectLoadingState as selectTimeCostReportLoadingState,
} from '../slices/timeCostReport'

import LoadingState from '../utils/LoadingState'
import MissingRatesList from '../views/MissingRatesList'
import TimeCostReport from '../views/TimeCostReport'
import TempoLink from '../views/TempoLink'

const TimeCostReportContent = ({ data, periodId, projectId }) => {
  const history = useHistory()

  const goToProjectDetails = useCallback(() => {
    history.push(`/project-details?projectId=${projectId}`)
  }, [history, projectId])

  return (
    <>
      <TimeCostReport data={data} />
      <MissingRatesList
        onClick={goToProjectDetails}
        missingRates={data.missingStaffRates}
      />
      <TempoLink
        periodId={periodId}
        projectId={projectId}
        sx={{ float: 'right', mb: 2, mr: 1 }}
      />
    </>
  )
}

const TimeCostReportContainer = ({ periodId, projectId }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const timeCostReport = useSelector(selectTimeCostReportData)
  const reportLoadingState = useSelector(selectTimeCostReportLoadingState)

  const fetchReport = useCallback(() => {
    if (periodId > 0 && projectId > 0) {
      dispatch(fetchTimeCostReport({ periodId, projectId, getTokenCallback }))
    }
  }, [dispatch, periodId, projectId, getTokenCallback])

  useEffect(() => {
    if (reportLoadingState === LoadingState.idle) {
      fetchReport()
    }
  }, [reportLoadingState, fetchReport])

  if (reportLoadingState === LoadingState.idle) {
    return <Info>Please select a project.</Info>
  } else if (reportLoadingState === LoadingState.pending) {
    return <Loading />
  } else if (reportLoadingState === LoadingState.rejected) {
    return (
      <>
        <Error error={timeCostReport} retryCallback={fetchReport} />
        <TempoLink
          periodId={periodId}
          projectId={projectId}
          sx={{ float: 'right', mb: 2, mr: 1 }}
        />
      </>
    )
  } else {
    return (
      <TimeCostReportContent
        data={timeCostReport}
        periodId={periodId}
        projectId={projectId}
      />
    )
  }
}

export default TimeCostReportContainer
