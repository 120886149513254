export function sanitise(value) {
  return value.replace(/[^.0-9-]/g, '').replace(/(?<=^\d*\.\d{2})\d*/g, '')
}

export function validate(value) {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (!/^\s*$/.test(value) && !isNaN(Number(value))) // valid number and not only whitespace
  )
}
