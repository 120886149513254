import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography
} from '@mui/material'

import { useClientContext } from '../clientContext'
import Info from '../primitives/Info'

import {
  newProject,
  resetUpdateError,
  selectProject,
  selectUpdateError,
  selectUpdateState,
} from '../slices/project'
import { reset as resetProjects } from '../slices/projects'

import LoadingState from '../utils/LoadingState'

const SelectOrCreateProject = () => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const { isOperations } = useClientContext()
  const dispatch = useDispatch()
  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [jiraKey, setJiraKey] = useState('')

  const project = useSelector(selectProject)
  const errorMessage = useSelector(selectUpdateError)
  const updateState = useSelector(selectUpdateState)

  const createNewProject = useCallback(() => {
    dispatch(newProject({ jiraKey, getTokenCallback }))
  }, [dispatch, jiraKey, getTokenCallback])

  const handleChange = useCallback(
    (e) => {
      dispatch(resetUpdateError())
      setJiraKey(e.target.value)
    },
    [dispatch, setJiraKey]
  )

  const openDialog = useCallback(() => {
    dispatch(resetUpdateError())
    setOpen(true)
  }, [dispatch, setOpen])

  useEffect(() => {
    if (updateState === LoadingState.fulfilled && project !== null) {
      setOpen(false)
      dispatch(resetProjects())
      history.push(`/project-details?projectId=${project.projectId}`)
    }
  }, [dispatch, history, project, updateState, setOpen])

  const loadingMessage = 'Creating new project...'

  return (
    <>
      <Info>
        <span>Please select a project.</span>
        {isOperations ? (
          <Button
            onClick={openDialog}
            size="small"
            sx={{
              ml: 1,
              p: 0,
              textTransform: 'none',
              '& :hover': {
                textDecoration: 'underline',
              }
            }}
          >
            {' '}
            Or click here to create a new one from Jira.
          </Button>
        ) : null}
      </Info>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Box sx={{ mb: 1 }}>
            <Typography sx={{ mb: 0.5 }}>
              Create New Project
            </Typography>
            <TextField
              label="Jira Key"
              onChange={handleChange}
              size="small"
              value={jiraKey}
              variant="standard"
            />
            <Button
              aria-label="create-project"
              color="secondary"
              disabled={updateState === LoadingState.pending}
              onClick={createNewProject}
              size="small"
              sx={{ ml: 1, mt: 1.5 }}
              variant="contained"
            >
              Save
            </Button>
            {updateState === LoadingState.pending ? (
              <Typography sx={{ color: 'secondary.main' }}>{loadingMessage}</Typography>
            ) : errorMessage ? (
              <Typography sx={{ color: 'error.main' }}>{errorMessage}</Typography>
            ) : null}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SelectOrCreateProject
