import { useCallback } from 'react'
import dayjs, { locale } from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import {
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'

import { sanitise } from '../utils/moneyEditUtils'
import {
  minStaffRate,
  maxStaffRate,
  validateStaffRate,
} from '../utils/validation'

const nameWidth = 260
const dateWidth = 200
const rateWidth = 120

const Header = ({ children, width }) => (
  <Typography
    float="left"
    sx={{
      bgcolor: 'secondary.main',
      color: 'background.light',
      fontSize: 14,
      p: 2,
    }}
    textTransform="uppercase"
    width={width}
  >
    {children}
  </Typography>
)

const lineStyle = '2px solid'

const fieldSx = ({ theme, width }) => ({
  borderBottom: 'none',
  px: 2,
  py: 0.7,
  width,
  '& .MuiFormHelperText-root': { fontSize: '11px' },
  '& .MuiInputAdornment-root': { fontSize: '14px' },
  '& .MuiInputBase-input': { fontSize: '14px' },
  '& .MuiInput-underline:before': { borderBottom: 'none' },
  '& .MuiInput-underline.Mui-disabled:before': { borderBottom: 'none' },
  '& .MuiSelect-select:focus': { bgcolor: 'inherit' },
  '& .Mui-disabled': { WebkitTextFillColor: theme.palette.text.dark },
})

const RateControl = ({
  autoFocus,
  disabled,
  defaultRate,
  editedRate,
  existingRate,
  fromDate = '',
  id,
  isStaffFirstEntry = true,
  isStaffLastEntry = true,
  onChangeFromDate,
  onChangeStaffName,
  onChangeStaffRate,
  staffList,
  staffName,
}) => {
  const currentRate =
    typeof editedRate === 'string' ? editedRate : existingRate || defaultRate
  const isError = fromDate.length > 0 && !validateStaffRate(currentRate)
  const isNew = !existingRate
  const showDate = !isNew || staffName.length > 0
  const showRate = !isNew || (staffName.length > 0 && fromDate.length > 0)
  const isEdited =
    editedRate && parseFloat(editedRate) !== (existingRate || defaultRate)

  const helperText = isError
    ? `Invalid rate. Must be a dollar amount between $${minStaffRate} and $${maxStaffRate}`
    : isNew
    ? isEdited
      ? 'New - Edited'
      : 'Default Rate'
    : isEdited
    ? 'Edited'
    : ''

  const onDateChange = useCallback(
    (event) => {
      const newDate = dayjs(event.$d).format('YYYY-MM-DDT00:00:00')
      if (!onChangeFromDate || newDate === fromDate) {
        return
      }
      onChangeFromDate(newDate)
    },
    [fromDate, onChangeFromDate]
  )

  const onNameChange = useCallback(
    (event) => {
      const newName = event.target.value
      if (!onChangeStaffName || newName === staffName) {
        return
      }
      onChangeStaffName(newName)
    },
    [staffName, onChangeStaffName]
  )

  const onRateChange = useCallback(
    (event) => {
      const newRate = sanitise(event.target.value)
      if (!onChangeStaffRate || newRate === editedRate) {
        return
      }
      onChangeStaffRate(newRate)
    },
    [editedRate, onChangeStaffRate]
  )

  return (
    <Box
      sx={{
        bgcolor: isNew ? 'secondaryHighlight.light' : 'editable.main',
        borderBottom: isStaffLastEntry ? lineStyle : '',
        borderTop: isStaffFirstEntry ? lineStyle : '',
        borderColor: 'editable.dark',
        marginTop: isStaffFirstEntry ? '8px' : '',
      }}
    >
      <TextField
        autoFocus={autoFocus}
        data-testid={`${id}-name-TextField`}
        disabled={disabled || !isNew}
        id={`${id}-name-input`}
        onChange={onNameChange}
        select={isNew}
        sx={(theme) => fieldSx({ theme, width: nameWidth })}
        value={staffName}
        variant="standard"
        inputProps={{ 'data-testid': `${id}-name-input` }}
      >
        {isNew &&
          staffList.sort().map((staffName, idx) => (
            <MenuItem key={idx} style={{ fontSize: '14px' }} value={staffName}>
              {staffName}
            </MenuItem>
          ))}
      </TextField>
      {showDate && (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={locale('en-nz')}
        >
          <DatePicker
            data-testid={`${id}-date-input`}
            disabled={disabled || !isNew}
            disableOpenPicker={disabled || !isNew}
            helperText={fromDate === '' ? 'Select Date' : ''}
            format={'D MMMM YYYY'}
            onChange={onDateChange}
            slotProps={{
              textField: {
                sx: (theme) => fieldSx({ theme, width: dateWidth }),
                variant: 'standard',
              },
            }}
            value={dayjs(fromDate)}
          />
        </LocalizationProvider>
      )}
      {showRate && (
        <TextField
          data-testid={`${id}-rate-TextField`}
          disabled={disabled || (isNew && staffName.length === 0)}
          error={isError}
          id={`${id}-rate-input`}
          helperText={helperText}
          onChange={onRateChange}
          sx={(theme) => ({
            ...fieldSx({ theme, width: rateWidth }),
            bgcolor: isEdited
              ? 'warning.light'
              : isNew
              ? 'secondaryHighlight.light'
              : 'background.main',
            '& .MuiFormHelperText-root': { color: isError && 'error.main' },
          })}
          value={currentRate}
          variant="standard"
          inputProps={{ 'data-testid': `${id}-rate-input` }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography
                  sx={{
                    color: isError ? 'error.main' : 'text.dark',
                    fontSize: 14,
                  }}
                >
                  $
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      )}
    </Box>
  )
}

const RateControlHeaderRow = () => (
  <div style={{ display: 'flex' }}>
    <Header width={nameWidth}>Name</Header>
    <Header width={dateWidth}>Effective From</Header>
    <Header width={rateWidth}>Rate</Header>
  </div>
)

export { RateControl as default, RateControlHeaderRow }
