import moment from 'moment'

export const rowSumTotalsLabel = 'All Staff'

const LEADERSHIP_GROUP_NAME = 'Leadership'

const flattenArrays = (arr1 = [], arr2 = []) => [...arr1, ...arr2]
const formatDateCsv = (date) => moment(date).format('DD/MM/YYYY')

// Constructs a hierarchy of groups => teams => person on a weekly basis based on data from work logs
export const createResourceHierarchy = (periods) => {
  const unorderedHierarchy = {}
  periods.forEach((period) => {
    period.weeks.forEach((week) => {
      for (const groupKey in week.logs) {
        if (groupKey === 'None') {
          continue
        }
        if (!unorderedHierarchy[groupKey] && Object.keys(week.logs[groupKey]).length > 0) {
          unorderedHierarchy[groupKey] = {}
        }

        const group = week.logs[groupKey]
        for (const teamKey in group) {
          if (teamKey === 'None') {
            continue
          }
          if (!unorderedHierarchy[groupKey][teamKey] && Object.keys(group[teamKey]).length > 0) {
            unorderedHierarchy[groupKey][teamKey] = []
          }

          const team = group[teamKey]
          for (const personKey in team) {
            if (!unorderedHierarchy[groupKey][teamKey].includes(personKey)) {
              unorderedHierarchy[groupKey][teamKey].push(personKey)
            }
          }
        }
      }
    })
  })

  const sortedGroups = []
  if (unorderedHierarchy[LEADERSHIP_GROUP_NAME]) {
    sortedGroups.push(LEADERSHIP_GROUP_NAME)
  }
  sortedGroups.push(
    ...Object.keys(unorderedHierarchy)
      .filter((el) => el !== LEADERSHIP_GROUP_NAME)
      .sort()
  )

  const sortedHierarchy = {}
  sortedGroups.forEach((groupKey) => {
    const teams = {}
    const sortedTeams = Object.keys(unorderedHierarchy[groupKey]).sort()
    sortedTeams.forEach((teamKey) => {
      teams[teamKey] = [...unorderedHierarchy[groupKey][teamKey]].sort()
    })
    sortedHierarchy[groupKey] = teams
  })

  // Rebuild with objects containing the person's name and a unique index at the lowest level.
  let resourceIndex = 1
  const resourceHierarchy = {}
  for (const [groupName, group] of Object.entries(sortedHierarchy)) {
    if (!resourceHierarchy[groupName]) {
      resourceHierarchy[groupName] = {}
    }

    for (const [teamName, team] of Object.entries(group)) {
      if (!resourceHierarchy[groupName][teamName]) {
        resourceHierarchy[groupName][teamName] = []
      }

      for (const person of team) {
        resourceHierarchy[groupName][teamName].push({ name: person, index: resourceIndex++ })
      }
    }
  }

  return resourceHierarchy
}

// Creates column headers for the chargeability table, constructed from the resourceHierarchy.
// Current design is to have people appear in ALL teams that they have historically appeared in.
// Note that the header array starts with an empty object to account for the 'All Staff' column.
export const createResourceHeaders = (resourceHierarchy, resourceLevel) => {
  switch (resourceLevel) {
    case 'group':
      return [{ index: 0, name: '', isFirstInGroup: true, isFirstInTeam: true }].concat(
        Object.keys(resourceHierarchy).map((groupName, groupIdx) => ({
          index: groupIdx + 1,
          name: groupName,
          isFirstInGroup: true,
          isFirstInTeam: true,
        }))
      )
    case 'team':
      let teamCount = 1
      return [{ index: 0, name: '', isFirstInGroup: true, isFirstInTeam: true }].concat(
        Object.values(resourceHierarchy)
          .map((group) =>
            Object.keys(group).map((teamName, teamIdx) => ({
              index: teamCount++,
              name: teamName,
              isFirstInGroup: teamIdx === 0,
              isFirstInTeam: true,
            }))
          )
          .reduce(flattenArrays)
      )
    case 'person':
      return [{ index: 0, name: '', isFirstInGroup: true, isFirstInTeam: true }].concat(
        Object.values(resourceHierarchy)
          .map((group) =>
            Object.values(group)
              .map((team, teamIdx) =>
                team.map((person, personIdx) => ({
                  index: person.index,
                  name: person.name,
                  isFirstInGroup: teamIdx === 0 && personIdx === 0,
                  isFirstInTeam: personIdx === 0,
                }))
              )
              .reduce(flattenArrays)
          )
          .reduce(flattenArrays)
      )
    default:
      return
  }
}

// Constructs a hierarchy of year => month => week based on data from work logs
// IMPORTANT: Assumes logs always start at the beginning of a financial year.
export const createTimeFrameHierarchy = (periods) => {
  if (periods.length === 0) {
    return []
  }

  const nYears = Math.ceil(periods.length / 12)
  const firstYear = new Date(periods[0].startDate).getFullYear()

  const years = Array.from({ length: nYears }, (_, i) => firstYear + i)

  return years.map((year, yearIdx) => {
    const startingMonthIdx = 12 * yearIdx
    const nMonths = Math.min(12, periods.length - startingMonthIdx)
    const endingMonthIdx = startingMonthIdx + nMonths - 1

    const months = periods.slice(startingMonthIdx, endingMonthIdx + 1).map((month, monthIdx) => {
      const { endDate, startDate, weeks: weeksData } = month

      const weeks = weeksData.map((week, weekIdx) => {
        const { endDate, startDate } = week

        const weekLabel = moment(startDate).format('D MMM') + ' - ' + moment(endDate).format('D MMM')
        return {
          endDate,
          isFirstInTimeFrame: weekIdx === 0,
          label: weekLabel,
          startDate,
        }
      })

      const monthLabel = moment(endDate).format('MMM YYYY')
      return {
        endDate,
        isFirstInTimeFrame: monthIdx === 0,
        label: monthLabel,
        startDate,
        weeks,
      }
    })

    const yearLabel = `${year} - ${year + 1}`
    return {
      endDate: periods[endingMonthIdx].endDate,
      isFirstInTimeFrame: false,
      label: yearLabel,
      months,
      startDate: periods[startingMonthIdx].startDate,
    }
  })
}

// Creates row headers for the chargeability table
export const createTimeFrameHeaders = (timeFrameHierarchy, timeFrame) => {
  switch (timeFrame) {
    case 'year':
      return timeFrameHierarchy.map(({ months, ...yearDetails }) => yearDetails).reverse()
    case 'month':
      return timeFrameHierarchy
        .map((year) =>
          year.months.map((month) => {
            const { weeks, ...monthDetails } = month
            return monthDetails
          })
        )
        .reduce(flattenArrays)
        .reverse()
    case 'week':
      return timeFrameHierarchy
        .map((year) => year.months.map((month) => month.weeks).reduce(flattenArrays))
        .reduce(flattenArrays)
        .reverse()
    default:
      return
  }
}

const calculateReferenceHours = ({ leaveHours, standardHours, totalHours }, adjusted = false) => {
  // original calculation
  // if standardHours is 0 --> casual --> use totalHours
  if (!adjusted) {
    return standardHours === 0 ? totalHours : standardHours
  }

  // leave-adjusted calculation
  // if any fails check, fail whole resgrp
  // check if any hours are null or NaN, if leave > std
  if (leaveHours > standardHours) {
    // if leave hours exceed standard hours --> error
    return NaN
  } else if (leaveHours === standardHours) {
    // if whole period is on leave, treat as casual, i.e. use total hours as reference
    return totalHours - leaveHours
  } else {
    // at least some hours not on leave
    return standardHours - leaveHours
  }
}

// Filters a resource hierarchy based on the supplied employeeNames.
// All employees who are in employeeNames will be retained and those who are not will be omitted.
export const filterResourceHierarchy = (resourceHierarchy, employeeNames) => {
  const filteredPeopleHiearchy = {}
  for (const [groupName, group] of Object.entries(resourceHierarchy)) {
    if (!filteredPeopleHiearchy[groupName]) {
      filteredPeopleHiearchy[groupName] = {}
    }
    for (const [teamName, team] of Object.entries(group)) {
      if (!filteredPeopleHiearchy[groupName][teamName]) {
        filteredPeopleHiearchy[groupName][teamName] = []
      }
      team.forEach((person) => {
        if (employeeNames.includes(person.name) || person.name === rowSumTotalsLabel) {
          filteredPeopleHiearchy[groupName][teamName].push(person)
        }
      })
    }
  }

  const filteredTeamsAndPeopleHierarchy = {}
  for (const [groupName, group] of Object.entries(filteredPeopleHiearchy)) {
    if (!filteredTeamsAndPeopleHierarchy[groupName]) {
      filteredTeamsAndPeopleHierarchy[groupName] = {}
    }
    for (const [teamName, team] of Object.entries(group)) {
      if (team.length > 0) {
        filteredTeamsAndPeopleHierarchy[groupName][teamName] = team
      }
    }
  }

  const filteredHierarchy = {}
  for (const [groupName, group] of Object.entries(filteredTeamsAndPeopleHierarchy)) {
    if (Object.keys(group).length > 0) {
      filteredHierarchy[groupName] = group
    }
  }

  return filteredHierarchy
}

const getHoursArray = (hours = {}) => [
  hours.billableHours || 0,
  calculateReferenceHours(hours) || 0,
  calculateReferenceHours(hours, true) || 0,
]

// Aggregates log data into matrices for each resource level on a weekly basis.
// Note that this is a 3D matrix, i.e. [weeks][resources][hours],
// where hours is a three-element array
const createResourceLevelHoursMatrices = (periods, resourceHierarchy) => {
  const peopleHoursMatrix = [],
    teamsHoursMatrix = [],
    groupsHoursMatrix = []
  let rowIdx = 0

  periods.forEach((period) => {
    period.weeks.forEach((week) => {
      const pMat = [],
        tMat = [],
        gMat = []
      let allHours = [0, 0, 0],
        pColIdx = 0,
        tColIdx = 0

      Object.entries(resourceHierarchy).forEach(([groupKey, group], gColIdx) => {
        let groupHours = [0, 0, 0]

        Object.entries(group).forEach(([teamKey, team]) => {
          let teamHours = [0, 0, 0]

          team.forEach((person) => {
            const personLogs = ((week.logs[groupKey] || {})[teamKey] || {})[person.name]
            const hoursArray = getHoursArray(personLogs)
            pMat[pColIdx] = hoursArray
            teamHours.forEach((_, i) => (teamHours[i] += hoursArray[i]))
            pColIdx++
          })

          tMat[tColIdx] = teamHours
          groupHours.forEach((_, i) => (groupHours[i] += teamHours[i]))
          tColIdx++
        })

        gMat[gColIdx] = groupHours
        allHours.forEach((_, i) => (allHours[i] += groupHours[i]))
      })

      peopleHoursMatrix[rowIdx] = [allHours, ...pMat]
      teamsHoursMatrix[rowIdx] = [allHours, ...tMat]
      groupsHoursMatrix[rowIdx] = [allHours, ...gMat]
      rowIdx++
    })
  })

  return {
    person: peopleHoursMatrix,
    team: teamsHoursMatrix,
    group: groupsHoursMatrix,
  }
}

// Aggregates matrices from a weekly timeframe to monthly and yearly ones for a particular resource
// Note that these are 3D matrices, i.e. [timeframes][resources][hours],
// where hours is a three-element array
// TODO: Investigate whether this is inefficient, as this loops through the matrices a second time.
const createTimeFrameHoursMatrices = (weeklyHoursMatrix, timeFrameHierarchy) => {
  const monthlyHoursMatrix = [],
    yearlyHoursMatrix = []
  let yearCount = 0,
    monthCount = 0,
    weekCount = 0

  timeFrameHierarchy.forEach((year) => {
    const yearMatrix = []

    year.months.forEach((month) => {
      const monthMatrix = []

      month.weeks.forEach(() => {
        ;(weeklyHoursMatrix[weekCount] || []).forEach((weeklyHoursByResource, i) => {
          if (!monthMatrix[i]) {
            monthMatrix[i] = []
          }
          monthMatrix[i] = weeklyHoursByResource.map((hours, j) => (monthMatrix[i][j] || 0) + hours)
          if (!yearMatrix[i]) {
            yearMatrix[i] = []
          }
          yearMatrix[i] = weeklyHoursByResource.map((hours, j) => (yearMatrix[i][j] || 0) + hours)
        })
        weekCount++
      })

      monthlyHoursMatrix[monthCount] = monthMatrix
      monthCount++
    })

    yearlyHoursMatrix[yearCount] = yearMatrix
    yearCount++
  })

  return { monthlyHoursMatrix, yearlyHoursMatrix }
}

const convertWeeksToAllTimeFrames = (weeklyHoursMatrix, timeFrameHierarchy) => {
  const { monthlyHoursMatrix, yearlyHoursMatrix } = createTimeFrameHoursMatrices(weeklyHoursMatrix, timeFrameHierarchy)
  return {
    week: weeklyHoursMatrix,
    month: monthlyHoursMatrix,
    year: yearlyHoursMatrix,
  }
}

const mapRowToChargeability = ([billableHours, referenceHours, _]) => billableHours / referenceHours
const mapMatrixToChargeability = (matrix) => matrix.map(mapRowToChargeability)

const mapRowToAdjustedChargeability = ([billableHours, _, adjustedRefHours]) => billableHours / adjustedRefHours
const mapMatrixToAdjustedChargeability = (matrix) => matrix.map(mapRowToAdjustedChargeability)

const convertHoursToChargeability = (matrix) => [
  matrix.map(mapMatrixToChargeability).reverse(),
  matrix.map(mapMatrixToAdjustedChargeability).reverse(),
]

/**
 * Filter any leading invalid periods out of the given period array.
 * An invalid period is one that doesn't have 4-5 weeks. This accounts for the 2 pseudo periods included at the start
 * of the periods DB table.
 * @param {*} periods
 * @returns
 */
const filterInvalidLeadingPeriods = (periods) => {
  const firstValidPeriodIndex = periods.findIndex((period) => period.weeks.length === 4 || period.weeks.length === 5)
  return periods.filter((_, i) => i >= firstValidPeriodIndex)
}

// Calls other processes to generate matrices with elements that are arrays of
// summations of hours [billableHours, referencHours, adjustedRefHours] across
// respective timeframe and resource levels.
// Then calculates chargeability (both unadjusted and adjusted) from sums
// and splits them into separate matrices.
export const createAllMatrices = (periods) => {
  periods = filterInvalidLeadingPeriods(periods)

  const chargeabilityMatrices = {
    week: { person: [], team: [], group: [] },
    month: { person: [], team: [], group: [] },
    year: { person: [], team: [], group: [] },
  }

  const resourceHierarchy = createResourceHierarchy(periods)
  const timeFrameHierarchy = createTimeFrameHierarchy(periods)

  const resourceLevelHoursMatrices = createResourceLevelHoursMatrices(periods, resourceHierarchy)
  Object.entries(resourceLevelHoursMatrices).forEach(([resourceLevel, resourceLevelHoursMatrix]) => {
    const timeFrameHoursMatrices = convertWeeksToAllTimeFrames(resourceLevelHoursMatrix, timeFrameHierarchy)
    Object.entries(timeFrameHoursMatrices).forEach(([timeFrame, timeFrameHoursMatrix]) => {
      chargeabilityMatrices[timeFrame][resourceLevel] = convertHoursToChargeability(timeFrameHoursMatrix)
    })
  })

  return {
    chargeabilityMatrices,
    resourceHierarchy,
    timeFrameHierarchy,
  }
}

export const generateCsvFromChargeability = (
  resourceLevel,
  { filteredResourceHierarchy, allRowDataHeaders, allRowDataValues }
) => {
  const csvLines = []
  const dateHeaders = ['Start Date', 'End Date']

  const valueIndexes = [0]
  if (resourceLevel === 'group') {
    const groupHeader = [...dateHeaders, 'All Staff']
    Object.keys(filteredResourceHierarchy).forEach((groupName) => {
      groupHeader.push(groupName)
      valueIndexes.push(valueIndexes.length)
    })
    csvLines.push(groupHeader)
  } else if (resourceLevel === 'team') {
    const groupHeader = Array(2).fill('-').concat('All Staff')
    const teamHeader = [...dateHeaders, 'Team']
    Object.entries(filteredResourceHierarchy).forEach(([groupName, group]) => {
      Object.keys(group).forEach((teamName) => {
        groupHeader.push(groupName)
        teamHeader.push(teamName)
        valueIndexes.push(valueIndexes.length)
      })
    })
    csvLines.push(groupHeader, teamHeader)
  } else if (resourceLevel === 'person') {
    const groupHeader = Array(2).fill('-').concat('All Staff')
    const teamHeader = Array(2).fill('-').concat('Team')
    const personHeader = [...dateHeaders, 'Person']
    Object.entries(filteredResourceHierarchy).forEach(([groupName, group]) => {
      Object.entries(group).forEach(([teamName, team]) => {
        team.forEach((person) => {
          personHeader.push(person.name)
          teamHeader.push(teamName)
          groupHeader.push(groupName)
          valueIndexes.push(person.index)
        })
      })
    })
    csvLines.push(groupHeader, teamHeader, personHeader)
  }

  allRowDataHeaders.forEach((_, lineNumber) => {
    const rowDataValues = []
    valueIndexes.forEach((index) => {
      const float = allRowDataValues[lineNumber][index]
      rowDataValues.push(isNaN(float) ? '' : float.toLocaleString('en-NZ', { style: 'percent' }))
    })

    csvLines.push([
      formatDateCsv(allRowDataHeaders[lineNumber].startDate),
      formatDateCsv(allRowDataHeaders[lineNumber].endDate),
      ...rowDataValues,
    ])
  })

  return csvLines.map((line) => line.join()).join('\n')
}
