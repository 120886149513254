import { Button as MuiButton, Box } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'
import { Tabs, Tab } from '@mui/material'

import { INVOICE_DRAFT_ID } from './constants/InvoiceConstants'

import { TabScrollButton } from '@mui/material'
import { withStyles } from '@mui/styles'

const HOVER_COLOUR = 'tab.main'
const SELECTED_COLOUR = 'background.light'
const DEFAULT_COLOUR = 'editable.light'

export default function InvoiceTabSelector({
  displayTabs,
  invoices,
  selectedInvoiceId,
  setInvoiceId,
  newInvoiceButtonAction,
  disableNewInvoiceButton,
  border,
  borderColour,
}) {
  const commonTabSx = {
    minWidth: '90px',
    minHeight: '30px',
    height: '30px',
    fontSize: '12px',
  }

  const ModifiedTabScrollButton = withStyles((theme) => ({
    root: {
      width: 28,
      overflow: 'hidden',
      transition: 'width 0.3s',
      '&.Mui-disabled': {
        width: 0,
      },
      ':hover': {
        backgroundColor: HOVER_COLOUR,
      },
    },
  }))(TabScrollButton)

  const selectedInvoiceIndex = invoices.findIndex((invoice) => invoice.id === selectedInvoiceId)

  // Only display if the selected tab is not the one just before the button
  const displayButtonLeftSeparator = selectedInvoiceIndex !== invoices.length - 1
  const displayEmptySpaceBox = invoices.length > 0

  return (
    <Box sx={{ mt: 1 }} display="flex" flexDirection="row" justifyContent="flex-start" align-items="stretch">
      <Box
        boxShadow={1}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: '100%',
          borderLeft: border,
          borderRight: border,
          borderTop: border,
          borderColor: borderColour,
        }}
      >
        {displayTabs ? (
          <Tabs
            value={selectedInvoiceId ?? 0}
            onChange={(_event, newValue) => setInvoiceId(newValue)}
            TabIndicatorProps={{ style: { display: 'none' } }}
            sx={{
              backgroundColor: 'tab.light',
              transition: 'background-color 0.12s ease',
              '& .MuiTabScrollButton-root': {
                borderBottom: border,
                borderColor: borderColour,
              },
              ...(displayButtonLeftSeparator
                ? {
                    '&:before': {
                      content: '" "',
                      position: 'absolute',
                      left: 0,
                      display: 'block',
                      height: 15,
                      width: '1px',
                      zIndex: 1,
                      backgroundColor: 'tab.main',
                    },
                  }
                : {}),
              borderRadius: 0,
              justifyContent: 'center',
              height: '100%',
              ...commonTabSx,
            }}
            variant="scrollable"
            scrollButtons="auto"
            ScrollButtonComponent={ModifiedTabScrollButton}
          >
            {invoices.map((invoice, index, invoices) => {
              // Whether or not to display a small separator on the left of the tab
              const displayLeftSeparator = ![
                invoices[selectedInvoiceIndex]?.id,
                invoices[selectedInvoiceIndex + 1]?.id,
              ].includes(invoice.id)
              const { label, icon } = GetInvoiceTabLabelOrIcon(invoice)

              return (
                <Tab
                  key={index}
                  label={label}
                  icon={icon}
                  value={invoice.id}
                  aria-label={`invoice-${label}`}
                  sx={{
                    ...commonTabSx,
                    transition: 'background-color 0.12s ease',
                    ':hover': {
                      backgroundColor: HOVER_COLOUR,
                    },
                    ':hover&.Mui-selected': {
                      backgroundColor: SELECTED_COLOUR,
                      color: 'primary.main',
                      fontWeight: 'bold',
                    },
                    '&.Mui-selected': {
                      backgroundColor: SELECTED_COLOUR,
                      color: 'primary.main',
                      borderLeft: index === 0 ? 'none' : border,
                      borderRight: index === invoices.length - 1 && disableNewInvoiceButton ? 'none' : border,
                      borderColor: borderColour,
                      fontWeight: 'bold',
                    },
                    ':not(.Mui-selected)': {
                      borderBottom: border,
                      borderColor: borderColour,
                    },
                    ...(displayLeftSeparator
                      ? {
                          '&:not(:first-of-type)': {
                            '&:before': {
                              content: '" "',
                              position: 'absolute',
                              left: 0,
                              display: 'block',
                              height: 15,
                              width: '1px',
                              zIndex: 1,
                              backgroundColor: 'editable.dark',
                            },
                          },
                        }
                      : {}),
                  }}
                />
              )
            })}
          </Tabs>
        ) : null}
        {disableNewInvoiceButton ? null : (
          <Box>
            <MuiButton
              onClick={newInvoiceButtonAction}
              aria-label="create-new-invoice"
              sx={{
                backgroundColor: DEFAULT_COLOUR,
                transition: 'background-color 0.12s ease',
                '&:hover': {
                  backgroundColor: HOVER_COLOUR,
                },
                borderBottom: border,
                borderColor: borderColour,
                ...(displayButtonLeftSeparator
                  ? {
                      '&:before': {
                        content: '" "',
                        position: 'absolute',
                        left: 0,
                        display: 'block',
                        height: 15,
                        width: '1px',
                        zIndex: 1,
                        backgroundColor: 'editable.dark',
                      },
                    }
                  : {}),
                borderRadius: 0,
                justifyContent: 'center',
                height: '100%',
                ...commonTabSx,
              }}
            >
              {<Add style={{ color: 'primary' }} />}
            </MuiButton>
          </Box>
        )}
      </Box>
      {displayEmptySpaceBox ? <Box sx={{ width: '100%', borderBottom: border, borderColor: borderColour }} /> : null}
    </Box>
  )
}

/**
 * Remaps a label to the correct form for rendering it. It will return an object containing either a label or an icon
 * @param {*} label label to remap
 * @returns remapped label
 */
function GetInvoiceTabLabelOrIcon(invoice) {
  if (invoice.id === INVOICE_DRAFT_ID) {
    return { icon: <Edit /> }
  }
  return { label: invoice.label }
}
