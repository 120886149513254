import { useCallback, useMemo } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'

import InvoiceMoneyEditCell from './InvoiceMoneyEditCell'
import config from '../config'

import colours from '../styles/colours'
import { formatMoney, sum } from '../utils/invoiceUtils'
import { sanitise } from '../utils/moneyEditUtils'

const HeaderAndFooterRow = ({ children }) => (
  <TableRow
    sx={{
      backgroundColor: 'primary.main',
      border: 'none',
      color: 'primary.contrastText',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }}
  >
    {children}
  </TableRow>
)

const spacerSx = {
  backgroundColor: colours.backgroundDefault,
  height: 8,
  minWidth: 8,
  px: 0,
  py: 0,
  width: 8,
}

const numericSx = { textAlign: 'right', width: 120 }
const isUnassignedSx = { backgroundColor: 'warning.main', color: 'warning.contrastText' }
const greenSx = { backgroundColor: 'success.main', color: 'success.contrastText' }
const sumSx = { backgroundColor: colours.awaGrayUltralight, color: colours.awaGray }

const Cell = ({ children, green, numeric, sum, isUnassigned, spacer, colSpan, description }) => {
  const sx = {
    borderColor: colours.awaGrayUltralight,
    color: 'inherit',
    borderTop: 'none',
    fontWeight: 'inherit',
    fontSize: 11,
    height: 25,
    minWidth: 0,
    px: 1,
    py: 0.5,
  }

  if (isUnassigned) {
    Object.assign(sx, isUnassignedSx)
  } else if (green) {
    Object.assign(sx, greenSx)
  } else if (sum) {
    Object.assign(sx, sumSx)
  }

  if (numeric) {
    Object.assign(sx, numericSx)
  }
  if (spacer) {
    Object.assign(sx, spacerSx)
  }

  const cell = (
    <TableCell colSpan={colSpan} sx={sx}>
      {children}
    </TableCell>
  )

  return description ? (
    <Tooltip title={description} placement="bottom-start">
      {cell}
    </Tooltip>
  ) : (
    cell
  )
}

const InvoiceTable = ({ editEnabled, invoiceLines, onEditInvoiceLines, defaultWidth }) => {
  const handleChangeAmount = useCallback(
    (lineItemId, newAmount) => {
      const updatedInvoiceLines = invoiceLines.map((line) => {
        if (line.lineItemId === lineItemId) {
          return {
            ...line,
            amount: sanitise(newAmount),
          }
        }
        return line
      })
      onEditInvoiceLines(updatedInvoiceLines)
    },
    [invoiceLines, onEditInvoiceLines]
  )

  const sums = useMemo(() => sum(invoiceLines), [invoiceLines])

  // Sort unassigned line item to the end
  const sortedInvoiceLines = [...invoiceLines].sort((a, b) =>
    a.name === config.unassignedLineItemName ? 1 : b.name === config.unassignedLineItemName ? -1 : 0
  )

  return (
    <TableContainer style={{ maxWidth: defaultWidth }}>
      <Table>
        <TableHead>
          <HeaderAndFooterRow>
            <Cell>Contract Line Items</Cell>
            <Cell numeric description={'Amount to invoice this period'}>
              Invoice Amount
            </Cell>
            <Cell spacer />
            <Cell numeric description={'Rate x Total Hours to Date'}>
              Project Fees
            </Cell>
          </HeaderAndFooterRow>
        </TableHead>
        <TableBody>
          {sortedInvoiceLines.map((line, idx) => {
            const isUnassigned = line.name === config.unassignedLineItemName
            return (
              <TableRow
                key={idx}
                style={{
                  backgroundColor: idx % 2 === 1 ? colours.awaGrayUltralight : '',
                  border: 'none',
                }}
              >
                <Cell
                  isUnassigned={isUnassigned}
                  colSpan={isUnassigned ? 2 : 1} // Unassigned Row header spans 2 columns - see below
                  description={isUnassigned ? 'Work not allocated to any contract line item' : null}
                >
                  {line.name}
                </Cell>
                {isUnassigned ? null : editEnabled ? ( // Only render an invoice amount cell for contract line items
                  <InvoiceMoneyEditCell
                    displayValue={formatMoney(line.amount)}
                    id={line.lineItemId}
                    onChange={(event) => handleChangeAmount(line.lineItemId, event.target.value)}
                    value={line.amount}
                  />
                ) : (
                  <Cell numeric>{formatMoney(line.amount)}</Cell>
                )}
                <Cell spacer />
                <Cell numeric isUnassigned={isUnassigned}>
                  {formatMoney(line.totalWip)}
                </Cell>
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter>
          <HeaderAndFooterRow>
            <Cell>Total</Cell>
            <Cell numeric>{formatMoney(sums.amount)}</Cell>
            <Cell spacer />
            <Cell numeric>{formatMoney(sums.totalWip)}</Cell>
          </HeaderAndFooterRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default InvoiceTable
