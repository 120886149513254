import { useCallback } from 'react'

import DeleteIcon from '@mui/icons-material/Close'
import { Button as MaterialButton, TextField } from '@mui/material'

import colours from '../styles/colours'
import { validateEmail } from '../utils/validation'

const InvoiceRecipientControl = ({
  id,
  disabled,
  originalEmail,
  email = '',
  onChangeEmail,
  onDelete,
}) => {
  const handleChangeEmail = useCallback(
    (event) => {
      if (onChangeEmail) {
        onChangeEmail(event.target.value)
      }
    },
    [onChangeEmail]
  )

  const isNew = originalEmail === null

  const edited = !isNew && originalEmail !== email
  const error = !validateEmail(email)

  const emailHelperText = error
    ? 'Please enter a valid email address'
    : isNew
    ? 'New'
    : edited
    ? 'Edited'
    : null

  return (
    <>
      <TextField
        fullWidth
        disabled={disabled}
        error={!validateEmail(email)}
        helperText={emailHelperText}
        inputProps={{ 'data-testid': `${id}-email-input` }}
        onChange={handleChangeEmail}
        sx={{
          backgroundColor: isNew
            ? colours.awaLightGreenOld
            : edited
            ? colours.awaLightYellow
            : 'inherit',
          float: 'left',
          ml: 1,
          mt: 1,
          width: 300,
        }}
        value={email}
        variant="standard"
        data-testid={`${id}-email-TextField`}
      />
      <MaterialButton
        aria-label="delete"
        disabled={disabled}
        onClick={onDelete}
        sx={{ float: 'left', ml: 1, mt: 1, width: 24 }}
      >
        <DeleteIcon />
      </MaterialButton>
    </>
  )
}

export default InvoiceRecipientControl
