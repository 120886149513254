import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'

import {
  useQueryParam,
  DelimitedArrayParam,
  StringParam,
  withDefault,
} from 'use-query-params'

import config from '../config'
import CompanyWorkbook from '../containers/CompanyWorkbook'
import Error from '../primitives/Error'
import Loading from '../primitives/Loading'

import {
  fetchCompanyWorkbook,
  selectCompanyWorkbookData,
  selectLoadingState,
} from '../slices/companyWorkbook'

import {
  fetchInvoicingApproaches,
  selectLoadingState as fetchLoadingInvoicingApproaches,
} from '../slices/invoicingApproaches'

import { presetViewNames } from '../utils/dataGridPresetViewUtils'
import LoadingState from '../utils/LoadingState'

import CompanyWorkbookCompletionStatusSelector from '../views/CompanyWorkbookCompletionStatusSelector'
import CompanyWorkbookMyTeamSwitch from '../views/CompanyWorkbookMyTeamSwitch'
import CompanyWorkbookPresetViewSelector from '../views/CompanyWorkbookPresetViewSelector'
import CompanyWorkbookViewerTypeSelector from '../views/CompanyWorkbookViewerTypeSelector'
import CompanyWorkbookViewModeSelector from '../views/CompanyWorkbookViewModeSelector'
import { useClientContext } from '../clientContext'
import { selectActiveWorkbookStep } from '../slices/compoundSelectors/activeStepSelector'
import { workbookFrontendStatus } from '../utils/steps'

// Converts from viewerType (string) to columnName (string)
// Currently, this mapping is unnecessary as they are equivalent,
// but it makes the conversion explicit in case of future changes
const { clientManager, projectManager, projectDirector } = config.viewerTypes
const viewerTypeColNameMap = {
  [clientManager]: 'clientManager',
  [projectManager]: 'projectManager',
  [projectDirector]: 'projectDirector',
}

/**
 * Checks if reconciliation editing is available for the given user based on the the current workbook status and
 * the user's reconciliation access based on their role.
 * @param {*} frontendStatus current frontend workbook status. Must be in reconciling state to return true.
 * @param {*} hasReconciliationAccess User's reconciliation edit access based on their role.
 * @returns true if the user has reconciliation edit access, false if not.
 */
function isReconciliationEditingAvailable(
  frontendStatus,
  hasReconciliationAccess
) {
  const hasAccess =
    frontendStatus === workbookFrontendStatus.reconciling &&
    hasReconciliationAccess
  return hasAccess
}

/**
 * Checks if invoicing editing is available for the given user based on the current workbook status and the view
 * types selected by the user.
 * @param {*} frontendStatus - Current frontend workbook status. Must be in invoicing state to return true.
 * @param {*} enabledViewerTypes - List of viewer types selected by the user.
 * @returns - True if the user has invoicing edit access, false if not.
 */
function isInvoicingEditingAvailable(frontendStatus, enabledViewerTypes) {
  if (frontendStatus !== workbookFrontendStatus.invoicing) {
    return false
  }
  // If any option of 'PM', 'PD', or 'CM' is selected, the user can edit the viewable lines
  const anyViewerTypeSelected = enabledViewerTypes.length > 0
  return anyViewerTypeSelected
}

/**
 * Checks if the PD reviewing editing is available for the given user based on the current workbook status and view types.
 * selected by the user. If the user has selected project manager view, they won't be able to edit the workbook lines.
 * @param {*} frontendStatus - Current frontend workbook status. Must be in invoicing state to return true.
 * @param {*} enabledViewerTypes - List of viewer types selected by the user.
 * @returns - True if the user has PD reviewing edit access, false if not.
 */
function isPdReviewingEditingAvailable(frontendStatus, enabledViewerTypes) {
  if (frontendStatus !== workbookFrontendStatus.reviewing) {
    return false
  }
  const reviewEditableViewerTypes = [clientManager, projectDirector]
  const reviewNonEditableViewerTypes = [projectManager]

  const hasEditableViewerTypeEnabled = enabledViewerTypes.some((vt) =>
    reviewEditableViewerTypes.includes(vt)
  )
  const hasNonEditableViewerTypeEnabled = enabledViewerTypes.some((vt) =>
    reviewNonEditableViewerTypes.includes(vt)
  )

  // Only allow editing if one or more of the editable viewer types are enabled and none of the non editable
  // viewer types are enabled
  const pdReviewingEditAccessPresent =
    hasEditableViewerTypeEnabled && !hasNonEditableViewerTypeEnabled
  return pdReviewingEditAccessPresent
}

/**
 * Checks if editing is available for the current user given the currently enabled and disabled options.
 * @param {*} frontendStatus - The current workbook frontend status. E.G. 'reviewing', 'invoicing', 'closed'.
 * @param {*} hasReconciliationAccess - True if the user has reconciliation editing access, false if not.
 * @param {*} enabledViewerTypes - List of currently enabled viewer types, E.G. 'projectDirectory', 'clientManager', etc.
 * @returns - True if the user has editing access, false if not.
 */
function isEditEnabled(
  frontendStatus,
  hasReconciliationAccess,
  enabledViewerTypes
) {
  const reconcilingEditingAvailable = isReconciliationEditingAvailable(
    frontendStatus,
    hasReconciliationAccess
  )
  const invoicingEditingAvailable = isInvoicingEditingAvailable(
    frontendStatus,
    enabledViewerTypes
  )
  const pdReviewingEditingAvailable = isPdReviewingEditingAvailable(
    frontendStatus,
    enabledViewerTypes
  )

  return (
    reconcilingEditingAvailable ||
    invoicingEditingAvailable ||
    pdReviewingEditingAvailable
  )
}

/**
 * Gets the viewer type to set by default if none are included in the query parameters.
 * @param {*} frontendStatus - The current workbook frontend status. E.G. 'reviewing', 'invoicing', 'closed'.
 * @returns - List of selected viewer types.
 */
function getDefaultViewerType(frontendStatus) {
  if (frontendStatus === workbookFrontendStatus.reviewing) {
    return [projectDirector]
  }
  return [projectManager]
}

const CompanyWorkbookContent = ({ frontendStatus }) => {
  const { hasReconciliationAccess } = useClientContext()

  const [hiddenColumns, setHiddenColumns] = useQueryParam(
    'hiddenColumns',
    withDefault(DelimitedArrayParam, [viewerTypeColNameMap[projectManager]])
  )

  const [viewMode, setViewMode] = useQueryParam(
    'viewMode',
    withDefault(StringParam, 'view')
  )

  const [viewerTypes, setViewerTypes] = useQueryParam(
    'viewerTypes',
    withDefault(DelimitedArrayParam, getDefaultViewerType(frontendStatus))
  )

  const [completionStatusTypes, setCompletionStatusTypes] = useQueryParam(
    'completionStatus',
    withDefault(DelimitedArrayParam, [])
  )

  const [presetView, setPresetView] = useQueryParam(
    'presetView',
    withDefault(StringParam, presetViewNames.DEFAULT)
  )

  const [presetViewList, setPresetViewList] = useState([
    presetViewNames.DEFAULT,
  ])
  const [myTeamOnly, setMyTeamOnly] = useState(false)

  const handlePresetViewSelect = useCallback(
    (viewName) => {
      setPresetView(viewName)
    },
    [setPresetView]
  )

  const handleColumnVisibilityChange = useCallback(
    (event = {}) => {
      const { field, isVisible } = event
      if (isVisible) {
        setHiddenColumns((hiddenColumns || []).filter((col) => col !== field))
      } else {
        setHiddenColumns((hiddenColumns || []).concat(field))
      }
    },
    [hiddenColumns, setHiddenColumns]
  )

  const handleToggleViewerType = useCallback(
    (type) => {
      const colName = viewerTypeColNameMap[type]
      if (viewerTypes.includes(type)) {
        setViewerTypes(viewerTypes.filter((t) => t !== type))
        setHiddenColumns((hiddenColumns || []).filter((col) => col !== colName))
      } else {
        setViewerTypes(viewerTypes.concat(type))
        setHiddenColumns((hiddenColumns || []).concat(colName))
      }
    },
    [viewerTypes, setViewerTypes, hiddenColumns, setHiddenColumns]
  )

  const handleToggleCompletionStatusType = useCallback(
    (type) => {
      if (completionStatusTypes.includes(type)) {
        setCompletionStatusTypes(
          completionStatusTypes.filter((t) => t !== type)
        )
      } else {
        setCompletionStatusTypes(completionStatusTypes.concat(type))
      }
    },
    [completionStatusTypes, setCompletionStatusTypes]
  )

  const toggleTeamSwitch = () => {
    if (!myTeamOnly) {
      setViewerTypes([])
      setHiddenColumns((f) =>
        f.filter((col) => !Object.values(viewerTypeColNameMap).includes(col))
      )
    }
    setMyTeamOnly((f) => !f)
  }

  const enableEditWriteOff = useMemo(
    () =>
      isReconciliationEditingAvailable(frontendStatus, hasReconciliationAccess),
    [frontendStatus, hasReconciliationAccess]
  )

  const editButtonEnabled = useMemo(
    () => isEditEnabled(frontendStatus, hasReconciliationAccess, viewerTypes),
    [frontendStatus, hasReconciliationAccess, viewerTypes]
  )

  // force out of edit mode if edit has been disabled
  useEffect(() => {
    if (!editButtonEnabled && viewMode === 'edit') {
      setViewMode('view')
    }
  }, [editButtonEnabled, viewMode, setViewMode])

  useEffect(() => {
    const { DEFAULT, SETUP_PROBLEMS, UNFINISHED } = presetViewNames
    if (frontendStatus === workbookFrontendStatus.preparation) {
      // if status is review, clear 'UNFINISHED' if selected and remove from list
      if (presetView === UNFINISHED) {
        setPresetView(DEFAULT)
      }
      setPresetViewList(
        Object.keys(presetViewNames).filter((view) => view !== UNFINISHED)
      )
    } else if (frontendStatus === workbookFrontendStatus.invoicing || frontendStatus === workbookFrontendStatus.reviewing) {
      // if status is invoicing or reviewing, clear 'SETUP_PROBLEMS' if selected and remove from list
      if (presetView === SETUP_PROBLEMS) {
        setPresetView(DEFAULT)
      }
      setPresetViewList(
        Object.keys(presetViewNames).filter((view) => view !== SETUP_PROBLEMS)
      )
    } else {
      // if status is neither review nor invoicing,
      // clear 'SETUP_PROBLEMS' and 'UNFINISHED' if either are selected
      // and removed them from the list
      if ([SETUP_PROBLEMS, UNFINISHED].includes(presetView)) {
        setPresetView(DEFAULT)
      }
      setPresetViewList(
        Object.keys(presetViewNames).filter(
          (view) => ![SETUP_PROBLEMS, UNFINISHED].includes(view)
        )
      )
    }
  }, [presetView, setPresetView, frontendStatus])

  return (
    <>
      <CompanyWorkbookViewModeSelector
        editButtonEnabled={editButtonEnabled}
        onViewModeChange={setViewMode}
        viewMode={viewMode}
      />
      <CompanyWorkbookViewerTypeSelector
        onToggleViewerType={handleToggleViewerType}
        viewerTypes={viewerTypes}
      />
      <CompanyWorkbookCompletionStatusSelector
        onToggleStatusType={handleToggleCompletionStatusType}
        statusTypes={completionStatusTypes}
      />
      <CompanyWorkbookMyTeamSwitch
        onClick={toggleTeamSwitch}
        selected={myTeamOnly}
      />
      <CompanyWorkbookPresetViewSelector
        handlePresetViewSelect={handlePresetViewSelect}
        presetView={presetView}
        presetViewList={presetViewList}
      />
      <CompanyWorkbook
        enableEditWriteOff={enableEditWriteOff}
        hiddenColumns={hiddenColumns}
        handleColumnVisibilityChange={handleColumnVisibilityChange}
        viewMode={viewMode}
        viewerTypes={viewerTypes}
        completionStatusTypes={completionStatusTypes}
        presetView={presetView}
        myTeamOnly={myTeamOnly}
      />
    </>
  )
}

const CompanyWorkbookContainer = ({ periodId }) => {
  const { getAccessTokenSilently, user } = useAuth0()
  const { invoicingApproachEnabled } = useClientContext()
  const dispatch = useDispatch()

  const data = useSelector(selectCompanyWorkbookData)
  const loadingState = useSelector(selectLoadingState)
  const currentWorkbookStep = useSelector(selectActiveWorkbookStep)
  const loadingStateInvoicingApproaches = useSelector(
    fetchLoadingInvoicingApproaches
  )

  const fetchWorkbook = useCallback(() => {
    dispatch(
      fetchCompanyWorkbook({
        periodId,
        getTokenCallback: getAccessTokenSilently,
      })
    )
  }, [dispatch, periodId, getAccessTokenSilently])

  useEffect(() => {
    if (loadingState === LoadingState.idle) {
      fetchWorkbook()
    }
  }, [fetchWorkbook, loadingState])

  /**
   * Dispatches the fetchInvoicingApproaches thunk if the invoicingApproaches feature flag is enabled.
   * fetchInvoicingApproaches: Fetches the invoicingApproaches lookup table
   */
  const fetchInvApproaches = useCallback(() => {
    if (invoicingApproachEnabled) {
      dispatch(
        fetchInvoicingApproaches({ getTokenCallback: getAccessTokenSilently })
      )
    }
  }, [dispatch, getAccessTokenSilently, invoicingApproachEnabled])

  useEffect(() => {
    if (loadingStateInvoicingApproaches === LoadingState.idle) {
      fetchInvApproaches()
    }
  }, [fetchInvApproaches, loadingStateInvoicingApproaches])

  if (
    loadingState === LoadingState.idle ||
    loadingState === LoadingState.pending
  ) {
    return <Loading />
  } else if (loadingState === LoadingState.rejected) {
    return <Error error={data} retryCallback={fetchWorkbook} />
  } else {
    return (
      <CompanyWorkbookContent
        frontendStatus={currentWorkbookStep.frontendStatus}
        user={user}
      />
    )
  }
}

export default CompanyWorkbookContainer
