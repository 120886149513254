import { CellType, ChargeabilityCell } from '../views/ChargeabilityCell'

export const ChargeabilityRowDataCells = ({
  rowDataValues,
  resourceInfo,
  hideColours,
  currentTimeSpan,
  enableTooltips,
}) => {
  return resourceInfo.map((resource) => {
    const cellValue = rowDataValues[resource.index]
    return (
      <ChargeabilityCell
        key={resource.index}
        type={CellType.percent}
        isFirstInGroup={resource.isFirstInGroup}
        isFirstInTeam={resource.isFirstInTeam}
        heatmapPercentage={hideColours ? null : cellValue}
        currentTimeSpan={currentTimeSpan}
        tooltip={enableTooltips && currentTimeSpan ? 'warning - data is likely incomplete' : null}
      >
        {cellValue == null || isNaN(cellValue) || cellValue < 0
          ? null
          : cellValue.toLocaleString('en-NZ', {
              style: 'percent',
            })}
      </ChargeabilityCell>
    )
  })
}
