import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Dialog, DialogActions, DialogContent } from '@mui/material'

import Loading from '../primitives/Loading'

import { reset as resetTimeCostReport } from '../slices/timeCostReport'
import {
  selectMappingData,
  selectLoadingState,
  updateWorkItemMapping,
  selectUpdateState,
  selectUpdateError,
  resetSaveError,
  reset,
} from '../slices/workItemMapping'

import LoadingState from '../utils/LoadingState'
import {
  anyEdits,
  findErrors,
  isMappingValid,
  transformToLocalMapping,
  transformToUpdateMappingPayload,
} from '../utils/mappingUtils'

import AddLineItemButton from '../views/AddLineItemButton'
import DialogTitleWithClose from '../views/DialogTitleWithClose'
import SaveEditsControl from '../views/SaveEditsControl'
import WorkItemMapper from '../views/WorkItemMapper'

const ProjectWorkItemMappingDialog = ({ projectId, onClose, open }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const data = useSelector(selectMappingData)
  const loadingState = useSelector(selectLoadingState)
  const updateState = useSelector(selectUpdateState)
  const updateError = useSelector(selectUpdateError)

  const [anyErrors, setAnyErrors] = useState(false)
  const [mappingData, setMappingData] = useState(null)

  const setMapping = useCallback(
    (newMappingData) => {
      setAnyErrors(findErrors(newMappingData))
      setMappingData(newMappingData)
    },
    [setAnyErrors, setMappingData]
  )

  const handleAdd = useCallback(
    (newItemId) => {
      setMapping({
        ...mappingData,
        lineItems: {
          ...mappingData.lineItems,
          [newItemId]: {
            id: newItemId.toString(),
            name: '',
            jiraKeys: [],
            billable: true,
            deletable: true,
            isEditing: true,
            isExpanded: true,
            new: true,
          },
        },
        lineItemIds: [...mappingData.lineItemIds, newItemId.toString()],
      })
    },
    [mappingData, setMapping]
  )

  const handleSave = useCallback(() => {
    const payload = transformToUpdateMappingPayload(mappingData)
    dispatch(
      updateWorkItemMapping({
        payload,
        projectId,
        getTokenCallback,
      })
    )
    dispatch(resetTimeCostReport())
  }, [dispatch, getTokenCallback, mappingData, projectId])

  const handleReset = useCallback(() => {
    setMapping(transformToLocalMapping(data))
    dispatch(resetSaveError())
  }, [data, dispatch, setMapping])

  useEffect(() => {
    if (loadingState === LoadingState.fulfilled && data !== null) {
      handleReset()
    }
  }, [data, loadingState, handleReset])

  useEffect(() => {
    if (updateState === LoadingState.fulfilled) {
      dispatch(reset())
    }
  }, [updateState, dispatch])

  const isEdited = useMemo(() => {
    if (
      loadingState === LoadingState.fulfilled &&
      data !== null &&
      mappingData !== null
    ) {
      return anyEdits(mappingData, data)
    }

    return false
  }, [loadingState, data, mappingData])

  const isSaving = useMemo(
    () => updateState === LoadingState.pending,
    [updateState]
  )

  if (projectId === null) {
    return null
  } else {
    return (
      <>
        <Prompt
          when={isEdited}
          message="You have unsaved changes. Are you sure you want to leave?"
        />
        <Dialog onClose={onClose} open={open} maxWidth="xl">
          <DialogTitleWithClose onClose={onClose} title="Contract Line Items" />
          <DialogContent>
            {loadingState === LoadingState.fulfilled &&
              mappingData !== null &&
              isMappingValid(mappingData) ? (
              <WorkItemMapper
                disabled={false}
                mappingData={mappingData}
                setMappingData={setMapping}
                onUpdate={() => { }}
              />
            ) : (
              <Loading />
            )}
          </DialogContent>
          <DialogActions>
            <Box sx={{ clear: 'both', mb: 1, mx: 1, width: '100%' }}>
              {loadingState !== LoadingState.pending ? (
                <AddLineItemButton
                  disabled={isSaving || loadingState !== LoadingState.fulfilled}
                  onAdd={handleAdd}
                  mappingData={mappingData}
                />
              ) : null}
              <SaveEditsControl
                isEdited={isEdited}
                isValid={!anyErrors}
                loadingState={loadingState}
                onReset={handleReset}
                onSave={handleSave}
                updateError={updateError}
                updateState={updateState}
              />
            </Box>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default ProjectWorkItemMappingDialog
