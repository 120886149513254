import { useSelector } from 'react-redux'

import { Box, CircularProgress, Step, StepLabel, Stepper } from '@mui/material'

import { selectIsWorkbookReady } from '../slices/companyWorkbook'

import colours from '../styles/colours'
import {
  selectActiveWorkbookStep,
  selectCurrentlyAvailableWorkbookSteps,
} from '../slices/compoundSelectors/activeStepSelector'
import { workbookFrontendStatus } from '../utils/steps'

const CompanyWorkbookStepper = () => {
  const isReady = useSelector(selectIsWorkbookReady)
  const workbookSteps = useSelector(selectCurrentlyAvailableWorkbookSteps)
  const activeWorkbookStep = useSelector(selectActiveWorkbookStep)

  // Don't render if not ready or active workbook step isn't yet available
  if (!isReady || !activeWorkbookStep) {
    return null
  }

  return (
    <Box
      sx={{
        backgroundColor: colours.white,
        mt: 1,
        width: '100%',
      }}
    >
      <Stepper
        activeStep={mapStepIndex(
          activeWorkbookStep.index,
          workbookSteps.length
        )}
        sx={{ p: 3 }}
      >
        {workbookSteps.map((workbookStep, index) => (
          <Step key={index} data-testid={workbookStep.testId}>
            {generateStepLabel({
              isActiveStep: index === activeWorkbookStep.index,
              isClosedStep:
                workbookStep.frontendStatus === workbookFrontendStatus.closed,
              isBuildingStep:
                workbookStep.frontendStatus === workbookFrontendStatus.building,
              label: workbookStep.label,
              index: index,
            })}
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

/**
 * Due to the way the MUI stepper works and how we want the functionality to work for us, for the final step of the
 * stepper to show a tick, the stepper index must be equal to the total number of steps, not the total number of steps -1.
 * This accounts for that conversion.
 * @param {*} index
 * @param {*} totalSteps
 * @returns
 */
function mapStepIndex(index, totalSteps) {
  if (index === totalSteps - 1) {
    index = totalSteps
  }
  return index
}

/**
 * Generate the step label including all relevant styling
 * @param {*} isActiveStep true if this is the active step that the workbook is in
 * @param {*} isClosedStep true if this is the final step / closed step
 * @param {*} isCompletedStep true if this step has been completed
 * @param {*} label label/name of this step (used to display label)
 * @returns
 */
function generateStepLabel({
  isActiveStep,
  isClosedStep,
  isBuildingStep,
  label,
  index,
}) {
  const stepIconComponent =
    isActiveStep && isBuildingStep ? CircularProgress : undefined

  const stepIconProps =
    isActiveStep && isClosedStep
      ? { sx: { '&.Mui-completed': { color: 'secondary.main' } } }
      : // Otherwise, set the colour
      isActiveStep && isBuildingStep
      ? { active: undefined, completed: undefined, error: undefined }
      : { sx: { '&.Mui-active': { color: 'secondary.main' } } }

  return (
    <StepLabel
      StepIconComponent={stepIconComponent}
      StepIconProps={stepIconProps}
    >
      {label}
    </StepLabel>
  )
}

export default CompanyWorkbookStepper
