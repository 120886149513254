import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParam, NumberParam } from 'use-query-params'
import { Box } from '@mui/material'

import ProjectSelector from '../containers/ProjectSelector'
import Page from '../primitives/Page'
import PageTitle from '../primitives/PageTitle'

import {
  selectLoadingState as selectProjectsLoadingState,
  selectProjectsMap,
  reset as resetProjects,
} from '../slices/projects'
import resetProjectState from '../slices/resetProjectState'

import LoadingState from '../utils/LoadingState'
import ProjectDetails from '../views/ProjectDetails'
import SelectOrCreateProject from '../views/SelectOrCreateProject'


// validate data and render content
const ProjectDetailsPageContent = ({ project }) => project
  ? <ProjectDetails project={project} />
  : <SelectOrCreateProject />

// retrieve data (projects) from server and provide project selector
const ProjectDetailsPageContentContainer = () => {
  const dispatch = useDispatch()

  const [projectId, setProjectId] = useQueryParam('projectId', NumberParam)

  const projectsLoadingState = useSelector(selectProjectsLoadingState)
  const projectsMap = useSelector(selectProjectsMap)

  const handleProjectChange = useCallback(() => {
    dispatch(resetProjects())
    dispatch(resetProjectState())
  }, [dispatch])

  useEffect(() => {
    if (
      projectsLoadingState === LoadingState.fulfilled &&
      projectsMap &&
      projectId
    ) {
      if (projectsMap[projectId] === undefined) {
        setProjectId(undefined)
      } else {
        document.title = `Details - ${projectsMap[projectId].projectName}`
      }
    } else {
      document.title = 'Project Details'
    }
  }, [projectsLoadingState, projectsMap, projectId, setProjectId])

  useEffect(() => {
    if (!projectId) {
      dispatch(resetProjectState())
    }
  }, [dispatch, projectId])

  return (
    <>
      <ProjectSelector
        onChange={handleProjectChange}
        style={{ float: 'left' }}
      />
      {projectsLoadingState === LoadingState.fulfilled ? (
        <Box sx={{ clear: 'both', display: 'block', pt: 1 }}>
          <ProjectDetailsPageContent
            project={projectsMap ? projectsMap[projectId] : null}
          />
        </Box>
      ) : null}
    </>
  )
}

// wrap content in page frame
const ProjectDetailsPage = () => (
  <Page>
    <PageTitle>Project Details</PageTitle>
    <ProjectDetailsPageContentContainer />
  </Page>
)

export default ProjectDetailsPage
