import {
  FormControl,
  TextField
} from '@mui/material'

import config from '../config'
import ButtonLink from '../primitives/ButtonLink'

const ProjectBasicInfo = ({ project }) => {

  const jiraLink = `${config.jira.baseUrl}/browse/${project.jiraKey}`

  return (
    <FormControl sx={{ ml: 2, my: 1 }}>
      <TextField
        fullWidth
        id="client"
        label="Client"
        sx={{ display: 'block', mt: 1, width: 300 }}
        value={project.clientName}
        variant="standard"
      />
      <ButtonLink
        label="Jira Epic"
        to={jiraLink}
        sx={{ mr: 1, mt: 2, width: 155 }}
      />
    </FormControl>
  )
}

export default ProjectBasicInfo
