import { useRef, useState } from 'react'
import { List, ListItem, Paper, Popper } from '@mui/material'

const NavDropdown = ({ id, iconAlt, iconSrc, children }) => {
  const popperRef = useRef()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMouseLeave = (e) => {
    if (e.currentTarget.localName === 'li') {
      const menuBoundary = popperRef.current.getBoundingClientRect()
      if (
        e.clientX >= menuBoundary.left - 1 &&
        e.clientX <= menuBoundary.right &&
        e.clientY >= menuBoundary.top &&
        e.clientY <= menuBoundary.bottom
      ) {
        return
      }
    }
    setAnchorEl(null)
  }

  return (
    <>
      <ListItem
        aria-label="menu"
        aria-controls={id}
        aria-haspopup="true"
        onMouseEnter={openMenu}
        onMouseLeave={handleMouseLeave}
        sx={{
          height: 60,
          px: 1.875,
          py: 1,
          width: '100%',
          '&:hover': {
            backgroundColor: 'secondary.main',
          },
        }}
      >
        <img
          src={iconSrc}
          alt={iconAlt}
          style={{
            backgroundColor: 'inherit',
            margin: '15px 0',
            width: '100%',
          }}
        />
      </ListItem>
      <Popper
        id={id}
        ref={popperRef}
        anchorEl={anchorEl}
        placement="right-start"
        open={open}
        onMouseLeave={handleMouseLeave}
        style={{ zIndex: 999 }}
      >
        <Paper elevation={1}>
          <List>{children}</List>
        </Paper>
      </Popper>
    </>
  )
}

export default NavDropdown
