import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import Note from '../primitives/Note'
import {
  selectIsWorkbookFinalised,
  selectLatestWorkbook,
  selectValidWorkbook,
  selectWorkbookStatus,
} from '../slices/companyWorkbook.js'

import { diff, timestamp } from '../utils/format'
import workbookStatus from '../utils/workbookStatus'

const CompanyWorkbookHeader = ({ float, isOps }) => {

  const isWorkbookFinalised = useSelector(selectIsWorkbookFinalised)
  const latestWorkbook = useSelector(selectLatestWorkbook)
  const status = useSelector(selectWorkbookStatus)
  const validWorkbook = useSelector(selectValidWorkbook)

  var message = ''
  if (!validWorkbook) {
    message = <span>There is no workbook for this period yet.</span>
  } else if (!isOps && status === workbookStatus.review) {
    message = <span>The workbook for this period is not open yet.</span>
  } else {
    message = (
      <span>
        {isWorkbookFinalised
          ? `Closed ${timestamp(validWorkbook.finalised)}.`
          : `Generated ${timestamp(validWorkbook.createdStart)}.`}
      </span>
    )
  }

  var opsMessage = ''
  if (isOps) {
    if (validWorkbook && validWorkbook.id === latestWorkbook.id) {
      if (!isWorkbookFinalised) {
        opsMessage = (
          <Box component='span' sx={{ color: 'success.main' }}>
            {`Generation took ${diff(validWorkbook.createdStart, validWorkbook.createdFinish)}.`}
          </Box>
        )
      }
    } else if (latestWorkbook) {
      if (latestWorkbook.status === workbookStatus.failed) {
        opsMessage = (
          <Box component='span' sx={{ color: 'error.main' }}>
            {`Regeneration failed ${timestamp(latestWorkbook.createdFinish)}. ${latestWorkbook.comment}`}
          </Box>
        )
      } else if (latestWorkbook.status === workbookStatus.cancelled) {
        opsMessage = (
          <Box component='span' sx={{ color: 'error.main' }}>
            {`Regeneration cancelled ${timestamp(latestWorkbook.createdFinish)}.`}
          </Box>
        )
      } else if (latestWorkbook.status === workbookStatus.building) {
        opsMessage = (
          <Box component='span' sx={{ color: 'warning.main' }}> Regeneration in progress...</Box>
        )
      }
    }
  }

  return (
    <Note
      style={float && { float: 'right' }}
      sx={float ? { mr: 0.5, mt: 0.5 } : { mb: 1 }}
    >
      {message}
      {opsMessage}
    </Note >
  )
}

export default CompanyWorkbookHeader
