import { Box, Switch, Typography } from '@mui/material'

const ToggleSwitch = ({
  checked,
  onChange,
  onText,
  offText,
  sx,
  testId,
}) => {

  return (
    <Box sx={{...sx, display: 'flex', flexDirection: 'row', alignItems: 'baseline', width: 'fit-contents'}}>
      <Typography
        color={checked ? 'text.light' : 'text.dark'}
        fontSize={13}
      >
        {offText}
      </Typography>
      <Switch
        checked={checked}
        onChange={onChange}
        size="small"
        data-testid={testId}
      />
      <Typography
        color={checked ? 'text.dark' : 'text.light'}
        fontSize={13}
      >
        {onText}
      </Typography>
    </Box>
  )
}

export default ToggleSwitch
