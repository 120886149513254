/**
 * This handles fetching all access rights from the backend.
 * Access rights define what resources the current user is allowed to use.
 * They can be a made up of any combination of underlying access control mechanisms such as
 * feature flags, JWT permissions, team position entitlements (e.g. team or group leaders), etc.
 * These business logic based combinations will be built up from these control mechanisms as required
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../utils/api'
import LoadingState from '../utils/LoadingState'
import { accessRightCodeMap } from '../views/constants/AccessRightCodes'

const initialState = {
  loadingState: LoadingState.idle,
  data: null,
}

export const fetchAccessRights = createAsyncThunk(
  'fetchAccessRights',
  async ({ getTokenCallback }, { getState }) => {
    const url = api.endpoints(getState().client.name).accessRight.all()
    return await api.getData(url, getTokenCallback)
  }
)

const slice = createSlice({
  name: 'accessRights',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchAccessRights.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchAccessRights.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error
    },
    [fetchAccessRights.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled

      // Handle the mapping of the agreed access right exchange interface with the backend to
      // a usable access right object in the frontend
      const convertedAccessRights = convertReceivedAccessRightsToObject(
        action.payload
      )
      state.data = convertedAccessRights
    },
  },
})

/**
 * Convert the basic access right object the backend provides to a more usable frontend object
 * that includes the access right name
 * @param {*} deserializedAccessRightObject access right object deserialized from backend
 * @returns object mapping access right names to the code and accessibility
 */
function convertReceivedAccessRightsToObject(deserializedAccessRightObject) {
  const remappedObject = {}
  Object.keys(deserializedAccessRightObject).forEach((accessRightCode) => {
    const accessRightName = accessRightCodeMap.get(accessRightCode)

    // No access right was found for the given access right code
    if (accessRightName == null) {
      return
    }

    const accessRightObj = {
      code: accessRightCode,
      hasAccess: deserializedAccessRightObject[accessRightCode],
    }

    remappedObject[accessRightName] = accessRightObj
  })
  return remappedObject
}

export const selectAccessRightsLoadingState = (state) =>
  state.accessRights.loadingState
export const selectAccessRights = (state) => state.accessRights.data
// Specific access right selectors
export const selectPdReviewAccessRight = (state) =>
  state.accessRights.data?.companyWorkbookPdReviewStage?.hasAccess
export default slice.reducer
