import { Box, Link } from '@mui/material'
import { formatMoney } from '../utils/invoiceUtils'
import { TitledBox, HeaderBox, DisplayBox } from '../primitives/TitledBox'

const UNPOPULATED_DISPLAY_VALUE = '-'

const xeroStatusMap = new Map([
  [0, 'Authorised'],
  [1, 'Deleted'],
  [2, 'Draft'],
  [3, 'Paid'],
  [4, 'Submitted'],
  [5, 'Voided'],
])

function HeaderAndDisplayCell({ headerContent, displayContent }) {
  return (
    <Box display="flex" flex={1}>
      <HeaderBox flex={1} sx={{ alignContent: 'center' }}>
        {headerContent}
      </HeaderBox>
      <DisplayBox flex={1} sx={{ alignContent: 'center' }}>
        {displayContent}
      </DisplayBox>
    </Box>
  )
}

function formatMoneyOrGetDefault(value) {
  if (isNaN(Number(value))) {
    return UNPOPULATED_DISPLAY_VALUE
  }
  return formatMoney(value)
}

export function SubmittedInvoiceDetailsTable({
  invoiceNumber,
  invoiceSent,
  invoiceStatusNumber,
  xeroLink,
  amountOwing,
  netTotal,
  grossTotal,
  purchaseOrder,
  maxWidth,
}) {
  amountOwing = formatMoneyOrGetDefault(amountOwing)
  netTotal = formatMoneyOrGetDefault(netTotal)
  grossTotal = formatMoneyOrGetDefault(grossTotal)
  purchaseOrder = purchaseOrder || UNPOPULATED_DISPLAY_VALUE
  if (xeroLink) {
    invoiceNumber = <Link href={xeroLink} target="_blank" rel="noopener noreferrer">{invoiceNumber}</Link>
  }
  else {
    invoiceNumber = invoiceNumber || UNPOPULATED_DISPLAY_VALUE
  }

  let invoiceStatusString = UNPOPULATED_DISPLAY_VALUE
  if (invoiceStatusNumber !== undefined && invoiceStatusNumber !== null)
    // || to account for an invalid invoiceStatusNumber being provided
    invoiceStatusString = xeroStatusMap.get(invoiceStatusNumber) || invoiceStatusString

  let invoiceSentString = ` (${UNPOPULATED_DISPLAY_VALUE})`
  if (invoiceSent !== undefined && invoiceSent !== null) {
    invoiceSentString = invoiceSent ? ' (Sent)' : ' (Not Sent)'
  }
  invoiceStatusString += `${invoiceSentString}`

  const title = `Invoice Details`

  return (
    <TitledBox title={title} containerSx={{ maxWidth, width: '100%', flex: 10 }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          height: '100%',
        }}
      >
        <Box
          flex={1}
          display="flex"
          sx={{ fontSize: 14, alignItems: 'center', height: '100%', p: 0.5, flexWrap: 'wrap' }}
        >
          <Box flex={1} display="flex" flexDirection="row" flexWrap="wrap">
            <HeaderAndDisplayCell headerContent="Purchase Order" displayContent={purchaseOrder} />
            <HeaderAndDisplayCell headerContent="Invoice Number" displayContent={invoiceNumber} />
          </Box>
          <Box flex={1} display="flex" flexDirection="row" flexWrap="wrap">
            <HeaderAndDisplayCell headerContent="Status" displayContent={invoiceStatusString} />
            <HeaderAndDisplayCell headerContent="Subtotal (ex. GST)" displayContent={netTotal} />
          </Box>
          <Box flex={1} display="flex" flexDirection="row" flexWrap="wrap">
            <HeaderAndDisplayCell headerContent="Total (incl. GST)" displayContent={grossTotal} />
            <HeaderAndDisplayCell headerContent="Amount Due" displayContent={amountOwing} />
          </Box>
        </Box>
      </Box>
    </TitledBox>
  )
}
