import { useCallback, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParam, NumberParam, withDefault } from 'use-query-params'

import Error from '../primitives/Error'
import Loading from '../primitives/Loading'
import {
  fetchProjects,
  selectProjectsError,
  selectLoadingState as selectProjectsLoadingState,
  selectProjectsMap,
} from '../slices/projects'
import LoadingState from '../utils/LoadingState'
import ProjectSelector from '../views/ProjectSelector'

const ProjectSelectorContainer = ({ onChange, hideSpinner, ...props }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const [projectId, setProjectId] = useQueryParam(
    'projectId',
    withDefault(NumberParam, 0)
  )

  const projectsError = useSelector(selectProjectsError)
  const projectsLoadingState = useSelector(selectProjectsLoadingState)
  const projectsMap = useSelector(selectProjectsMap)

  const handleProjectUpdate = useCallback(
    (_event, newValue) => {
      setProjectId(newValue.projectId)
      onChange()
    },
    [onChange, setProjectId]
  )

  const fetchProjectsCallback = useCallback(() => {
    dispatch(fetchProjects({ getTokenCallback }))
  }, [dispatch, getTokenCallback])

  useEffect(() => {
    if (projectsLoadingState === LoadingState.idle) {
      fetchProjectsCallback()
    }
  }, [projectsLoadingState, fetchProjectsCallback])

  if (projectsLoadingState === LoadingState.pending) {
    return hideSpinner ? null : <Loading />
  } else if (projectsLoadingState === LoadingState.rejected) {
    return <Error error={projectsError} retryCallback={fetchProjectsCallback} />
  } else if (projectsLoadingState === LoadingState.fulfilled) {
    return (
      <ProjectSelector
        handleProjectSelect={handleProjectUpdate}
        projectsByIdMap={projectsMap}
        projectId={projectId}
        {...props}
      />
    )
  } else {
    return null
  }
}

export default ProjectSelectorContainer
