import { Button, ButtonGroup } from '@mui/material'

const SelectorButtons = ({ buttons, sx }) => (
  <ButtonGroup
    color="primary"
    size="small"
    style={{ height: 24 }}
    sx={sx || { float: 'left', mb: 1, ml: 2, mt: 2.5 }}
    variant="contained"
  >
    {
      buttons.map(({ children, selected, ...buttonProps }, i) => (
        <Button
          key={i}
          color={selected ? 'secondary' : 'primary'}
          style={{ borderRadius: 1, minWidth: 80 }}
          {...buttonProps}
        >
          {children}
        </Button>
      ))
    }
  </ButtonGroup>
)

export default SelectorButtons
