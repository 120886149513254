import { Link } from 'react-router-dom'
import { ListItem, ListItemText } from '@mui/material'

const NavDropdownItem = ({ route, text, subheader }) => (
  <ListItem
    component={Link}
    sx={{ '&:hover': { backgroundColor: 'editable.light' } }}
    to={route}
  >
    <ListItemText primary={text} secondary={subheader} />
  </ListItem>
)

export default NavDropdownItem
