import MuiAppBar from './MuiAppBar'
import MuiTab from './MuiTab'
import MuiDataGrid from './MuiDataGrid'
import MuiInput from './MuiInput'
import MuiInputBase from './MuiInputBase'
import MuiListItem from './MuiListItem'
import MuiListItemText from './MuiListItem'
import MuiMenuItem from './MuiMenuItem'
import MuiPopper from './MuiPopper'
import MuiButton from './MuiButton'

const components = {
  MuiAppBar,
  MuiDataGrid,
  MuiInput,
  MuiInputBase,
  MuiListItem,
  MuiListItemText,
  MuiMenuItem,
  MuiPopper,
  MuiTab,
  MuiButton
}

export default components
