const MuiButton = {
    styleOverrides: {
      root: {
        borderRadius: 0,
        }
    },
  }

  export default MuiButton
  
  