import { createSlice } from '@reduxjs/toolkit'
import { getWorkbookSteps } from '../utils/steps'

// Reviewing step is disabled by default - will only be enabled if the associated access right is enabled
// (as reflected in the associated store)
const initialWorkbookSteps = getWorkbookSteps({ includeReviewingStep: false })

const slice = createSlice({
  name: 'workbooksteps',
  initialState: { data: initialWorkbookSteps },
  reducers: {
    set(state, action) {
      return {
        // Includes all workbook steps, including those not enabled
        all: getWorkbookSteps({ includeReviewingStep: true }),

        // Includes only workbook steps that are always present or optional but enabled
        enabled: getWorkbookSteps({
          includeReviewingStep: action.payload.includeReviewingStep,
        }),
      }
    },
  },
})

export const { set } = slice.actions

// Selects workbook steps that are always present or optional but enabled
export const selectEnabledWorkbookSteps = (state) => state.workbookSteps.enabled

// Gets all workbook steps
export const selectAllWorkbookSteps = (state) => state.workbookSteps.all

export default slice.reducer
