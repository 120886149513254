import { TableRow } from '@mui/material'

const SpacerRow = ({ big, small }) => (
  <TableRow
    style={small ? { height: 1 } : big ? { height: 2 } : null}
    sx={{ backgroundColor: 'primary.contrastText' }}
  />
)

export default SpacerRow
