import { INVOICE_DRAFT_ID } from "../views/constants/InvoiceConstants"

export function formatMoney(value) {
  if (value === undefined || value === null || value === '') {
    return ''
  } else {
    return Number(value).toLocaleString('en-NZ', {
      style: 'currency',
      currency: 'NZD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
}

export const InvoiceStatus = {
  draft: 0,
  final: 1,
}

export function sum(invoiceLines) {
  var sum = {
    totalWip: 0,
    amount: 0,
  }
  for (var line of invoiceLines) {
    sum.totalWip += safeConvertToNumber(line.totalWip)
    sum.amount += safeConvertToNumber(line.amount)
  }
  return sum
}

/**
 * Converts a given value to a number and always returns a number
 * @param {*} valueToConvert type to attempt to convert to a number
 * @returns The converted number on successful conversion, or 0 on unsuccessful conversion
 */
function safeConvertToNumber(valueToConvert) {
  const convertedValue = Number(valueToConvert)
  if (isNaN(convertedValue)) return 0
  return convertedValue
}

function validateAmount(amount) {
  return !isNaN(Number(amount))
}

export function validateForSave(invoice) {
  if (!validateInvoiceLines(invoice?.invoiceLines)) {
    return false
  }

  return true
}

export function validateForSubmit(invoice) {
  if (!invoice?.clientComment && !invoice?.opsComment) {
    return generateValidateForSubmitResult(
      false,
      "To submit this invoice, you must include comments in both the 'PROJECT STATUS COMMENTS FOR INVOICE' and 'INSTRUCTIONS FOR OPERATIONS' fields."
    )
  }

  if (!invoice?.clientComment) {
    return generateValidateForSubmitResult(
      false,
      "To submit this invoice, you must include a comment in the 'PROJECT STATUS COMMENTS FOR INVOICE'."
    )
  }

  if (!invoice?.opsComment) {
    return generateValidateForSubmitResult(
      false,
      "To submit this invoice, you must include a comment in the 'INSTRUCTIONS FOR OPERATIONS'."
    )
  }

  if (invoice?.id === INVOICE_DRAFT_ID) {
    // Invoice needs to be saved at least once before submitting is enabled.
    // This is because the backend doesn't support submitting an invoice without an ID.
    return generateValidateForSubmitResult(
      false,
      "To submit this invoice, you must save it first."
    )
  }

  if (!validateInvoiceLines(invoice.invoiceLines)) {
    return generateValidateForSubmitResult(false, 'Please ensure all amounts are valid numbers.')
  }

  return generateValidateForSubmitResult(true)
}

function generateValidateForSubmitResult(isValidForSubmit, submitValidationMessage) {
  return { isValidForSubmit, submitValidationMessage }
}

function validateInvoiceLines(invoiceLines) {
  return invoiceLines?.every((line) => validateAmount(line.amount)) ?? false
}
