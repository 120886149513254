import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'

import resetProjectState from './resetProjectState'
import api from '../utils/api'
import LoadingState from '../utils/LoadingState'
import { INVOICE_DRAFT_ID } from '../views/constants/InvoiceConstants'
import { deepEqual } from '../utils/deepEqual'
import { InvoiceStatus } from '../utils/invoiceUtils'

function prepareTemplate(templateInvoice) {
  const updatedTemplate = { ...templateInvoice }
  // Ensure that the amount field is empty for each line item and can be converted to a number
  updatedTemplate.invoiceLines = templateInvoice?.invoiceLines?.map((line) => {
    return { ...line, amount: 0 }
  })
  return updatedTemplate
}

const initialState = {
  data: {
    projectId: null,
    periodId: null,
    invoices: {},
    template: null,
    activeInvoiceId: null,
    invoicingAllowed: false,
  },
  loadingState: LoadingState.idle,
  loadingError: null,
  updateState: LoadingState.idle,
  updateError: null,
  submitState: LoadingState.idle,
  submitError: null,
}

export const fetchInvoiceDetails = createAsyncThunk(
  'fetchInvoiceDetails',
  async ({ projectId, periodId, getTokenCallback }, { getState }) => {
    return await api.getData(
      api.endpoints(getState().client.name).projects.v1.invoiceDetails(projectId, periodId),
      getTokenCallback
    )
  }
)

export const saveInvoice = createAsyncThunk(
  'saveInvoice',
  async ({ invoice, getTokenCallback }, { getState }) => {
    return await saveInvoiceCallLogic(invoice, getTokenCallback, getState)
  }
)

export const submitInvoice = createAsyncThunk(
  'submitInvoice',
  async ({ invoice, getTokenCallback }, { getState }) => {
    // Indicate to backend this invoice is being submitted
    const submittableInvoice = { ...invoice, status: InvoiceStatus.final }
    return await saveInvoiceCallLogic(submittableInvoice, getTokenCallback, getState)
  }
)

async function saveInvoiceCallLogic(invoice, getTokenCallback, getState) {
  const projectId = invoice.projectId
  const periodId = invoice.periodId
  try {
    const saveResult = await api.put(
      api.endpoints(getState().client.name).invoices.save(),
      invoice,
      getTokenCallback
    )

    const fetchResult = await api.getData(
      api.endpoints(getState().client.name).projects.v1.invoiceDetails(projectId, periodId),
      getTokenCallback
    )

    return {
      submittedInvoice: invoice,
      updatedInvoice: saveResult,
      invoicesList: fetchResult,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

const slice = createSlice({
  name: 'invoiceDetails',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
    resetUpdateError(state, action) {
      state.updateError = initialState.updateError
      state.updateState = initialState.updateState
    },
    addInvoice(state, action) {
      const newInvoice = action.payload?.newInvoice
      if (newInvoice) {
        state.data.invoices[newInvoice.id] = {
          editableVersion: newInvoice,
          referenceVersion: newInvoice,
          isModified: false,
        }
      }
    },
    replaceInvoice(state, action) {
      const invoiceToReplaceId = action.payload?.invoiceToReplaceId
      const updatedInvoice = action.payload?.updatedInvoice
      const originalInvoice = state.data.invoices[invoiceToReplaceId]?.referenceVersion

      if (invoiceToReplaceId && updatedInvoice) {
        state.data.invoices[invoiceToReplaceId] = {
          ...state.data.invoices[invoiceToReplaceId],
          editableVersion: updatedInvoice,
          isModified: !deepEqual(originalInvoice, updatedInvoice),
        }
      }
    },
    setActiveInvoiceId(state, action) {
      state.data.activeInvoiceId = action.payload
    },
  },
  extraReducers: {
    [fetchInvoiceDetails.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchInvoiceDetails.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.loadingError = action?.error?.message
    },
    [fetchInvoiceDetails.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload
      state.data.invoices = createInvoiceCollection(action.payload?.invoicesById)
      state.data.template = prepareTemplate(action.payload.template)
    },
    [saveInvoice.pending]: (state, action) => {
      state.updateState = LoadingState.pending
      // Reset existing errors on new save request
      state.submitError = null
      state.updateError = null
    },
    [saveInvoice.rejected]: (state, action) => {
      state.updateState = LoadingState.rejected
      state.updateError = action.error.message
    },
    [saveInvoice.fulfilled]: (state, action) => {
      state.data.invoices = createInvoiceCollection(action?.payload?.invoicesList?.invoicesById)
      state.updateState = LoadingState.fulfilled
    },
    [submitInvoice.pending]: (state, action) => {
      state.submitState = LoadingState.pending
      // Reset existing errors on new submit request
      state.submitError = null
      state.updateError = null
    },
    [submitInvoice.rejected]: (state, action) => {
      state.submitState = LoadingState.rejected
      state.submitError = action.error.message
    },
    [submitInvoice.fulfilled]: (state, action) => {
      state.data.invoices = createInvoiceCollection(action?.payload?.invoicesList?.invoicesById)
      state.submitState = LoadingState.fulfilled
    },
    [resetProjectState]: (state, action) => {
      return initialState
    },
  },
})

function createInvoiceCollection(invoices) {
  const invoiceCollections = {}

  if (!invoices) {
    console.error('No invoices found invoices passed to createInvoiceCollection')
    return invoiceCollections
  }

  invoices?.forEach((invoice) => {
    invoiceCollections[invoice.id] = {
      editableVersion: invoice,
      referenceVersion: invoice,
      isModified: false,
    }
  })
  return invoiceCollections
}

export const selectLoadingError = (state) => state.invoiceDetails.loadingError
export const selectLoadingState = (state) => state.invoiceDetails.loadingState
export const selectSaveState = (state) => state.invoiceDetails.updateState
export const selectSaveError = (state) => state.invoiceDetails.updateError
export const selectSubmitState = (state) => state.invoiceDetails.submitState
export const selectSubmitError = (state) => state.invoiceDetails.submitError
export const selectInvoiceTemplate = (state) => state.invoiceDetails.data.template
export const selectInvoiceCollections = (state) => state.invoiceDetails.data.invoices
export const selectActiveInvoiceId = (state) => state.invoiceDetails.data.activeInvoiceId
export const selectIsInvoicingAllowed = (state) => state.invoiceDetails.data.isInvoicingAllowed
export const selectInvoicePeriodId = (state) => state.invoiceDetails.data.periodId
export const selectInvoiceClientName = (state) => state.invoiceDetails.data.clientName

export const selectActiveInvoice = createSelector(
  selectInvoiceCollections,
  selectActiveInvoiceId,
  (invoiceCollections, activeInvoiceId) => {
    return invoiceCollections[activeInvoiceId]?.editableVersion || null
  }
)

export const selectActiveInvoiceLabel = createSelector(selectActiveInvoice, (activeInvoice) => {
  return activeInvoice?.label || ''
})

export const selectActiveReferenceInvoice = createSelector(
  selectInvoiceCollections,
  selectActiveInvoiceId,
  (invoiceCollections, activeInvoiceId) => {
    return invoiceCollections[activeInvoiceId]?.referenceVersion || null
  }
)

// Has the active invoice changed from the original referenceVersion invoice?
export const selectIsActiveInvoiceModified = createSelector(
  selectInvoiceCollections,
  selectActiveInvoiceId,
  (invoiceCollections, activeInvoiceId) => {
    return invoiceCollections[activeInvoiceId]?.isModified || false
  }
)

export const selectIsDraftInvoicePresent = createSelector(
  selectInvoiceCollections,
  (invoiceCollections) => {
    const isDraftPresent = INVOICE_DRAFT_ID in invoiceCollections
    return isDraftPresent
  }
)

export const selectIsActiveInvoiceADraft = createSelector(
  selectActiveInvoiceId,
  (activeInvoiceId) => {
    return activeInvoiceId === INVOICE_DRAFT_ID
  }
)

export const selectInvoices = createSelector(selectInvoiceCollections, (invoiceCollections) => {
  const invoices = Object.values(invoiceCollections).map(
    (invoiceCollection) => invoiceCollection.editableVersion
  )
  return invoices
})

export default slice.reducer
export const { reset, resetUpdateError, addInvoice, setActiveInvoiceId, replaceInvoice } =
  slice.actions
