const MuiPopper = {
  styleOverrides: {
    root: ({ theme }) => ({
      zIndex: 1,
      '& .MuiPaper-root': {
        boxShadow: 'none',
      },
      '& .MuiTypography-root': {
        padding: '0px 5px 0px 0.25px',
      },
      'a': {
        color: theme.palette.secondary.main,
        fontSize: '0.6875rem',
        letterSpacing: '0.009325rem',
      },
    })
  }
}

export default MuiPopper