export function hexToRgb(hexCode) {
  let r = 0,
    g = 0,
    b = 0

  // 3 digits
  if (hexCode.length === 4) {
    r = '0x' + hexCode[1] + hexCode[1]
    g = '0x' + hexCode[2] + hexCode[2]
    b = '0x' + hexCode[3] + hexCode[3]

    // 6 digits
  } else if (hexCode.length === 7) {
    r = '0x' + hexCode[1] + hexCode[2]
    g = '0x' + hexCode[3] + hexCode[4]
    b = '0x' + hexCode[5] + hexCode[6]
  }

  return [r, g, b]
}

export function rgbToHex(rgb) {
  let r = rgb[0].toString(16)
  let g = rgb[1].toString(16)
  let b = rgb[2].toString(16)

  if (r.length === 1) r = '0' + r
  if (g.length === 1) g = '0' + g
  if (b.length === 1) b = '0' + b

  return '#' + r.toUpperCase() + g.toUpperCase() + b.toUpperCase()
}

export function interpolateColour(colour1, colour2, percent) {
  if (colour1 && colour2 && percent) {
    const usePercent = Math.max(Math.min(percent, 1), 0)
    const rgb1 = hexToRgb(colour1)
    const rgb2 = hexToRgb(colour2)
    const weight1 = usePercent
    const weight2 = 1 - usePercent
    const newRgb = [
      Math.round(rgb1[0] * weight1 + rgb2[0] * weight2),
      Math.round(rgb1[1] * weight1 + rgb2[1] * weight2),
      Math.round(rgb1[2] * weight1 + rgb2[2] * weight2),
    ]
    const newColour = rgbToHex(newRgb)
    return newColour
  } else {
    return null
  }
}
