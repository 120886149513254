const workbookStatus = {
  doesNotExist: -1,
  unknown: 0,
  building: 1,
  active: 2,
  finalised: 3,
  failed: 4,
  cancelled: 5,
  superceded: 6,
  reconciliation: 7, // Reconciling
  review: 8, // Invoicing
  pdReview: 9, // Reviewing
}

export default workbookStatus
