import * as EmailValidator from 'email-validator'

function validateBudget(budget) {
  var numberBudget =
    budget !== null && budget !== undefined && budget !== ''
      ? Number(budget)
      : null
  return numberBudget !== null && !isNaN(numberBudget) && numberBudget >= 0
}

function validateEmail(email) {
  return EmailValidator.validate(email)
}

const minStaffRate = 50
const maxStaffRate = 700
function validateStaffRate(rate) {
  var numberRate = rate ? Number(rate) : null
  return (
    numberRate !== null &&
    !isNaN(numberRate) &&
    numberRate >= minStaffRate &&
    numberRate <= maxStaffRate
  )
}

function validateDateString(date) {
  return date !== null && date.length > 0
}

export {
  validateBudget,
  validateEmail,
  minStaffRate,
  maxStaffRate,
  validateStaffRate,
  validateDateString,
}
