import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import ButtonLink from '../primitives/ButtonLink'
import { selectCurrentAndPastPeriods } from '../slices/periods'
import { selectProjectsMap } from '../slices/projects'
import config from '../config'

const tempoReportPath = '#!/reports/logged-time'
const tempoQueryParams =
  '?columns=WORKED_COLUMN&dateDisplayType=days&groupBy=project&groupBy=issue&groupBy=worker&groupBy=worklog&order=ASCENDING&periodKey&periodType=FIXED&showCharts=false&sortBy=TITLE_COLUMN&subPeriodType=MONTH&viewType=LIST'

const TempoLink = ({ className, periodId, projectId }) => {
  const periods = useSelector(selectCurrentAndPastPeriods)
  const projects = useSelector(selectProjectsMap)

  const tempoLink = useMemo(() => {
    const period = periods
      ? periods.find((period) => period.id === periodId)
      : null
    const project = projects ? projects[projectId] : null

    var tempoLink = config.tempo.baseUrl
    if (period && project) {
      tempoLink += tempoReportPath + tempoQueryParams
      tempoLink += `&epicId=${project.issueId}`
      tempoLink += `&from=${moment(period.periodStart).format('YYYY-MM-DD')}`
      tempoLink += `&to=${moment(period.periodEnd).format('YYYY-MM-DD')}`
    }
    return tempoLink
  }, [periodId, periods, projectId, projects])

  return (
    <ButtonLink
      className={className}
      to={tempoLink}
      label="View In Tempo"
    />
  )
}

export default TempoLink
