import moment from 'moment'

import calculateSums from './calculateTimeCostReportSums'
import { formatMoney, formatHours } from './format'
import { RowType } from '../views/ReportTable'
import { viewModes } from '../views/TimeCostReportViewModeSelector'

const numAdditionalColumns = 8
const rateErrorMessage = 'Missing rate(s). Cannot calculate fees.'
const mappingErrorMessage =
  'These work items must be assigned to a contract line item.'

function hierarchicalSort(list) {
  list.sort((a, b) => {
    let aHasPeriodHours = a.periodHours ? 1 : 0
    let bHasPeriodHours = b.periodHours ? 1 : 0
    let hasHoursDiff = bHasPeriodHours - aHasPeriodHours
    let periodWipDiff = b.periodWip - a.periodWip
    let periodHoursDiff = b.periodHours - a.periodHours
    let totalWipDiff = b.totalWip - a.totalWip
    let totalHoursDiff = b.totalHours - a.totalHours
    return hasHoursDiff
      ? hasHoursDiff
      : periodWipDiff
        ? periodWipDiff
        : periodHoursDiff
          ? periodHoursDiff
          : totalWipDiff
            ? totalWipDiff
            : totalHoursDiff
              ? totalHoursDiff
              : a.name.localeCompare(b.name)
  })
}

function hoursToCells(hours) {
  return hours.map((hourValue) => ({
    content: formatHours(hourValue),
  }))
}

function wipToCells(wips) {
  return wips.map((wipValue) => ({
    content: formatMoney(wipValue),
  }))
}

export function makeHeaderRow(dateRanges, options) {
  const rowWidth = 120

  const numWeeks = dateRanges.length
  const weekCells = Array(numWeeks)
  for (let i = 0; i < numWeeks; i++) {
    weekCells[i] = {
      content: `Week ${i + 1}`,
      description: `${moment(dateRanges[i].from).format('D MMM')} - ${moment(
        dateRanges[i].to
      ).format('D MMM')}`,
      width: rowWidth,
    }
  }

  return [
    {
      type: RowType.h2,
      cells: [
        {
          content: '',
        },
        {
          content: options.viewMode === viewModes.collapsed ? '' : 'Rate',
          description:
            options.viewMode === viewModes.collapsed
              ? undefined
              : 'The hourly rate for this employee',
          width: rowWidth,
        },
        {
          content: 'Period Hours',
          description: 'Total hours logged for the project this period',
          width: rowWidth,
        },
        {
          content: 'Period Fees',
          description: 'Period Hours x Rate',
          width: rowWidth,
        },
        ...weekCells,
        {
          type: 'spacer',
        },
        {
          content: 'Project Hours',
          description: 'Total hours logged for the project since outset',
          width: rowWidth,
        },
        {
          content: 'Project Fees',
          description: 'Total Hours x Rate',
          width: rowWidth,
        },
      ],
    },
  ]
}

function makeRow(type, error, warning, content, options) {
  const {
    title,
    rate,
    totalHours,
    totalWip,
    periodHours,
    periodWip,
    weeklyHours,
    weeklyWips,
  } = content
  const { displayWipNotHours } = options

  return {
    type: type,
    isError: error ? true : false,
    isWarning: warning ? true : false,
    cells: [
      {
        content: title,
        description: error ? error : warning ? warning : '',
      },
      {
        content: rate ? formatMoney(rate) : '',
      },
      {
        content: formatHours(periodHours),
      },
      {
        content: formatMoney(periodWip),
      },
      ...(displayWipNotHours
        ? wipToCells(weeklyWips)
        : hoursToCells(weeklyHours)),
      {
        type: 'spacer',
      },
      {
        content: formatHours(totalHours),
        type: 'bold',
      },
      {
        content: formatMoney(totalWip),
        type: 'bold',
      },
    ],
  }
}

function makeTitleRow(rowProps, cellProps, options) {
  const { numWeeks } = options
  return {
    type: RowType.total,
    rateError: null,
    ...rowProps,
    cells: [
      {
        colSpan: numAdditionalColumns + numWeeks - 4,
        ...cellProps,
      },
      {
        type: 'spacer',
      },
      {
        colSpan: 3,
      },
    ],
  }
}

function makeTotalRow(totalSums, options) {
  return makeRow(
    RowType.total,
    totalSums.rateError ? rateErrorMessage : null,
    null,
    {
      title: options.totalLabel,
      rate: '',
      totalHours: totalSums.totalHours,
      totalWip: totalSums.totalWip,
      periodHours: totalSums.periodHours,
      periodWip: totalSums.periodWip,
      weeklyHours: totalSums.weeklyHours,
      weeklyWips: totalSums.weeklyWips,
    },
    options
  )
}

export function makeSpacerRow(numWeeks) {
  return {
    type: RowType.line,
    rateError: null,
    cells: [
      {
        content: '',
        colSpan: numAdditionalColumns + numWeeks,
        type: 'spacer',
      },
    ],
  }
}

function generateCollapsedLineItemRows(lineItemsSums, options) {
  const filteredLineItemsSums = options.displayAllLineItems
    ? lineItemsSums
    : lineItemsSums.filter((sums) => sums.totalHours > 0)

  return filteredLineItemsSums.map((lineItemSums) =>
    makeRow(
      RowType.line,
      lineItemSums.rateError ? rateErrorMessage : null,
      lineItemSums.mappingError ? mappingErrorMessage : null,
      {
        title: lineItemSums.name,
        rate: '',
        totalHours: lineItemSums.totalHours,
        totalWip: lineItemSums.totalWip,
        periodHours: lineItemSums.periodHours,
        periodWip: lineItemSums.periodWip,
        weeklyHours: lineItemSums.weeklyHours,
        weeklyWips: lineItemSums.weeklyWips,
      },
      options
    )
  )
}

function generateExpandedLineItemRows(lineItemsSums, options) {
  let rows = []

  const filteredLineItemsSums = options.displayAllLineItems
    ? lineItemsSums
    : lineItemsSums.filter((sums) => sums.totalHours > 0)

  filteredLineItemsSums.forEach((lineItemSums) => {
    const subHeaderRow = makeTitleRow(
      {
        type: RowType.h3,
        isError: false,
        isWarning: lineItemSums.mappingError,
      },
      {
        content: lineItemSums.name,
        description: lineItemSums.mappingError ? mappingErrorMessage : null,
      },
      options
    )

    const subTotalRow = makeRow(
      RowType.subtotal,
      lineItemSums.rateError ? rateErrorMessage : null,
      lineItemSums.mappingError ? mappingErrorMessage : null,
      {
        title: lineItemSums.mappingError
          ? 'Unassigned Work Item Totals'
          : 'Contract Line Item Totals',
        rate: '',
        totalHours: lineItemSums.totalHours,
        totalWip: lineItemSums.totalWip,
        periodHours: lineItemSums.periodHours,
        periodWip: lineItemSums.periodWip,
        weeklyHours: lineItemSums.weeklyHours,
        weeklyWips: lineItemSums.weeklyWips,
      },
      options
    )

    const workLogsRows = generateExpandedWorkItemRows(
      lineItemSums.workItemsSums,
      options,
      true
    )

    rows = [...rows, subHeaderRow, ...workLogsRows, subTotalRow]
  })

  return rows
}

function generateExpandedWorkItemRows(workItemsSums, options, isSubSection) {
  let rows = []

  workItemsSums.forEach((workItemSums) => {
    if (workItemSums.totalHours > 0) {
      const subHeaderRow = makeTitleRow(
        {
          type: isSubSection ? RowType.h4 : RowType.h3,
        },
        {
          content: workItemSums.name,
        },
        options
      )

      const subTotalRow = makeRow(
        RowType.subsubtotal,
        workItemSums.rateError ? rateErrorMessage : null,
        null,
        {
          title: 'Work Item Totals',
          rate: '',
          totalHours: workItemSums.totalHours,
          totalWip: workItemSums.totalWip,
          periodHours: workItemSums.periodHours,
          periodWip: workItemSums.periodWip,
          weeklyHours: workItemSums.weeklyHours,
          weeklyWips: workItemSums.weeklyWips,
        },
        options
      )

      const workLogsRows = workItemSums.workLogsSums.map((workLogSums) =>
        makeRow(
          RowType.indentedLine,
          workLogSums.rateError ? 'Missing rate. Cannot calculate fees.' : null,
          null,
          {
            title: workLogSums.name,
            rate: workLogSums.rate,
            totalHours: workLogSums.totalHours,
            totalWip: workLogSums.totalWip,
            periodHours: workLogSums.periodHours,
            periodWip: workLogSums.periodWip,
            weeklyHours: workLogSums.weeklyHours,
            weeklyWips: workLogSums.weeklyWips,
          },
          options
        )
      )

      rows = [...rows, subHeaderRow, ...workLogsRows, subTotalRow]
    }
  })

  return rows
}

function generatePeopleRows(peopleSums, options) {
  return peopleSums.map((personSums) =>
    makeRow(
      RowType.line,
      personSums.rateError ? 'Missing rate. Cannot calculate fees.' : null,
      null,
      {
        title: personSums.name,
        rate: personSums.rate,
        totalHours: personSums.totalHours,
        totalWip: personSums.totalWip,
        periodHours: personSums.periodHours,
        periodWip: personSums.periodWip,
        weeklyHours: personSums.weeklyHours,
        weeklyWips: personSums.weeklyWips,
      },
      options
    )
  )
}

export function generateRows(lineItems, workItemLogs, options) {
  const { hideHistoricWork, numWeeks, title, viewMode } = options

  // calculate sums
  let { lineItemsSums, peopleSums, totalSums, workItemsSums } = calculateSums(
    lineItems,
    workItemLogs,
    numWeeks
  )

  // sort sums
  hierarchicalSort(peopleSums)
  hierarchicalSort(workItemsSums)
  for (let workItemSums of workItemsSums) {
    hierarchicalSort(workItemSums.workLogsSums)
  }
  //hierarchicalSort(lineItemsSums) // don't sort line items to preserve id order
  for (let lineItemSums of lineItemsSums) {
    hierarchicalSort(lineItemSums.workItemsSums)
    for (let workItemSums of lineItemSums.workItemsSums) {
      hierarchicalSort(workItemSums.workLogsSums)
    }
  }

  // filter sums
  if (hideHistoricWork) {
    peopleSums = peopleSums.filter((sum) => sum.periodHours)
    workItemsSums = workItemsSums.filter((sum) => sum.periodHours)
    for (let workItemSums of workItemsSums) {
      workItemSums.workLogsSums = workItemSums.workLogsSums.filter(
        (sum) => sum.periodHours
      )
    }
    lineItemsSums = lineItemsSums.filter((sum) => sum.periodHours)
    for (let lineItemSums of lineItemsSums) {
      lineItemSums.workItemsSums = lineItemSums.workItemsSums.filter(
        (sum) => sum.periodHours
      )
      for (let workItemSums of lineItemSums.workItemsSums) {
        workItemSums.workLogsSums = workItemSums.workLogsSums.filter(
          (sum) => sum.periodHours
        )
      }
    }
  }

  // transform into rows
  let rows = [makeTitleRow({}, { content: title }, options)]
  if (viewMode === viewModes.collapsed) {
    rows = [...rows, ...generateCollapsedLineItemRows(lineItemsSums, options)]
  } else if (viewMode === viewModes.expanded) {
    rows = [...rows, ...generateExpandedLineItemRows(lineItemsSums, options)]
  } else if (viewMode === viewModes.people) {
    rows = [...rows, ...generatePeopleRows(peopleSums, options)]
  }

  // add total row
  const totalRow = makeTotalRow(totalSums, options)
  rows.push(totalRow)
  return rows
}
