import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import resetProjectState from './resetProjectState'
import api from '../utils/api'
import LoadingState from '../utils/LoadingState'

const initialState = {
  loadingState: LoadingState.idle,
  data: {},
  updateState: LoadingState.idle,
  updateError: null,
}

export const fetchProjectRates = createAsyncThunk(
  'fetchProjectRates',
  async ({ projectId, getTokenCallback }, { getState }) => {
    return await api.getData(
      api.endpoints(getState().client.name).projects.v1.staffRates(projectId),
      getTokenCallback
    )
  }
)

export const updateProjectRates = createAsyncThunk(
  'updateProjectRates',
  async ({ projectId, projectRates, getTokenCallback }, { getState }) => {
    return await api.put(
      api.endpoints(getState().client.name).projects.v1.staffRates(projectId),
      projectRates,
      getTokenCallback
    )
  }
)

const slice = createSlice({
  name: 'projectRates',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
    resetSaveError(state, action) {
      state.updateState = initialState.updateState
      state.updateError = initialState.updateError
    },
  },
  extraReducers: {
    [fetchProjectRates.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchProjectRates.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error
    },
    [fetchProjectRates.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload
    },
    [updateProjectRates.pending]: (state, action) => {
      state.updateState = LoadingState.pending
    },
    [updateProjectRates.rejected]: (state, action) => {
      state.updateState = LoadingState.rejected
      state.updateError = action.error.message
    },
    [updateProjectRates.fulfilled]: (state, action) => {
      state.updateState = LoadingState.fulfilled
    },
    [resetProjectState]: (state, action) => {
      return initialState
    },
  },
})

export const { reset, resetSaveError } = slice.actions

export const selectProjectRates = (state) => state.projectRates.data
export const selectLoadingState = (state) => state.projectRates.loadingState
export const selectUpdateError = (state) => state.projectRates.updateError
export const selectUpdateState = (state) => state.projectRates.updateState

export default slice.reducer
