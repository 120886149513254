
import { TableCell, Tooltip } from '@mui/material'
import colours from '../styles/colours'
import { interpolateColour } from '../utils/colourUtils'
import { useEffect, useRef } from 'react'
import { forwardRef } from 'react'

const cellSx = {
  h1: {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    verticalAlign: 'top',
    lineHeight: 1.5,
  },
  h1Sticky: {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    fontWeight: 'bold',
    left: 60,
    position: 'sticky',
    textAlign: 'center',
    textTransform: 'uppercase',
    verticalAlign: 'top',
    zIndex: 99,
  },
  h2: {
    backgroundColor: colours.awaGray15,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    verticalAlign: 'top',
    lineHeight: 1.5,
  },
  h2Sticky: {
    backgroundColor: colours.awaGray15,
    fontWeight: 'bold',
    left: 60,
    position: 'sticky',
    textAlign: 'center',
    textTransform: 'uppercase',
    verticalAlign: 'top',
    zIndex: 99,
  },
  h3: {
    backgroundColor: colours.awaGrayUltralight,
    fontWeight: 'bold',
    textAlign: 'center',
    verticalAlign: 'top',
    fontSize: 10,
    lineHeight: 1.5,
  },
  h3Sticky: {
    backgroundColor: colours.awaGrayUltralight,
    fontWeight: 'bold',
    left: 60,
    maxWidth: 100,
    minWidth: 100,
    position: 'sticky',
    textAlign: 'center',
    verticalAlign: 'top',
    width: 100,
    zIndex: 99,
  },
  dataRowH1: {
    backgroundColor: 'primaryHighlight.main',
    fontWeight: 'bold',
    left: 60,
    maxWidth: 100,
    minWidth: 100,
    paddingLeft: 0,
    paddingRight: 0,
    position: 'sticky',
    textAlign: 'center',
    width: 100,
  },
  percent: { textAlign: 'center' },
  hours: {
    backgroundColor: colours.awaLightBlue,
    textAlign: 'center',
  },
  hoursCollapsed: {
    backgroundColor: colours.awaLightBlue,
    py: 0,
  },
}

export const CellType = {
  h1: 'h1',
  h1Sticky: 'h1Sticky',
  h2: 'h2',
  h2Sticky: 'h2Sticky',
  h3: 'h3',
  h3Sticky: 'h3Sticky',
  dataRowH1: 'dataRowH1',
  dataRowH1Collapsed: 'dataRowH1Collapsed',
  dataRowH2Collapsed: 'dataRowH2Collapsed',
  dataRowH3Collapsed: 'dataRowH3Collapsed',
  percent: 'percent',
  hours: 'hours',
  hoursCollapsed: 'hoursCollapsed',
}

export const ChargeabilityCell = forwardRef(({
  cellWidth,
  colSpan,
  children,
  heatmapPercentage,
  currentTimeSpan,
  isFirstInGroup,
  isFirstInTeam,
  isRowTitle,
  type,
  tooltip,
},
ref) => {
  
  const sx = {
    // Mui processed values
    border: 'none',
    color: 'inherit',
    pl: 0.5,
    pr: 0.5,
    py: 0.5,
    
    // Non MUI processed values (direct CSS)
    fontSize: 11,
    fontWeight: 'inherit'
  }

  if (isFirstInGroup) {
    Object.assign(sx, { borderLeft: '3px solid', borderLeftColor: 'primary.contrastText' })
  } else if (isFirstInTeam) {
    Object.assign(sx, { borderLeft: '1px solid', borderLeftColor: 'primary.contrastText' })
  }

  if (isRowTitle) { Object.assign(sx, { fontWeight: 'bold', textAlign: 'right' }) }
  if (type) { Object.assign(sx, cellSx[type]) }
  if (currentTimeSpan) { Object.assign(sx, { backgroundColor: colours.awaLightBlue, color: colours.awaGray50 }) }

  const cell = (
    <TableCell
      colSpan={colSpan}
      ref={ref}
      style={{
        // special handling for heatmap colours to stop emotion from creating too many css styles
        backgroundColor: (!heatmapPercentage || isNaN(heatmapPercentage) || type !== CellType.percent)
          ? null
          : interpolateColour(colours.awaYellow, colours.white, heatmapPercentage),
        minWidth: cellWidth ? `${cellWidth}px` : undefined
      }}
      sx={sx}
    >
      {children}
    </TableCell>
  )

  return tooltip ? (
    <Tooltip arrow title={tooltip}>
      {cell}
    </Tooltip>
  ) : (
    cell
  )
})

export const ChargeabilityCellWithRef = ({
  updateMaxWidth,cellWidth,...props}) => {
  // Used to get the width of the cell for finding the max cell width across all cells
  const refElem = useRef(0)

  // On cell data change, attempt to update the max width with this cell's width
  useEffect(() => {
    if (updateMaxWidth && refElem.current.offsetWidth> cellWidth) {
      updateMaxWidth(refElem.current.offsetWidth)
    }
  }, [refElem.current.offsetWidth, cellWidth, updateMaxWidth])

  return <ChargeabilityCell ref={refElem} cellWidth={cellWidth} {...props}/>
}