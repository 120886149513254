import { Button as MuiButton } from '@mui/material'

const buttonSx = {
  bgcolor: 'primaryHighlight.light',
  borderColor: 'primary.main',
  color: 'primary.main',
  minWidth: 80,
  height: 32,
  fontSize: 14,
  border: '1px solid',
  borderRadius: 0,
  '&.Mui-disabled': {
    border: 'none',
  },
  '&:hover': {
    bgcolor: 'secondary.main',
    color: 'primaryHightlight.light',
  },
}

const Button = ({ label, sx, ...buttonProps }) => (
  <MuiButton sx={{ ...buttonSx, ...sx }} {...buttonProps}>
    {label}
  </MuiButton>
)

export default Button
