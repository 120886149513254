import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import ProjectWorkItemMappingDialog from '../containers/ProjectWorkItemMappingDialog'

import {
  fetchWorkItemMapping,
  selectMappingData,
  selectLoadingState,
} from '../slices/workItemMapping'
import LoadingState from '../utils/LoadingState'
import { isMappingValid } from '../utils/mappingUtils'

const ContractConfigurationContainer = ({ projectId, view: GridRow }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const mappingData = useSelector(selectMappingData)
  const loadingState = useSelector(selectLoadingState)

  const [dialogOpen, setDialogOpen] = useState(false)

  const fetchMapping = useCallback(() => {
    dispatch(
      fetchWorkItemMapping({
        projectId,
        getTokenCallback,
      })
    )
  }, [dispatch, getTokenCallback, projectId])

  useEffect(() => {
    if (projectId !== null && loadingState === LoadingState.idle) {
      fetchMapping()
    }
  }, [projectId, loadingState, fetchMapping])

  const isLoading =
    loadingState === LoadingState.idle || loadingState === LoadingState.pending

  const isError =
    loadingState === LoadingState.rejected ||
    mappingData === null ||
    !isMappingValid(mappingData)

  const contractStatus = useMemo(() => {
    const lineItemsCount =
      mappingData && mappingData.lineItems && mappingData.lineItems.length >= 1
        ? mappingData.lineItems.length - 1
        : 0
    return isLoading
      ? 'loading...'
      : isError
        ? 'Error. Failed to load.'
        : `${lineItemsCount} ${lineItemsCount === 1 ? 'line item' : 'line items'}`
  }, [isError, isLoading, mappingData])

  const gridRowProps = {
    buttonProps: {
      children: 'Contract',
      disabled: isError || isLoading,
      onClick: () => setDialogOpen(true),
    },
    dialogComponent: () =>
      <ProjectWorkItemMappingDialog
        projectId={projectId}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />,
    label: contractStatus,
  }

  return <GridRow {...gridRowProps} />
}

export default ContractConfigurationContainer
