import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import resetProjectState from './resetProjectState'
import api from '../utils/api'
import LoadingState from '../utils/LoadingState'

const initialState = {
  loadingState: LoadingState.idle,
  data: null,
}

export const fetchProjectCandidates = createAsyncThunk(
  'fetchProjectCandidates',
  async ({ projectId, getTokenCallback }, { getState }) => {
    const projectCandidatesData = await api.getData(
      api.endpoints(getState().client.name).people.projectCandidates(projectId),
      getTokenCallback
    )
    return projectCandidatesData
  }
)

const slice = createSlice({
  name: 'projectCandidates',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
  },
  extraReducers: {
    [fetchProjectCandidates.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchProjectCandidates.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error
    },
    [fetchProjectCandidates.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload
    },
    // Synchronise with resetProjectState
    [resetProjectState]: (state, action) => {
      return initialState
    },
  },
})

export const { reset } = slice.actions

export const selectProjectCandidates = (state) => state.projectCandidates.data
export const selectLoadingState = (state) =>
  state.projectCandidates.loadingState

export default slice.reducer
