import { Box, Button, CircularProgress, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useRef, useState } from 'react'
import LoadingState from '../utils/LoadingState'
import colours from '../styles/colours'

const animationState = {
  notReady: 0,
  ready: 1,
  running: 2,
}

const useStyles = makeStyles({
  drawTick: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '24px',
    height: '24px',
    fill: 'none',
    stroke: colours.awaGreen,
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeDasharray: 25,
    strokeDashoffset: 25,
    animation: '$drawTick 0.5s linear forwards',
  },
  '@keyframes drawTick': {
    '0%': {
      strokeDashoffset: 25,
    },
    '100%': {
      strokeDashoffset: 0,
    },
  },
  drawCross: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '24px',
    height: '24px',
    fill: 'none',
    stroke: colours.awaRed,
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeDasharray: 25,
    strokeDashoffset: 25,
    animation: '$drawCross 1s linear forwards',
  },
  '@keyframes drawCross': {
    '0%': {
      strokeDashoffset: 25,
    },
    '100%': {
      strokeDashoffset: 0,
    },
  },
})

export const AnimatedTickButton = ({
  loadingState,
  idleText,
  pendingText,
  handleClick,
  enabledColour,
  disabledColour,
  disabledMessage,
  sx,
  ...buttonProps
}) => {
  const classes = useStyles()
  const [curAnimationState, setCurAnimationState] = useState(animationState.notReady)
  const isMounted = useRef(true)

  const tickSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -2 18 18" className={classes.drawTick}>
      <path d="M 3 7 L 7 11 L 15 3" />
    </svg>
  )

  const crossPath = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 18 18" className={classes.drawCross}>
      <path d="M 3 3 L 11 11 M 3 11 L 11 3" />
    </svg>
  )

  // Track when the component is unmounted to prevent animation steps from continuing when the component isn't rendered
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  // Logic to handle updating the animation state based on the provided loading state
  useEffect(() => {
    if ([LoadingState.fulfilled, LoadingState.rejected].includes(loadingState)) {
      if (curAnimationState === animationState.ready) {
        setCurAnimationState(animationState.running)
        // Reset so that animation won't run again until the save endpoint is called
        // Prevents rerunning of the animation on changing tabs and rerendering the save button
        setTimeout(() => {
          if (isMounted.current) {
            setCurAnimationState(animationState.notReady)
          }
        }, 1500)
      }
    }
    // When the endpoint is called, prepare the animation for a run
    if (loadingState === LoadingState.pending) {
      setCurAnimationState(animationState.ready)
    }
  }, [loadingState, curAnimationState])

  const displayAnimation = curAnimationState === animationState.running
  const actionInProgress = loadingState === LoadingState.pending
  // Don't allow clicking during animation
  const disabled = buttonProps.disabled || displayAnimation || actionInProgress

  const fulfilledState = displayAnimation ? tickSvg : idleText
  const rejectedState = displayAnimation ? crossPath : idleText

  return (
    <Tooltip title={disabledMessage}>
      <Box>
        <Button {...buttonProps} sx={{ ...sx }} onClick={handleClick} className={classes.button} disabled={disabled}>
          {loadingState === LoadingState.fulfilled && fulfilledState}
          {loadingState === LoadingState.rejected && rejectedState}
          {loadingState === LoadingState.pending && <CircularProgress size={20} />}
          {loadingState === LoadingState.idle && idleText}
        </Button>
      </Box>
    </Tooltip>
  )
}
