import { Box, Input } from '@mui/material'
import TitledBox from '../primitives/TitledBox'

const regexUrl = new RegExp(/(?=http:|https:|ftp:)\S*/g)

const convertUrlsInComment = (value = null) => {
  let newComment = [],
    i = 0,
    remainingString = ''
  if (value !== null) {
    while (i < value.length) {
      remainingString = value.slice(i)
      const indexUrl = remainingString.search(regexUrl)

      // format comment string in <div/> by converting \n to <br/>
      const unformattedSubstring = indexUrl < 0 ? remainingString : remainingString.slice(0, indexUrl)
      const textArray = unformattedSubstring.split('\n')
      textArray.forEach((line, j) => {
        newComment.push(line)
        if (j < textArray.length - 1) {
          newComment.push(<br key={`n${j}`} />)
        }
      })

      // format comment string in <div/> by converting urls to <a/>
      if (indexUrl < 0) {
        break
      }
      const url = remainingString.match(regexUrl)[0]
      const link = (
        <a href={url} target="_blank" rel="noreferrer" key={`link${i}`}>
          {url}
        </a>
      )
      newComment.push(link)
      i += indexUrl + url.length
    }
  }
  return newComment
}

const commentSx = {
  fontSize: 11,
  width: '100%',
  letterSpacing: '0.1px',
  lineHeight: '15.06px',
  minHeight: '80px',
  p: 1,
}

const CommentField = ({ disabled, label, name, onChange, value, minWidth }) => {
  
  const commentContent = disabled ? (
    // Can't include hyperlinks in an input component  so use a box component instead
    <Box
      sx={{
        ...commentSx,
        color: "text.main",
        wordWrap: 'break-word',
        wordBreak: 'break-all',
        whiteSpace: 'normal',
      }}
    >
      {convertUrlsInComment(value)}
    </Box>
  ) : (
    <Input
      disabled={disabled}
      disableUnderline={false}
      minRows={4}
      multiline
      onChange={(event) => onChange(event.target.value)}
      sx={{ ...commentSx, width: '100%' }}
      value={value === null ? '' : value}
      inputProps={{ 'aria-label': name }}
    />
  )

  return (
    <TitledBox title={label} minWidth={{xs: '100%', sm: minWidth}} containerSx = {{display: "flex", flex: 1}}>
      <Box
        sx={{
          backgroundColor: disabled ? 'background.light' : 'primaryHighlight.main',
          boxShadow: 1,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {commentContent}
      </Box>
    </TitledBox>
  )
}

export default CommentField
