import { Box } from '@mui/material'

const baseHeaderSx = {
  border: 'none',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}

const baseCellSx = {
  color: 'inherit',
  border: 'none',
  fontWeight: 'inherit',
  fontSize: 11,
  minWidth: '100px',
  px: 1,
  py: 0.5,
  alignContent: 'center'
}

export const TitledBox = ({ children, title, containerSx, minWidth }) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'column',
        backgroundColor: 'background.light',
        width: 'fit-content',
        boxShadow: 0,
        border: '1px solid',
        borderColor: 'secondaryHighlight.main',
        minWidth,
        ...containerSx,
      }}
    >
      <Box
        sx={{
          ...baseHeaderSx,
          px: 1,
          fontSize: 11,
          minHeight: '30px',
          color: 'text.dark',
          backgroundColor: 'headerBackground.light',
          alignContent: 'center',
        }}
      >
        {title}
      </Box>
      <Box sx={{ minHeight: '3px', width: '100%', backgroundColor: 'secondary.main', alignSelf: 'center' }} />
      {children}
    </Box>
  )
}

export const HeaderBox = ({ children, sx, ...otherProps }) => {
  return <Box sx={{ ...baseCellSx, color: 'text.main', textAlign: 'right',  ...sx }} {...otherProps}>{children}</Box>
}

export const DisplayBox = ({ children, sx, ...otherProps }) => {
  return <Box sx={{ ...baseCellSx, fontWeight: 'bold', color: 'text.dark', ...sx }} {...otherProps}>{children}</Box>
}

export default TitledBox
