import { Button } from '@mui/material'

const ButtonLink = ({ disabled, label, sx, to }) => (
  <Button
    component='a'
    href={to}
    target='_blank'
    color='secondary'
    disabled={disabled}
    size='small'
    sx={{
      border: 'none',
      borderRadius: 0.25,
      height: 23,
      marginTop: 1,
      ...sx
    }}
    variant='contained'
  >
    {label}
  </Button>
)

export default ButtonLink
