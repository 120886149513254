import { useClientContext } from '../clientContext'
import ChargeabilityContainer from '../containers/ChargeabilityContainer'
import Page from '../primitives/Page'
import PageNotFound from './PageNotFound'

// wraps content in page frame
const ChargeabilityPage = () => {
  const { chargeabilityEnabled } = useClientContext()
  if (!chargeabilityEnabled) { return <PageNotFound /> }
  return <Page maxWidth="xl" >
    <ChargeabilityContainer/>
  </Page>
}

export default ChargeabilityPage
