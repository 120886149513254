import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'

import {
  reset,
  resetUpdateError,
  updateProject,
  selectLoadingState,
  selectProject,
  selectUpdateError as selectUpdateProjectError,
  selectUpdateState as selectUpdateProjectState,
} from '../slices/project'

import colours from '../styles/colours'
import LoadingState from '../utils/LoadingState'
import { sanitise } from '../utils/moneyEditUtils'
import { validateBudget } from '../utils/validation'

import DialogTitleWithClose from '../views/DialogTitleWithClose'
import SaveEditsControl from '../views/SaveEditsControl'

const ProjectEditBudgetDialog = ({ onClose, open }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const loadingState = useSelector(selectLoadingState)
  const project = useSelector(selectProject)
  const updateError = useSelector(selectUpdateProjectError)
  const updateState = useSelector(selectUpdateProjectState)

  const [initialBudget, setInitialBudget] = useState(0)

  const updateInitialBudget = useCallback(
    (value) => {
      setInitialBudget(value)
      dispatch(resetUpdateError())
    },
    [dispatch, setInitialBudget]
  )

  const handleChangeInitialBudget = useCallback(
    (event) => {
      var newValue = sanitise(event.target.value)
      updateInitialBudget(newValue)
    },
    [updateInitialBudget]
  )

  const saveProject = useCallback(() => {
    dispatch(
      updateProject({
        project: { ...project, initialBudget: Number(initialBudget) },
        getTokenCallback,
      })
    )
  }, [dispatch, project, initialBudget, getTokenCallback])

  // update purchase orders when project value changes
  useEffect(() => {
    if (
      loadingState === LoadingState.fulfilled &&
      project &&
      project.initialBudget
    ) {
      updateInitialBudget(project.initialBudget ? project.initialBudget : 0)
    }
  }, [loadingState, project, updateInitialBudget])

  // reset to refresh projects when changes are saved
  useEffect(() => {
    if (updateState === LoadingState.fulfilled) {
      dispatch(reset())
    }
  }, [dispatch, updateState])

  const isEdited = useMemo(
    () =>
      project &&
      (project.initialBudget === undefined ||
        initialBudget.toString() !== project.initialBudget.toString()),
    [initialBudget, project]
  )

  const isValid = useMemo(() => validateBudget(initialBudget), [initialBudget])

  if (!project) {
    return null
  } else {
    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitleWithClose onClose={onClose} title="Budget" />
        <DialogContent>
          <TextField
            error={!isValid}
            fullWidth
            id="initial-budget"
            label="Initial Budget"
            onChange={handleChangeInitialBudget}
            value={initialBudget}
            helperText={!isValid ? 'Please enter a valid budget' : ''}
            style={isEdited ? { backgroundColor: colours.awaLightYellow } : {}}
            sx={{ display: 'block', mt: 1, width: 200 }}
            variant='standard'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={!isValid ? { color: 'error.main' } : {}}>$</Typography>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <SaveEditsControl
            isEdited={isEdited}
            isValid={isValid}
            loadingState={loadingState}
            onReset={() => updateInitialBudget(0)}
            onSave={saveProject}
            updateError={updateError}
            updateState={updateState}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default ProjectEditBudgetDialog
