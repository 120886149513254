import { Link } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { useClientContext } from '../clientContext'
import routes from '../utils/routes'
import { useMemo } from 'react'
import LoadingState from '../utils/LoadingState'
import SelectorDropdown from '../primitives/SelectorDropdown'

export function PurchaseOrderSelector({
  purchaseOrder,
  onChangePurchaseOrder,
  disabled,
  projectId,
  project,
  projectLoadingState,
}) {
  const { purchaseOrdersEnabled } = useClientContext()

  // TODO - refactor purchase order logic out of this component
  const purchaseOrders = useMemo(() => {
    if (projectLoadingState === LoadingState.fulfilled && project) {
      return project.purchaseOrders
    } else {
      return null
    }
  }, [projectLoadingState, project])

  if (!purchaseOrdersEnabled) {
    return null
  }

  const mappedPurchaseOrders = purchaseOrders?.map((po) => ({ children: po, value: po }))

  return (
    <Box minWidth="100%">
      {!purchaseOrders || purchaseOrders.length === 0 ? (
        disabled ? (
          'None'
        ) : (
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'editable.main',
              display: 'flex-inline',
              maxWidth: 'fit-content',
              p: 1,
            }}
          >
            <Typography fontSize="9pt">
              {"This project has no PO. "}
              <Link
                sx={{
                  color: 'success.dark',
                  textDecoration: 'none',
                  '&:visited': { color: 'success.dark' },
                }}
                to={`${routes.projectDetails}?projectId=${projectId}`}
              >
                Click to add one.
              </Link>
            </Typography>
          </Box>
        )
      ) : (
        <SelectorDropdown
          label="Purchase Order"
          onChange={(event) => onChangePurchaseOrder(event.target.value)}
          options={mappedPurchaseOrders}
          value={purchaseOrder || ''}
          sx={{ maxWidth: '100%', minWidth: '250px', mb: 0 }}
          selectSx={{ fontWeight: 'normal', fontSize: 13 }}
          disabled={purchaseOrders?.length === 1 || disabled}
        />
      )}
    </Box>
  )
}
