import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NumberParam, useQueryParam } from 'use-query-params'
import { Box } from '@mui/material'

import PageNotFound from './PageNotFound'
import { useClientContext } from '../clientContext'

import PeriodSelector from '../containers/PeriodSelector'
import ProjectSelector from '../containers/ProjectSelector'
import TimeCostReportContainer from '../containers/TimeCostReportContainer'

import Page from '../primitives/Page'
import PageTitle from '../primitives/PageTitle'

import { selectCurrentAndPastPeriods, selectLoadingState as selectPeriodsLoadingState } from '../slices/periods'
import { selectLoadingState as selectProjectsLoadingState, selectProjectsMap } from '../slices/projects'
import resetProjectState from '../slices/resetProjectState'

import LoadingState from '../utils/LoadingState'
import { getPrettyName } from '../utils/periodUtils'

const TimeCostReportPageContentContainer = () => {
  const dispatch = useDispatch()

  const periods = useSelector(selectCurrentAndPastPeriods)
  const projects = useSelector(selectProjectsMap)
  const periodsLoadingState = useSelector(selectPeriodsLoadingState)
  const projectsLoadingState = useSelector(selectProjectsLoadingState)

  const [periodId] = useQueryParam('periodId', NumberParam)
  const [projectId] = useQueryParam('projectId', NumberParam)

  const handleChangePeriod = useCallback(() => {
    dispatch(resetProjectState())
  }, [dispatch])
  const handleChangeProject = useCallback(() => {
    dispatch(resetProjectState())
  }, [dispatch])

  useEffect(() => {
    if (periodsLoadingState === LoadingState.fulfilled && periods && periodId) {
      if (projectsLoadingState === LoadingState.fulfilled && projects && projectId) {
        document.title = `Time-Cost - ${getPrettyName(periods, periodId)} ${projects[projectId].projectName}`
      } else {
        document.title = `Time-Cost - ${getPrettyName(periods, periodId)}`
      }
    } else {
      document.title = 'Time-Cost Report'
    }
  }, [periodsLoadingState, projectsLoadingState, periods, projects, periodId, projectId])

  useEffect(() => {
    if (!projectId) {
      dispatch(resetProjectState())
    }
  }, [dispatch, projectId])

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap='wrap' gap={2}>
        <ProjectSelector
          onChange={handleChangeProject}
          hideSpinner
          sx={{
            float: 'left',
            maxWidth: 700,
          }}
        />
        <Box sx={{ mt: '4.5px' }}>
          {/* Included to account for weird css on period selector - TODO investigate */}
          <PeriodSelector onChange={handleChangePeriod} />
        </Box>
      </Box>
      {projectsLoadingState === LoadingState.fulfilled && periodsLoadingState === LoadingState.fulfilled && periodId ? (
        <Box sx={{ clear: 'both', display: 'block', pt: 1 }}>
          <TimeCostReportContainer periodId={periodId} projectId={projectId} />
        </Box>
      ) : null}
    </>
  )
}

const TimeCostReportPage = () => {
  const { timeCostReportEnabled } = useClientContext()
  if (!timeCostReportEnabled) {
    return <PageNotFound />
  }
  return (
    <Page>
      <PageTitle>Time-Cost Report</PageTitle>
      <TimeCostReportPageContentContainer />
    </Page>
  )
}

export default TimeCostReportPage
