import CategorySlider from '../primitives/CategorySlider'

const ChargeabilityResourceLevelSlider = ({ resourceLevel, onChange, sx }) => {
  const sliderValues = [
    { value: 0, label: 'PERSON' },
    { value: 1, label: 'TEAM' },
    { value: 2, label: 'GROUP' },
  ]

  return (
    <CategorySlider
      sliderValues={sliderValues}
      value={resourceLevel}
      name={'Group level slider'}
      onChange={onChange}
      sx={sx}
    />
  )
}

export default ChargeabilityResourceLevelSlider
