import { useCallback, useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParam, NumberParam } from 'use-query-params'

import CompanyWorkbookContainer from '../containers/CompanyWorkbookContainer'
import CompanyWorkbookStepController from '../containers/CompanyWorkbookStepController'
import PeriodSelector from '../containers/PeriodSelector'

import Loading from '../primitives/Loading'
import Page from '../primitives/Page'
import PageTitle from '../primitives/PageTitle'

import {
  reset,
  selectAreAnyLinesEdited,
  selectValidWorkbook,
} from '../slices/companyWorkbook'
import {
  selectCurrentAndPastPeriods,
  selectLoadingState as selectPeriodsLoadingState,
} from '../slices/periods'

import LoadingState from '../utils/LoadingState'
import { getPrettyName } from '../utils/periodUtils'
import routes from '../utils/routes'

import CompanyWorkbookStepper from '../views/CompanyWorkbookStepper'
import { useClientContext } from '../clientContext'

// handles data validation, renders content
const CompanyWorkbookPageContent = ({ periodId, userIsOps }) => {
  if (periodId === -1) {
    return <Loading />
  } else {
    return (
      <>
        <CompanyWorkbookContainer isOps={userIsOps} periodId={periodId} />
        <CompanyWorkbookStepper />
        {userIsOps ? (
          <CompanyWorkbookStepController periodId={periodId} />
        ) : null}
      </>
    )
  }
}

const CompanyWorkbookPage = () => {
  const { isOperations } = useClientContext()
  const dispatch = useDispatch()

  const loadingState = useSelector(selectPeriodsLoadingState)
  const periods = useSelector(selectCurrentAndPastPeriods)
  const [periodId] = useQueryParam('periodId', NumberParam)

  const anyEdits = useSelector(selectAreAnyLinesEdited)
  const workbook = useSelector(selectValidWorkbook)

  const handleChangePeriod = useCallback(() => {
    // the period may only change ephemerally if the user
    // selects 'Cancel' when they are prompted
    if (!anyEdits) {
      dispatch(reset())
    }
  }, [anyEdits, dispatch])

  useEffect(() => {
    if (loadingState === LoadingState.fulfilled && periods && periodId) {
      document.title = `Company Workbook - ${getPrettyName(periods, periodId)}`
    } else {
      document.title = 'Company Workbook'
    }
  }, [loadingState, periods, periodId])

  useEffect(() => {
    // this correctly resets in the event that there are edits
    // (and handleChangePeriod doesn't reset) and the period changes
    if (workbook && workbook.invoicingPeriod !== periodId) {
      dispatch(reset())
    }
  }, [dispatch, periodId, workbook])

  return (
    <Page>
      <PageTitle>Company Workbook</PageTitle>
      <Prompt
        when={anyEdits}
        message={(location) => {
          const queryParams = new URLSearchParams(location.search)
          if (
            location.pathname.includes(routes.companyWorkbook) &&
            queryParams.get('periodId') === periodId.toString()
          ) {
            return true // allow user to leave page (if only changing CWB view params)
          }

          return 'You have unsaved changes. Are you sure you want to leave?'
        }}
      />
      <PeriodSelector onChange={handleChangePeriod} />
      {loadingState === LoadingState.fulfilled && periodId ? (
        <CompanyWorkbookPageContent
          periodId={periodId}
          userIsOps={isOperations}
        />
      ) : null}
    </Page>
  )
}

export default CompanyWorkbookPage
