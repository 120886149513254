import workbookStatus from './workbookStatus'

// These names don't map to the lookup_codes database and are purely for tracking the stepper state
const workbookFrontendStatus = {
  notInvoicing: 'notInvoicing',
  building: 'building',
  preparation: 'preparation',
  invoicing: 'invoicing',
  reviewing: 'reviewing',
  reconciling: 'reconciling',
  closed: 'closed',
}

/**
 * Gets all workbook steps including their relation to surrounding steps, the associated status
 * @param {bool} includeReviewingStep - True to include the 'Reviewing' stage, false to exclude it.
 * @returns {Array{*}} - Array consisting of workbook steps
 */
function getWorkbookSteps({ includeReviewingStep = false }) {
  const steps = [
    {
      // Status specific to frontend - not tied to database implementation. This should be used rather than status in general use
      frontendStatus: workbookFrontendStatus.notInvoicing, // CONSIDER RENAMING THESE TO APPROPRIATE TERMS TEMP TODO REMOVE
      // Associated with this workbook status in the DB. This should only be used to associate a status from the backend to an ID
      databaseStatus: workbookStatus.doesNotExist,
      // Used by stepper to order the steps. Populated when step array has been created and filtered
      index: undefined,
      // Label displayed on the stepper
      label: 'Not Invoicing',
      testId: 'notInvoicing',
      // There is no backward functionality from this step
      backAction: undefined,
      forwardAction: {
        newFrontendStatus: workbookFrontendStatus.building, // ID of the next stage that the forward button will transition to
        buttonLabel: 'Generate',
      },
    },
    {
      frontendStatus: workbookFrontendStatus.building,
      databaseStatus: workbookStatus.building,
      label: 'Generating',
      testId: 'generating',
      backAction: undefined,
      forwardAction: undefined, // Cannot transition forward or backward from this state
    },
    {
      frontendStatus: workbookFrontendStatus.preparation,
      databaseStatus: workbookStatus.review, // Yes this is confusing; it's the old terminology
      label: 'Preparing',
      testId: 'preparing',
      backAction: {
        newFrontendStatus: workbookFrontendStatus.building,
        buttonLabel: 'Regenerate',
      },
      forwardAction: {
        newFrontendStatus: workbookFrontendStatus.invoicing,
        buttonLabel: 'Open',
      },
    },
    {
      frontendStatus: workbookFrontendStatus.invoicing,
      databaseStatus: workbookStatus.active,
      label: 'Invoicing',
      testId: 'invoicing',
      backAction: {
        newFrontendStatus: workbookFrontendStatus.preparation,
        buttonLabel: undefined,
      },
      forwardAction:
        includeReviewingStep === true
          ? {
              newFrontendStatus: workbookFrontendStatus.reviewing,
              buttonLabel: undefined,
            }
          : {
              newFrontendStatus: workbookFrontendStatus.reconciling,
              buttonLabel: undefined,
            },
    },
    includeReviewingStep && {
      frontendStatus: workbookFrontendStatus.reviewing,
      databaseStatus: workbookStatus.pdReview,
      label: 'Reviewing',
      testId: 'reviewing',
      backAction: {
        newFrontendStatus: workbookFrontendStatus.invoicing,
        buttonLabel: undefined,
      },
      forwardAction: {
        newFrontendStatus: workbookFrontendStatus.reconciling,
        buttonLabel: undefined,
      },
    },
    {
      frontendStatus: workbookFrontendStatus.reconciling,
      databaseStatus: workbookStatus.reconciliation,
      label: 'Reconciling',
      testId: 'reconciling',
      backAction:
        includeReviewingStep === true
          ? {
              newFrontendStatus: workbookFrontendStatus.reviewing,
              buttonLabel: undefined,
            }
          : {
              newFrontendStatus: workbookFrontendStatus.invoicing,
              buttonLabel: undefined,
            },
      forwardAction: {
        newFrontendStatus: workbookFrontendStatus.closed,
        buttonLabel: 'Close',
      },
    },
    {
      frontendStatus: workbookFrontendStatus.closed,
      databaseStatus: workbookStatus.finalised,
      label: 'Closed',
      testId: 'closed',
      backAction: {
        newFrontendStatus: workbookFrontendStatus.reconciling,
        buttonLabel: 'Reopen',
      },
      forwardAction: undefined,
    },
  ].filter(Boolean) // This filters out any falsy values, which can occur if includeReviewingStep is false

  // From the filtered steps, set each an index. This is used when displaying and indexing the steps on the UI
  // stepper
  steps.forEach((step, index) => (step.index = index))

  return steps
}

/**
 * Find the associated step object which is associated with the given db workbook status
 * @param {*} stepMap steps provided from getStepsWithMetadata()
 * @param {*} databaseStatus workbook status (from database) for fetch the step object for. The first step with this value as
 * '.databaseStatus' will be returned
 * @returns step associated with db workbook status, notInvoicing step if none are found, or null if no step map is provided
 */
export function getStepFromWorkbookDatabaseStatus(stepMap, databaseStatus) {
  // No additional processing can occur if the step map is empty
  if (stepMap == null) {
    return null
  }

  const foundStep = stepMap.find(
    (step) => step.databaseStatus === databaseStatus
  )

  // If the step is found, return that, otherwise return 'notInvoicing' step (should always be first step)
  return foundStep || stepMap[0]

  // This code is likely redundant - TEMP TODO REMOVE
  //   var stepNum = activeStepObj.id

  //   if (stepNum === stepMap.length - 1) {
  //     stepNum = stepMap.length
  //   }
  //   return stepNum
}

/**
 * Find the associated step object which is associated with the given frontend workbook status
 * @param {*} stepMap all workbook steps
 * @param {number} databaseStatus workbook status (from frontend 'workbookFrontendStatus') for fetch the step object for.
 * @returns step associated with frontend workbook status, or the notInvoicing step if none are found
 */
export function getStepFromFrontendWorkbookStatus(stepMap, frontendStatus) {
  const foundStep = stepMap.find(
    (step) => step.frontendStatus === frontendStatus
  )
  // If the step is found, return that, otherwise return 'notInvoicing' step (should always be first step)
  return foundStep || stepMap[0]
}

/**
 * Converts a workbook frontend status to the equivalent workbook database status
 * @param {*} stepMap all workbook steps
 * @param {string} frontendStatus frontend status to convert to a database status
 * @returns equivalent database status, or null if none are defined
 */
export function convertWorkbookFrontendStatusToDatabaseStatus(
  stepMap,
  frontendStatus
) {
  const step = getStepFromFrontendWorkbookStatus(stepMap, frontendStatus)
  const databaseStatus = step.databaseStatus
  return databaseStatus
}

export { getWorkbookSteps, workbookFrontendStatus }
