import { useCallback } from 'react'
import StringListEditorItemControl from './StringListEditorItemControl'

const StringListEditor = ({ disabled, originalList, list, onChange }) => {

  const handleChangeValue = useCallback(
    (idx, value) => {
      var newList = [...list]
      newList[idx] = value
      onChange(newList)
    },
    [list, onChange]
  )

  const handleDeleteValue = useCallback(
    (idx) => {
      var newList = [...list]
      newList.splice(idx, 1)
      onChange(newList)
    },
    [list, onChange]
  )

  return (
    <div style={{ clear: 'both', width: 380 }}>
      {list.map((value, idx) => (
        <StringListEditorItemControl
          key={idx}
          id={idx}
          disabled={disabled}
          originalValue={
            originalList && originalList.length > idx
              ? originalList[idx]
              : null
          }
          value={value}
          onChange={(value) => handleChangeValue(idx, value)}
          onDelete={() => handleDeleteValue(idx)}
        />
      ))}
    </div>
  )
}

export default StringListEditor
