import { useCallback, useEffect, useRef, useState } from 'react'

import {
  Box,
  ClickAwayListener,
  Input,
  InputAdornment,
  TableCell,
} from '@mui/material'

import colours from '../styles/colours'

const MoneyInput = ({ id, onChange, value }) => {
  const ref = useRef()

  useEffect(() => {
    // put cursor into element on component mount.
    if (ref.current) {
      ref.current.focus()
    }
  }, [])

  return (
    <Input
      // className={classes.input}
      onChange={onChange}
      value={value}
      sx={{
        fontSize: 'inherit',
        height: 23,
        p: 0,
        pl: 1,
        width: 120,
      }}
      startAdornment={
        <InputAdornment position="start">
          <span style={{ color: colours.awaGray75, fontSize: 16 }}>$</span>
        </InputAdornment>
      }
      inputRef={ref}
      inputProps={{
        'data-testid': `${id}-money-input`,
      }}
    />
  )
}

const InvoiceMoneyEditCell = ({ displayValue, id, onChange, value }) => {

  const [editing, setEditing] = useState(false)

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') { setEditing(false) }
  }, [setEditing])

  return (
    <ClickAwayListener onClickAway={() => setEditing(false)}>
      <TableCell
        onClick={() => setEditing(true)}
        onKeyDown={handleKeyDown}
        sx={{
          backgroundColor: 'primaryHighlight.main',
          color: 'inherit',
          padding: 0,
          fontWeight: 'inherit',
          fontSize: 11,
          textAlign: 'right',
          height: 25,
        }}
        data-testid={`${id}-money-cell`}
      >
        {editing ? (
          <MoneyInput id={id} onChange={onChange} value={value} />
        ) : (
          <Box component='span' sx={{ px: 1, py: 0.5 }} >{displayValue}</Box>
        )}
      </TableCell>
    </ClickAwayListener>
  )
}

export default InvoiceMoneyEditCell
