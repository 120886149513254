
import { useSelector } from 'react-redux'
import { CircularProgress, Typography } from '@mui/material'

import Button from '../primitives/Button'
import {
  selectAreAnyLinesEdited,
  selectSaveEditsState,
} from '../slices/companyWorkbook'
import LoadingState from '../utils/LoadingState'

const CompanyWorkbookSubmissionControls = ({ onSave }) => {
  const areAnyLinesEdited = useSelector(selectAreAnyLinesEdited)
  const savingState = useSelector(selectSaveEditsState)

  if (savingState === LoadingState.pending) {
    return <CircularProgress sx={{ ml: 1 }} />
  } else {
    return (
      <>
        <Button
          disabled={!areAnyLinesEdited}
          label="Save"
          onClick={onSave}
          sx={{ m: 1, width: 120 }}
        />
        {savingState === LoadingState.rejected ? (
          <Typography sx={{ color: 'error.main', width: 400 }}>
            Failed to save edits. Please try again.
          </Typography>
        ) : null}
        {savingState === LoadingState.fulfilled ? (
          <Typography sx={{ color: 'success.main', width: 400 }}>
            Saved successfully!
          </Typography>
        ) : null}
      </>
    )
  }
}

export default CompanyWorkbookSubmissionControls
