import { useMemo } from 'react'

import SaveAndResetButtons from './SaveAndResetButtons'
import LoadingState from '../utils/LoadingState'

const SaveEditsControl = ({
  isEdited,
  isValid,
  loadingState,
  onReset,
  onSave,
  updateError,
  updateState,
}) => {
  const loadingOrSaving = useMemo(() => {
    return (
      loadingState === LoadingState.pending ||
      updateState === LoadingState.pending
    )
  }, [loadingState, updateState])

  const resetDisabled = useMemo(() => {
    return loadingOrSaving || !isEdited
  }, [loadingOrSaving, isEdited])

  const saveDisabled = useMemo(() => {
    return loadingOrSaving || !isEdited || !isValid
  }, [loadingOrSaving, isEdited, isValid])

  return (
    <SaveAndResetButtons
      errorMessage={updateError}
      onReset={onReset}
      onSave={onSave}
      resetDisabled={resetDisabled}
      saveDisabled={saveDisabled}
    />
  )
}

export default SaveEditsControl
