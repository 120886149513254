import { Box } from '@mui/material'
import { TitledBox, HeaderBox, DisplayBox } from '../primitives/TitledBox'

const InvoiceDetailsTable = ({ clientName, clientContact, invoiceRecipients, border, borderColour }) => {
  
  const isClientNameAndContactTheSame = clientContact?.toLowerCase().includes(clientName?.toLowerCase())

  return (
    <TitledBox
      title="Project Details"
      containerSx={{ width: '100%', maxWidth: '700px', boxShadow: 1, border, borderColor: borderColour }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          p: 0.5,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box flex={1} display="flex" flexDirection="row">
          <HeaderBox>Client</HeaderBox>
          <DisplayBox>{(isClientNameAndContactTheSame ? clientContact : clientName) ?? '-' }</DisplayBox>
        </Box>

        {isClientNameAndContactTheSame ? null : (
          <Box flex={1} display="flex" flexDirection="row">
            <HeaderBox>Project Contact</HeaderBox>
            <DisplayBox>{clientContact ?? '-'}</DisplayBox>
          </Box>
        )}

        <Box flex={1} display="flex" flexDirection="row">
          <HeaderBox>Invoice Recipients</HeaderBox>
          <DisplayBox>
            {invoiceRecipients
              ? invoiceRecipients.map((email, idx) => (
                  <span key={idx}>
                    {email}
                    <br />
                  </span>
                ))
              : '-'}
          </DisplayBox>
        </Box>
      </Box>
    </TitledBox>
  )
}

export default InvoiceDetailsTable
