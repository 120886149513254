import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material'

import MissingRateIcon from '../primitives/MissingRateIcon'
import colours from '../styles/colours'

const MissingRatesList = ({ missingRates, onClick }) => {
  if (!missingRates || missingRates.length === 0) {
    return null
  }

  return (
    <Box
      sx={{
        clear: 'both',
        float: 'left',
        m: 1,
        width: 230,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          backgroundColor: colours.awaGrayUltralight,
          pt: 1,
          px: 2,
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 'bold',
            p: 1,
            textAlign: 'center',
          }}
        >
          Missing Rates
        </Typography>
        <Divider />
        <List dense>
          {missingRates.map((missingRate, idx) => (
            <ListItem key={idx} sx={{ p: 0 }}>
              <ListItemIcon
                sx={{
                  minWidth: 'auto',
                  pr: 1,
                }}
              >
                <SvgIcon
                  sx={{
                    color:
                      missingRate.earliestEffectiveDate == null
                        ? 'error.main'
                        : 'warning.main',
                    height: 28,
                  }}
                >
                  <MissingRateIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={missingRate.name}
                secondary={`${
                  missingRate.earliestEffectiveDate == null
                    ? 'No rate'
                    : 'Problem rate'
                } - ${missingRate.hours.toLocaleString('en-NZ', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })} hours`}
                sx={{
                  '& .MuiListItemText-primary': {
                    fontSize: 12,
                    fontWeight: 'bold',
                  },
                  '& .MuiListItemText-secondary': {
                    fontSize: 12,
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Button
        color="primary"
        onClick={onClick}
        size="small"
        sx={{
          border: 'none',
          borderRadius: 1,
          height: 23,
          mt: 1,
          width: '100%',
        }}
        variant="contained"
      >
        Assign Missing Rates
      </Button>
    </Box>
  )
}

export default MissingRatesList
