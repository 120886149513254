import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { Provider as ReduxProvider } from 'react-redux'
import { Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import MomentUtils from '@date-io/moment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LicenseInfo } from '@mui/x-license-pro'

import App from './App'
import config from './config'
import store from './store'
import history from './utils/history'

LicenseInfo.setLicenseKey(config.muixproLicenseKey)

const gtmId = config.googleTagManagerId
if (gtmId) {
  TagManager.initialize({ gtmId })
}

ReactDOM.render(
  <ReduxProvider store={store}>
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <LocalizationProvider utils={MomentUtils}>
          <App />
        </LocalizationProvider>
      </QueryParamProvider>
    </Router>
  </ReduxProvider>,
  document.getElementById('root')
)
