import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { Dialog, DialogActions, DialogContent, TextField } from '@mui/material'

import {
  reset,
  resetUpdateError,
  updateProject,
  selectLoadingState,
  selectProject,
  selectUpdateError as selectUpdateProjectError,
  selectUpdateState as selectUpdateProjectState,
} from '../slices/project'

import LoadingState from '../utils/LoadingState'
import DialogTitleWithClose from '../views/DialogTitleWithClose'
import SaveEditsControl from '../views/SaveEditsControl'

const ProjectClientContactDialog = ({ onClose, open }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const loadingState = useSelector(selectLoadingState)
  const project = useSelector(selectProject)
  const updateError = useSelector(selectUpdateProjectError)
  const updateState = useSelector(selectUpdateProjectState)

  const [clientContact, setClientContact] = useState('')

  const handleChangeClientContact = useCallback(
    (event) => {
      setClientContact(event.target.value)
      dispatch(resetUpdateError())
    },
    [dispatch, setClientContact]
  )

  const saveProject = useCallback(() => {
    dispatch(
      updateProject({
        project: { ...project, clientContact },
        getTokenCallback,
      })
    )
  }, [dispatch, project, clientContact, getTokenCallback])

  // update project values when project changes
  useEffect(() => {
    if (
      loadingState === LoadingState.fulfilled &&
      project &&
      !clientContact
    ) {
      setClientContact(project.clientContact || '')
      dispatch(resetUpdateError())
    }
  }, [dispatch, loadingState, project, clientContact])

  // reset to refresh projects when changes are saved
  useEffect(() => {
    if (updateState === LoadingState.fulfilled) {
      dispatch(reset())
    }
  }, [dispatch, updateState])

  const isEdited = useMemo(
    () => project && clientContact !== project.clientContact,
    [project, clientContact]
  )

  const isValid = true

  if (!project) {
    return null
  } else {
    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitleWithClose onClose={onClose} title="Contact Details" />
        <DialogContent>
          <TextField
            error={!isValid}
            fullWidth
            id="client-contact"
            label="Client Contact Name"
            onChange={handleChangeClientContact}
            sx={{ display: 'block', mt: 1, width: 250 }}
            value={clientContact}
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <SaveEditsControl
            isEdited={isEdited}
            isValid={true}
            loadingState={loadingState}
            onReset={() => setClientContact(null)}
            onSave={saveProject}
            updateError={updateError}
            updateState={updateState}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default ProjectClientContactDialog
