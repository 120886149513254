import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Input, InputAdornment } from '@mui/material'
import colours from '../styles/colours'

function validatePercent(value) {
  const integerVal = parseInt(value, 10)
  const numberVal = Number(value)
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (!isNaN(integerVal) &&
      !isNaN(numberVal) &&
      integerVal === numberVal &&
      integerVal >= 0 &&
      integerVal <= 100)
  )
}

const PercentEditCell = ({ id, value, api, field }) => {
  const ref = useRef()
  const [error, setError] = useState(!validatePercent(value))

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value
      var isValid = validatePercent(value)
      setError(!isValid)

      const editProps = {
        value: event.target.value,
        error: !isValid,
      }
      api.unstable_setEditCellProps({ id, field, props: editProps })
    },
    [api, field, id]
  )

  useEffect(() => {
    // put cursor into element on component mount.
    if (ref.current) {
      ref.current.focus()
    }
  }, [])

  return (
    <Box
      data-testid="percent-edit-cell"
      sx={{
        alignItems: 'center',
        backgroundColor: error ? colours.lightRed : null,
        color: error ? colours.darkRed : null,
        display: 'flex',
        px: 1,
      }}
    >
      <Input
        inputRef={ref}
        value={value !== null ? value : ''}
        onChange={handleChange}
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
      />
    </Box>
  )
}

export default PercentEditCell
