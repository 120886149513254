import { Typography } from '@mui/material'

const PageTitle = ({ children }) => (
  <Typography
    mb={2.5}
    textTransform="uppercase"
    variant="h5"
  >
    {children}
  </Typography>
)

export default PageTitle
