import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '../utils/api'
import LoadingState from '../utils/LoadingState'

const initialState = {
  loadingState: LoadingState.idle,
  data: null,
}

export const fetchEmployees = createAsyncThunk('fetchEmployees', async ({ getTokenCallback }, { getState }) => {
  const endpoint = api.endpoints(getState().client.name).people.currentEmployees()
  const employeeData = await api.getData(endpoint, getTokenCallback)
  return employeeData
})

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
  },
  extraReducers: {
    [fetchEmployees.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchEmployees.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error
    },
    [fetchEmployees.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload.startDates
    },
  },
})

export const { reset } = slice.actions

export const selectEmployees = (state) =>
  (state.employees.loadingState === LoadingState.fulfilled && state.employees.data) || null

export const selectLoadingState = (state) => state.employees.loadingState

export default slice.reducer
