import { Typography } from '@mui/material'
import colours from '../styles/colours'

const Note = ({ children, ...props }) => (
  <Typography
    color={colours.awaGray75}
    fontStyle='italic'
    {...props}
  >
    {children}
  </Typography>
)

export default Note
