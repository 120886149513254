import { useEffect, useRef, useState } from 'react'
import { Box, Paper, Popper, Typography } from '@mui/material'

const isOverflown = (element) => (
  element.scrollHeight > element.clientHeight ||
  element.scrollWidth > element.clientWidth
)

const rootSx = {
  alignItems: 'center',
  lineHeight: 24,
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  '& .cellValue': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .bold': {
    fontWeight: 'bold',
  },
  'a': {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: 'secondary.main',
      textDecoration: 'underline',
    },
  }
}

const GridCellExpand = ({ width, value, linkUrl }) => {
  const wrapper = useRef(null)
  const cellDiv = useRef(null)
  const cellValue = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showFullCell, setShowFullCell] = useState(false)
  const [showPopper, setShowPopper] = useState(false)

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current)
    setShowPopper(isCurrentlyOverflown)
    setAnchorEl(cellDiv.current)
    setShowFullCell(true)
  }

  const handleMouseLeave = () => {
    setShowFullCell(false)
  }

  useEffect(() => {
    if (!showFullCell) {
      return undefined
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [setShowFullCell, showFullCell])

  const innerText = linkUrl
    ? <a href={linkUrl} target="_blank" rel="noreferrer">{value}</a>
    : value

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={wrapper}
      sx={rootSx}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {innerText}
      </div>
      {showPopper && (
        <Popper
          placement="bottom-start"
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
        >
          <Paper elevation={1}>
            <Typography variant="body2">
              {innerText}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  )
}

export default GridCellExpand
