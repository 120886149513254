import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '../utils/api'
import LoadingState from '../utils/LoadingState'

const initialState = {
  loadingState: LoadingState.idle,
  data: null,
}

export const fetchGroups = createAsyncThunk(
  'fetchGroups',
  async ({ getTokenCallback }, { getState }) => {
    return await api.getData(
      api.endpoints(getState().client.name).groups.all(),
      getTokenCallback
    )
  }
)

const slice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
  },
  extraReducers: {
    [fetchGroups.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchGroups.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error
    },
    [fetchGroups.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload.groups
    },
  },
})

export const { reset } = slice.actions

export const selectGroups = (state) =>
  state.groups.loadingState === LoadingState.fulfilled && state.groups.data
    ? state.groups.data
    : null
export const selectLoadingState = (state) => state.groups.loadingState

export default slice.reducer
