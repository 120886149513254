import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '../utils/api'
import LoadingState from '../utils/LoadingState'

const initialState = {
  loadingState: LoadingState.idle,
  data: [{}],
}

export const fetchInvoicingApproaches = createAsyncThunk(
  'fetchInvoicingApproaches',
  async ({ getTokenCallback }, { getState }) => {
    return await api.getData(
      api
        .endpoints(getState().client.name)
        .companyWorkbooks.getInvoicingApproaches(),
      getTokenCallback
    )
  }
)

const slice = createSlice({
  name: 'invoicingApproaches',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchInvoicingApproaches.pending]: (state) => {
      state.loadingState = LoadingState.pending
    },
    [fetchInvoicingApproaches.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error
    },
    [fetchInvoicingApproaches.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload
    },
  },
})

export const { reset } = slice.actions

export const selectLoadingState = (state) =>
  state.invoicingApproaches.loadingState

/**
 * Transforms data returned from the API as an array of lookup codes
 * into a format ready for consumption
 * @param {*} lookup Array of lookup code objects with {id, code, description}
 * @returns Object containing a valueMap object that maps lookup code IDs to display values,
 * a tooltipMap object that maps lookup code IDs to display tooltips, and
 * a valueOptions array that lists options in a format required by the MUI SingleSelectCell component.
 */
const transformLookup = (lookup = []) => {
  const valueMap = {},
    tooltipMap = {},
    valueOptions = []
  lookup.forEach((l) => {
    valueMap[l.id] = l.code
    tooltipMap[l.id] = l.description
    valueOptions.push({ value: l.id, label: l.code })
  })
  return { valueMap, tooltipMap, valueOptions }
}

export const selectInvoicingApproaches = (state) =>
  transformLookup(state.invoicingApproaches.data)

export default slice.reducer
