export const userHasRole = (user = {}, namespace = '', roles) => {
  const userRoles = user[`${namespace}/roles`] || []
  if (typeof roles === "string") { return userRoles.includes(roles) }
  if (Array.isArray(roles)) { return roles.any(role => userRoles.includes(role)) }
  return false
}

const getViewerTypeFilter = (viewerTypes, viewerTypesFromConfig, userName) =>
  ({ projectManager, projectDirector, clientManager }) =>
    (viewerTypes.length === 0)
    || (viewerTypes.includes(viewerTypesFromConfig.projectManager)
      && projectManager === userName)
    || (viewerTypes.includes(viewerTypesFromConfig.projectDirector)
      && projectDirector === userName)
    || (viewerTypes.includes(viewerTypesFromConfig.clientManager)
      && clientManager === userName)

export const filterCompanyWorkbookLinesByUser = ({
  user,
  workbookLines,
  viewerTypes = [],
  completionStatusFilter = () => true,
  presetViewFilter = () => true,
  myTeam = [],
  myTeamOnly = false,
  isLeadership,
  isOperations,
  viewerTypesFromConfig,
}) => {
  // if no user, then return nothing
  if (!user) { return [] }

  const myTeamFilter = (line) => myTeamOnly
    ? (
      myTeam.includes(line.projectManager)
      || myTeam.includes(line.projectDirector)
      || myTeam.includes(line.clientManager)
    )
    : () => { }

  const viewerTypeFilter = getViewerTypeFilter(viewerTypes, viewerTypesFromConfig, user.name)

  // if either myTeam is selected or any viewerType has been selected
  // then apply the myTeam and viewerType filters
  // else (if leadership or ops, return everything, else return nothing)
  const roleBasedFilter = (myTeamOnly || viewerTypes.length > 0)
    ? (line) => (myTeamFilter(line) && viewerTypeFilter(line))
    : () => (isLeadership || isOperations)

  const lineFilter = (line) => (
    roleBasedFilter(line)
    && completionStatusFilter(line)
    && presetViewFilter(line)
  )

  return workbookLines.filter(lineFilter)
}
