import { useCallback, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import colours from '../styles/colours'

const AddLineItemButton = ({ disabled, onAdd, mappingData }) => {

  const [newItemId, setNewItemId] = useState(0)

  const handleClick = useCallback(() => {
    onAdd(newItemId)
    setNewItemId(newItemId + 1)
  }, [onAdd, newItemId])

  useEffect(() => {
    var highestId = 0
    if (mappingData.lineItemIds && mappingData.lineItemIds.length > 0) {
      for (const id of mappingData.lineItemIds) {
        const idValue = Number(id)
        if (idValue > highestId) {
          highestId = idValue
        }
      }
    }
    setNewItemId(highestId + 1)
  }, [mappingData])

  return (
    <Button
      aria-label="add"
      disabled={disabled}
      onClick={handleClick}
      sx={{
        backgroundColor: colours.awaLightGray,
        borderRadius: 1,
        color: colours.muiDefaultBlack,
        float: 'left',
        fontSize: 14,
        mt: 1,
        p: 1,
        textAlign: 'left',
        '&:hover': { backgroundColor: 'secondary.light' },
      }}
    >
      <AddIcon style={{ fontSize: 22 }} />
      Add Contract Line Item
    </Button>
  )
}

export default AddLineItemButton
