import { Checkbox, FormGroup, FormControlLabel } from '@mui/material'

const TotalOnlyCheckbox = ({ checked, onChange, disabled }) => {

  return (
    <FormGroup sx={{
      float: 'right',
      my: 0.5,
      '& .MuiFormControlLabel-root': { m: 0 },
      '& .MuiFormControlLabel-label': { fontSize: 12 },
    }}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            size="small"
            style={{ height: 24, width: 24, padding: 0 }}
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
            disabled={disabled}
          />
        }
        label="Invoice total only"
        labelPlacement="start"
      />
    </FormGroup>
  )
}

export default TotalOnlyCheckbox
