import { Route, Redirect, Switch } from 'react-router-dom'
import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from '@auth0/auth0-react'

import { ClientContextProvider, useClientContext } from './clientContext'
import config from './config'

import ChargeabilityPage from './pages/ChargeabilityPage'
import CompanyWorkbookPage from './pages/CompanyWorkbookPage'
import InvoicingPage from './pages/InvoicingPage'
import PageNotAuthorised from './pages/PageNotAuthorised'
import PageNotFound from './pages/PageNotFound'
import ProjectDetailsPage from './pages/ProjectDetailsPage'
import TimeCostReportPage from './pages/TimeCostReportPage'

import Loading from './primitives/Loading'
import ClientThemeProvider from './ClientThemeProvider'
import routes from './utils/routes'
import AppFrame from './views/AppFrame'
import history from './utils/history'

const onRedirectCallback = ({ returnTo } = {}) => {
  if (returnTo) {
    history.push(returnTo)
  }
}

const auth0Config = (client, path) => ({
  ...config.auth0,
  clientId: config.auth0ClientId[client],
  logoutParams: { returnTo: window.location.origin + path },
  redirectUri: window.location.origin + path,
  onRedirectCallback,
})

export const ClientRoutes = () => {
  const { path, isStaffMember } = useClientContext()
  if (!isStaffMember) {
    return <PageNotAuthorised />
  }

  return (
    <Switch>
      <Redirect
        exact
        from={`${path}/`}
        to={`${path}${routes.companyWorkbook}`}
      />
      <Route
        path={`${path}${routes.chargeability}`}
        component={ChargeabilityPage}
      />
      <Route
        path={`${path}${routes.companyWorkbook}`}
        component={CompanyWorkbookPage}
      />
      <Route path={`${path}${routes.invoicing}`} component={InvoicingPage} />
      <Route
        path={`${path}${routes.projectDetails}`}
        component={ProjectDetailsPage}
      />
      <Route
        path={`${path}${routes.timeCostReport}`}
        component={TimeCostReportPage}
      />
      <Route component={PageNotFound} />
    </Switch>
  )
}

const RouteWithContext = () => {
  const { isLoading, error } = useAuth0()

  return error ? (
    <div>Oops... {error.message}</div>
  ) : isLoading ? (
    <Loading />
  ) : (
    <ClientThemeProvider>
      <AppFrame>
        <ClientRoutes />
      </AppFrame>
    </ClientThemeProvider>
  )
}

const generateRouteWithAuth = (client, path) => {
  const auth0 = auth0Config(client, path)
  const WrappedRoute = withAuthenticationRequired(RouteWithContext, {
    onRedirecting: () => <Loading />,
  })

  const component = () => (
    <Auth0Provider {...auth0}>
      <ClientContextProvider client={client} path={path}>
        <WrappedRoute />
      </ClientContextProvider>
    </Auth0Provider>
  )

  return <Route path={path} component={component} />
}

const App = () => (
  <Switch>
    {generateRouteWithAuth('flip', '/flip')}
    {generateRouteWithAuth('awa', '')}
  </Switch>
)

export default App
