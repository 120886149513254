import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import TeamIcon from '@mui/icons-material/People';

import SelectorButtons from '../primitives/SelectorButtons';
import { selectMyTeamMembers } from '../utils/compositeSelectors';
import LoadingState from '../utils/LoadingState'

import {
  fetchChargeability as fetchChargeabilityAction,
  selectLoadingState as selectChargeabilityLoadingState,
} from '../slices/chargeability'

import {
  fetchGroups as fetchGroupsAction,
  selectLoadingState as selectGroupsLoadingState,
} from '../slices/groups'

const CompanyWorkbookMyTeamSwitch = (buttonProps) => {

  const { getAccessTokenSilently: getTokenCallback, user } = useAuth0()

  const store = useStore()
  const state = store.getState()
  const dispatch = useDispatch()

  const myTeam = selectMyTeamMembers(state, user.name)

  const chargeabilityLoadingState = useSelector(selectChargeabilityLoadingState)
  const fetchChargeability = useCallback(() => {
    dispatch(fetchChargeabilityAction({ getTokenCallback }))
  }, [dispatch, getTokenCallback])

  useEffect(() => {
    if (chargeabilityLoadingState === LoadingState.idle) { fetchChargeability() }
  }, [fetchChargeability, chargeabilityLoadingState])

  const groupsLoadingState = useSelector(selectGroupsLoadingState)
  const fetchGroups = useCallback(() => {
    dispatch(fetchGroupsAction({ getTokenCallback }))
  }, [dispatch, getTokenCallback])

  useEffect(() => {
    if (groupsLoadingState === LoadingState.idle) { fetchGroups() }
  }, [fetchGroups, groupsLoadingState])

  return (
    chargeabilityLoadingState === LoadingState.fulfilled &&
    groupsLoadingState === LoadingState.fulfilled &&
    myTeam.length > 0 &&
    <SelectorButtons
      buttons={[{ children: <TeamIcon />, ...buttonProps }]}
    />
  )

}

export default CompanyWorkbookMyTeamSwitch
