const DEFAULT = "DEFAULT"
const SETUP_PROBLEMS = 'SETUP_PROBLEMS'
const UNFINISHED = 'UNFINISHED'
const PROVISIONS = 'PROVISIONS'
const AGED_DEBT = 'AGED_DEBT'
const HAS_PERIOD_WIP = 'HAS_PERIOD_WIP'
const HAS_TOTAL_WIP = 'HAS_TOTAL_WIP'

const presetViewNames = {
    DEFAULT,
    SETUP_PROBLEMS,
    UNFINISHED,
    PROVISIONS,
    AGED_DEBT,
    HAS_PERIOD_WIP,
    HAS_TOTAL_WIP,
}

const presetViewLabels = {
    [DEFAULT]: 'Default',
    [SETUP_PROBLEMS]: 'Setup Problems',
    [UNFINISHED]: 'Unfinished',
    [PROVISIONS]: 'Provisions',
    [AGED_DEBT]: 'Aged Debt',
    [HAS_PERIOD_WIP]: 'Has Period WIP',
    [HAS_TOTAL_WIP]: 'Has Total WIP',
}

const addSetupProblemFlags = (lines) => {

    const extractedLines = []
    for (const line of lines) {
        const {
            clientManager = "",
            clientName = "",
            projectBudget = 0,
            projectDirector = "",
            projectManager = "",
            projectName = "",
            wipForPeriod,
        } = line
        // flag 1 - missing project rates (wipForPeriod is undefined)
        const missingProjectRates = wipForPeriod === undefined
        // flag 2 - missing budget
        const missingBudget = projectBudget === 0
        // flag 3 - missing key role (client name, client manager, project director, project manager)
        // note that we check string lengths in case the name is already an empty string
        const missingKeyRole = clientName.length === 0 || clientName === 'None'
            || clientManager.length === 0 || clientManager === 'None None'
            || projectDirector.length === 0 || projectDirector === 'None None'
            || projectManager.length === 0 || projectManager === 'None None'
        // flag 4 - missing job number (job number is the default value of 'J000xxx')
        const jobNumber = (projectName.match(/\((.{7,})\)/) || [])[1]
        const missingJobNumber = !jobNumber || (jobNumber === 'J000xxx')
        extractedLines.push({
            ...line,
            jobNumber,
            missingProjectRates,
            missingBudget,
            missingKeyRole,
            missingJobNumber,
            duplicateJobNumber: false,
        })
    }

    // flag 5 - duplicate job number
    // 1) sort by job number
    // 2) if current job number is equal to the job number of the next line, flag both as true
    extractedLines.sort((a, b) => a.jobNumber - b.jobNumber)
    for (let i = 0; i < extractedLines.length - 1; i++) {
        if (extractedLines[i].jobNumber === extractedLines[i + 1].jobNumber) {
            extractedLines[i]['duplicateJobNumber'] = true
            extractedLines[i + 1]['duplicateJobNumber'] = true
        }
    }

    return extractedLines
}

const flagFilter = (line) =>
    line.missingProjectRates
    || line.missingBudget
    || line.missingKeyRole
    || line.missingJobNumber
    || line.duplicateJobNumber

const unactionedInvoiceFilter = ({
    periodExpenses = null,
    invoiceAmount = null,
    expectedVariations = null,
    percentComplete = null,
    provisionalAmount = null,
    invoicingApproach = null,
}) =>
    periodExpenses === null
    || invoiceAmount === null
    || expectedVariations === null
    || percentComplete === null
    || provisionalAmount === null
    || invoicingApproach === null

const provisionedFilter = ({ provisionalAmount = 0 }) =>
    Math.round(provisionalAmount) !== 0

const agedDebtFilter = ({ agedDebt = 0, agedDebt3Months = 0 }) =>
    Math.round(agedDebt) > 0 || Math.round(agedDebt3Months) > 0

const nonZeroPeriodWipFilter = ({ wipForPeriod = 0 }) =>
    Math.round(wipForPeriod) !== 0

const nonZeroTotalWipFilter = ({ historicUnbilledWip = 0, wipForPeriod = 0 }) =>
    Math.round(historicUnbilledWip + wipForPeriod) !== 0

const presetViewFilters = {
    [DEFAULT]: () => true,
    [SETUP_PROBLEMS]: flagFilter,
    [UNFINISHED]: unactionedInvoiceFilter,
    [PROVISIONS]: provisionedFilter,
    [AGED_DEBT]: agedDebtFilter,
    [HAS_PERIOD_WIP]: nonZeroPeriodWipFilter,
    [HAS_TOTAL_WIP]: nonZeroTotalWipFilter,
}

export {
    addSetupProblemFlags,
    presetViewFilters,
    presetViewLabels,
    presetViewNames,
}