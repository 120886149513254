import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useQueryParam,
  NumberParam,
  ObjectParam,
  withDefault,
} from 'use-query-params'

import {
  DataGridPro,
  GridFooterContainer,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  useGridApiRef,
} from '@mui/x-data-grid-pro'

import CompanyWorkbookHeader from './CompanyWorkbookHeader'
import CompanyWorkbookPaginationControls from './CompanyWorkbookPaginationControls'
import CompanyWorkbookSubmissionControls from './CompanyWorkbookSubmissionControls'

import { useClientContext } from '../clientContext'
import Loading from '../primitives/Loading'
import { selectInvoicingApproaches } from '../slices/invoicingApproaches'

import {
  isModelEquivToQueryParam,
  toFilterModel,
  toQueryParamFilter,
} from '../utils/dataGridFilterUtils'
import { makeColumns } from '../utils/columns'
import { makeRows, getRowClassName } from '../utils/rows'

// based on sizing of table rows, given below
const defaultPageSize = 25
const maxFlexiRows = 26
const rowHeight = 25
const maxFixedHeight = 110 + maxFlexiRows * rowHeight

const CompanyWorkbookDataGrid = ({
  enableEditWriteOff,
  isOps,
  editedLines,
  onRowEdit,
  onSaveEdits,
  hiddenColumns,
  handleColumnVisibilityChange,
  viewMode,
  workbookLines,
}) => {
  const gridApiRef = useGridApiRef()
  const { cwbColumns } = useClientContext()
  const invoicingApproaches = useSelector(selectInvoicingApproaches)

  const [filter, setFilter] = useQueryParam(
    'filter',
    withDefault(ObjectParam, null)
  )
  const [filterModel, setFilterModel] = useState({ items: [] })

  const [sort, setSort] = useQueryParam('sort', withDefault(ObjectParam, null))
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0))

  const [editRowsModel, setEditRowsModel] = useState({})
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const handleEditRowsModelChange = useCallback((model) => {
    setEditRowsModel(model)
  }, [])

  // When the filterModel changes (could either be from user input or from useEffect below):
  // 1. Update the filterModel state
  // 2. Then if the filterModel does not match the query params, set the query params to match.
  // TODO: Currently the query params should only reflect first item in filterModel.
  // See discussion in Jira ticket WB-708 for more information.
  const handleFilterModelChange = useCallback(
    (params) => {
      setFilterModel(params)
      if (!isModelEquivToQueryParam(params, filter)) {
        setFilter(toQueryParamFilter(params))
      }
    },
    [filter, setFilter]
  )

  useEffect(() => {
    if (!isModelEquivToQueryParam(filterModel, filter)) {
      setFilterModel(toFilterModel(filter))
    }
  }, [filter, filterModel])

  const handlePageChange = useCallback(
    (newPage) => {
      if (page !== newPage) {
        setPage(newPage)
      }
    },
    [page, setPage]
  )

  const handleSortModelChange = useCallback(
    (params) => {
      if (params.length === 0) {
        setSort(null)
      } else {
        setSort(params[0])
      }
    },
    [setSort]
  )

  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const [totalRowCount, setTotalRowCount] = useState(0)

  const handleCellClick = (params) => {
    if (params.isEditable && params.cellMode !== 'edit') {
      gridApiRef.current.setCellMode(params.id, params.field, 'edit')
      gridApiRef.current.setCellFocus(params.id, params.field)
    }
  }

  const handleEditCellChangeCommitted = useCallback(
    ({ id, field, error, value = null }) => {
      if (error) {
        return
      }
      if (value !== null) {
        const editedProjectId = rows[id].projectId

        // this Number conversion works while we only submit number-type fields
        // when we add string fields (e.g. comment), we'll have to handle conversion
        // on a case-by-case basis
        const editedValue = value === '' ? null : Number(value)

        onRowEdit(editedProjectId, {
          projectId: editedProjectId,
          [field]: editedValue,
        })
      }
    },
    [rows, onRowEdit]
  )

  useEffect(() => {
    if (viewMode === 'export') {
      setPageSize(workbookLines.length + 1)
      setPage(0)
    } else {
      setPageSize(defaultPageSize)
    }
  }, [viewMode, workbookLines, setPage])

  useEffect(() => {
    setColumns(
      makeColumns(
        {},
        {
          cwbColumns,
          enableEditWriteOff,
          hiddenColumns,
          editable: viewMode === 'edit',
          sortable: true,
        },
        { invoicingApproaches }
      )
    )
  }, [
    cwbColumns,
    enableEditWriteOff,
    hiddenColumns,
    setFilter,
    setSort,
    viewMode,
    invoicingApproaches,
  ])

  const sortModel = useMemo(() => (sort ? [sort] : []), [sort])

  useEffect(() => {
    const result = makeRows(
      workbookLines,
      viewMode === 'edit' ? editedLines : {},
      filterModel,
      sortModel,
      pageSize,
      page
    )
    if (totalRowCount !== result.totalRowCount) {
      setPage(0)
    }
    setTotalRowCount(result.totalRowCount)
    setRows(result.rows)
  }, [
    editedLines,
    page,
    pageSize,
    filterModel,
    sortModel,
    totalRowCount,
    workbookLines,
    viewMode,
    setPage,
  ])

  const pinnedColumns = {
    left: ['jiraKey', 'clientName', 'projectName', 'projectManager'],
  }

  const fixedHeight = rows.length > maxFlexiRows
  const fixedHeightContainerStyle = fixedHeight
    ? { clear: 'both', height: maxFixedHeight }
    : {}

  if (columns.length === 0 || rows.length === 0) {
    return <Loading />
  } else {
    return (
      <div style={fixedHeightContainerStyle}>
        <DataGridPro
          apiRef={gridApiRef}
          autoHeight={!fixedHeight}
          rowHeight={rowHeight}
          headerHeight={40}
          columns={columns}
          disableColumnMenu
          disableColumnReorder
          disableColumnResize
          hideFooterPagination
          onCellClick={handleCellClick}
          isCellEditable={(params) => params.row.jiraKey !== 'Total'}
          editRowsModel={editRowsModel}
          onEditRowsModelChange={handleEditRowsModelChange}
          onCellEditCommit={handleEditCellChangeCommitted}
          filterMode="server"
          onFilterModelChange={handleFilterModelChange}
          filterModel={
            columns.length > 0 && rows.length > 0 ? filterModel : null
          }
          getRowClassName={getRowClassName}
          localeText={{ filterOperatorIsnull: 'is null' }}
          pinnedColumns={pinnedColumns}
          rows={rows}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                {viewMode === 'export' ? <GridToolbarExport /> : null}
                <CompanyWorkbookHeader float isOps={isOps} />
              </GridToolbarContainer>
            ),
            Footer: () => (
              <GridFooterContainer>
                {viewMode === 'edit' ? (
                  <CompanyWorkbookSubmissionControls onSave={onSaveEdits} />
                ) : null}
                <CompanyWorkbookPaginationControls
                  disabled={viewMode === 'export'}
                  totalRowCount={totalRowCount}
                  pageSize={pageSize}
                  page={page}
                  onPageChange={handlePageChange}
                />
              </GridFooterContainer>
            ),
          }}
        />
      </div>
    )
  }
}

export default CompanyWorkbookDataGrid
