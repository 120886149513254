export function toQueryParamFilter(filterModel) {
  var newFilter = filterModel.items[0]
  if (newFilter && newFilter.columnField && newFilter.operatorValue) {
    return {
      column: newFilter.columnField,
      operator: newFilter.operatorValue,
      value: newFilter.value || '',
    }
  }

  return null
}

export function toFilterModel(queryParamFilter) {
  if (
    queryParamFilter &&
    queryParamFilter.column &&
    queryParamFilter.operator
  ) {
    return {
      items: [
        {
          columnField: queryParamFilter.column,
          operatorValue: queryParamFilter.operator,
          value: queryParamFilter.value || '',
        },
      ],
    }
  }

  return { items: [] }
}

// Compares the filterModel to the queryParamFilter.
// If neither is defined, or if they are equivalent, then returns true.
// TODO: Currently the query params should only reflect first item in filterModel.
// See discussion in Jira ticket WB-708 for more information.
export function isModelEquivToQueryParam(filterModel = {}, queryParamFilter) {
  const firstItemModel = filterModel.items[0]
  if (!firstItemModel && !queryParamFilter) { return true }
  if (firstItemModel && queryParamFilter) {
    return (
      firstItemModel.columnField === queryParamFilter.column &&
      firstItemModel.operatorValue === queryParamFilter.operator &&
      firstItemModel.value === queryParamFilter.value
    )
  }
}
