// These map access right functionality to access right codes. This must match up
// to access right codes used on the backend. Access right codes are used rather than textual descriptions
// to provide a very clear interface between the frontend and the backend and to prevent these codes
// being used to convey additional meaning where they shouldn't be used for this purpose. These codes should
// exist as a static string that never changes, even if the functionality it's controlling slightly deviates.

export const ACCESS_RIGHT_CODES = {
    ACCESS_APPLICATION: "2844aaea-dae3-48a0-b0fb-1d52b4666d57",
    ACCESS_ENTITY: "aaab1084-234e-4199-91d7-f74442ad0734",
    CREATE_PROJECT: "8a039232-99b0-4eae-9050-ffee27a39824",
    GENERATE_WORKBOOK: "72ec3dcf-cebc-4216-a719-1ed8bf290d6a",
    CHARGEABILITY_FULL_VIEW: "4f6f8040-c0af-4a4f-b3bc-1e707c814b39",

    CHARGEABILITY_TEAM_LEAD_VIEW: "72c7141c-655a-442f-9e41-9d519717d80e",
    CHARGEABILITY_GROUP_LEAD_VIEW: "cc62598b-b33d-424b-adf7-a325e9573a6f",
    COMPANY_WORKBOOK_PD_REVIEW_STAGE: "6374676f-bf1f-4ee0-8595-cbcd48e1b80e",
}

export const accessRightCodeMap = new Map([
    // API permission exclusive (from JWT permission claims via DB `api_permissions` table lookup) 
    [ACCESS_RIGHT_CODES.ACCESS_APPLICATION, "accessApplication"],
    [ACCESS_RIGHT_CODES.ACCESS_ENTITY, "accessEntity"],
    [ACCESS_RIGHT_CODES.CREATE_PROJECT, "createProject"],
    [ACCESS_RIGHT_CODES.GENERATE_WORKBOOK, "generateWorkbook"],
    [ACCESS_RIGHT_CODES.CHARGEABILITY_FULL_VIEW, "chargeabilityFullView"],

    // Feature flag exclusive
    [ACCESS_RIGHT_CODES.CHARGEABILITY_GROUP_LEAD_VIEW, "chargeabilityGroupLeadView"],
    [ACCESS_RIGHT_CODES.CHARGEABILITY_TEAM_LEAD_VIEW, "chargeabilityTeamLeadView"],
    [ACCESS_RIGHT_CODES.COMPANY_WORKBOOK_PD_REVIEW_STAGE, "companyWorkbookPdReviewStage"],

    // Combined API permissions and feature flags
    // ...
])

