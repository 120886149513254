import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import ProjectStaffRatesDialog from './ProjectStaffRatesDialog'

import {
  fetchProjectCandidates,
  selectLoadingState as selectProjectCandidatesLoadingState,
} from '../slices/projectCandidates'
import {
  fetchProjectRates,
  selectLoadingState as selectProjectRatesLoadingState,
} from '../slices/projectRates'
import LoadingState from '../utils/LoadingState'

const RatesConfigurationContainer = ({ projectId, projectJiraKey, view: GridRow }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const projectCandidatesLoadingState = useSelector(
    selectProjectCandidatesLoadingState
  )
  const projectRatesLoadingState = useSelector(selectProjectRatesLoadingState)

  const [ratesDialogOpen, setRatesDialogOpen] = useState(false)

  // fetch project candidates (including their default rates) on load
  useEffect(() => {
    if (
      projectId !== null &&
      projectCandidatesLoadingState === LoadingState.idle
    ) {
      dispatch(fetchProjectCandidates({ projectId, getTokenCallback }))
    }
  }, [projectCandidatesLoadingState, projectId, dispatch, getTokenCallback])

  // fetch project rates on load
  useEffect(() => {
    if (projectId !== null && projectRatesLoadingState === LoadingState.idle) {
      dispatch(fetchProjectRates({ projectId, getTokenCallback }))
    }
  }, [projectRatesLoadingState, projectId, dispatch, getTokenCallback])

  const label =
    projectCandidatesLoadingState === LoadingState.idle ||
      projectCandidatesLoadingState === LoadingState.pending ||
      projectRatesLoadingState === LoadingState.idle ||
      projectRatesLoadingState === LoadingState.pending
      ? 'loading...'
      : projectCandidatesLoadingState === LoadingState.rejected ||
        projectRatesLoadingState === LoadingState.rejected
        ? 'Error. Failed to load.'
        : 'Custom'

  const gridRowProps = {
    buttonProps: {
      children: 'Rates',
      disabled: projectCandidatesLoadingState !== LoadingState.fulfilled ||
        projectRatesLoadingState !== LoadingState.fulfilled,
      onClick: () => setRatesDialogOpen(true),
    },
    dialogComponent: () =>
      <ProjectStaffRatesDialog
        projectJiraKey={projectJiraKey}
        projectId={projectId}
        open={ratesDialogOpen}
        onClose={() => setRatesDialogOpen(false)}
      />,
    label,
  }

  return <GridRow {...gridRowProps} />
}

export default RatesConfigurationContainer
