import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const SelectorDropdown = ({ label, onChange, options, sx, selectSx, value, disabled }) => (
  <FormControl sx={{ float: 'left', minWidth: 250, mb: 1, ...sx }}>
    {label !== undefined && label !== null ? (
      <InputLabel
        id="period-label"
        sx={{
          transform: 'translate(8px, -2px) scale(0.75)',
          width: 1,
        }}
      >
        {label}
      </InputLabel>
    ) : null}
    <Select
      id="period-select"
      labelId="period-label"
      onChange={onChange}
      sx={{
        backgroundColor: "editable.light",
        fontSize: '14px',
        pl: 1,
        '& .MuiSelect-select:focus': {
          backgroundColor: "editable.light",
        },
        ...selectSx
      }}
      value={value}
      variant="standard"
      disabled={disabled}
    >
      {options.map((option, i) => (
        <MenuItem key={i} style={{ fontSize: '14px' }} {...option} />
      ))}
    </Select>
  </FormControl>
)

export default SelectorDropdown
