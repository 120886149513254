import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import {
    fetchChargeability as fetchChargeabilityAction,
    selectLoadingState as selectChargeabilityLoadingState,
} from '../slices/chargeability'

import { selectClientName } from '../slices/client'
import LoadingState from '../utils/LoadingState'

// Checks if the user has chargeability access based on if they can access the chargeability endpoint
export const useChargeabilityAccess = (chargeabilityEnabled) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0();
  const dispatch = useDispatch();

  // Used to ensure chargeability is only fetched if clientName context has been set
  const clientName = useSelector(selectClientName);
  
  // State of chargeability GET API call
  const chargeabilityLoadingState = useSelector(selectChargeabilityLoadingState);
  
  const fetchChargeability = useCallback(() => {
    if (clientName) {
      dispatch(fetchChargeabilityAction({ getTokenCallback }));
    }
  }, [dispatch, getTokenCallback, clientName]);

  useEffect(() => {
    if (chargeabilityLoadingState === LoadingState.idle && chargeabilityEnabled) {
      fetchChargeability();
    }
  }, [fetchChargeability, chargeabilityLoadingState, chargeabilityEnabled]);

  return chargeabilityLoadingState === LoadingState.fulfilled;
};
