import { selectWorkbookStatus } from '../companyWorkbook'
import { createSelector } from '@reduxjs/toolkit'
import {
  getStepFromWorkbookDatabaseStatus,
  getWorkbookSteps,
} from '../../utils/steps'
import { selectPdReviewAccessRight } from '../accessRights'
import workbookStatus from '../../utils/workbookStatus'

/**
 * Selects the currently available workbook steps taking into account enabled workbook steps, and the current
 * step the workbook is in. If a workbook step (such as PD review) isn't enabled but the workbook is currently in that
 * state, the step will be included in the output. When the workbook then changes from that status, if the step is still
 * disabled, the next call to the selectCurrentlyAvailableWorkbookSteps won't return that step.
 */
export const selectCurrentlyAvailableWorkbookSteps = createSelector(
  selectWorkbookStatus,
  selectPdReviewAccessRight,
  (currentWorkbookStatus, pdReviewAccessRight) => {
    // Only include the reviewing step if it is enabled or the workbook is currently in that step
    const isPdReviewStageEnabled = pdReviewAccessRight || false
    const includeReviewingStep =
      currentWorkbookStatus === workbookStatus.pdReview ||
      isPdReviewStageEnabled

    return getWorkbookSteps({ includeReviewingStep })
  }
)

/**
 * Selects the active workbook step based on the DB workbook status and workbook steps in the store.
 */
export const selectActiveWorkbookStep = createSelector(
  selectWorkbookStatus,
  selectCurrentlyAvailableWorkbookSteps,
  (workbookStatus, workbookSteps) => {
    const workbookStep = getStepFromWorkbookDatabaseStatus(
      workbookSteps,
      workbookStatus
    )
    return workbookStep
  }
)
