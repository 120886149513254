import { Fragment } from 'react'
import { TableHead, TableRow } from '@mui/material'
import { rowSumTotalsLabel } from '../utils/chargeabilityUtils'
import { CellType, ChargeabilityCell, ChargeabilityCellWithRef } from '../views/ChargeabilityCell'

const insertBreaksForSpaces = (inputString, maxLineLength) => {
  if (typeof inputString !== 'string') {
    return []
  }

  const words = inputString.split(' ')
  let currentLine = ''
  const elements = []

  // Intentionally don't use index as key as this causes duplicate issues with long words
  words.forEach((word) => {
    // Check if adding the next word exceeds the max line length
    if ((currentLine + word).length > maxLineLength) {
      // Add current line to elements and reset it
      elements.push(
        <Fragment key={elements.length}>
          {currentLine}
          <br />
        </Fragment>
      )
      currentLine = word + ' ' // Start new line with the current word
    } else {
      // Add word to the current line
      currentLine += word + ' '
    }
  })

  // Add the last line if it's not empty
  if (currentLine.trim() !== '') {
    elements.push(<Fragment key={elements.length}>{currentLine}</Fragment>)
  }

  return elements
}

const ChargeabilityHeaders = ({ resourceHierarchy, resourceLevel, updateMaxWidth, cellWidth }) => {
  return (
    <TableHead>
      <TableRow>
        <ChargeabilityCell isRowTitle type={CellType.h1Sticky}>
          Group
        </ChargeabilityCell>
        <ChargeabilityCell isFirstInGroup type={CellType.h1} cellWidth={cellWidth}>
          {rowSumTotalsLabel}
        </ChargeabilityCell>
        {Object.entries(resourceHierarchy).map(([groupName, group], i) => {
          return (
            <ChargeabilityCellWithRef
              key={i}
              colSpan={
                resourceLevel === 'group'
                  ? 1
                  : resourceLevel === 'team'
                  ? Object.keys(group).length
                  : Object.values(group)
                      .map((team) => team.length)
                      .reduce((a, b) => a + b)
              }
              cellWidth={cellWidth}
              updateMaxWidth={resourceLevel === 'group' ? updateMaxWidth : undefined}
              isFirstInGroup
              type={CellType.h1}
              sx={{ overflowWrap: 'normal' }}
            >
              {insertBreaksForSpaces(groupName, 15)}
            </ChargeabilityCellWithRef>
          )
        })}
      </TableRow>
      {resourceLevel === 'team' || resourceLevel === 'person' ? (
        <TableRow>
          <ChargeabilityCell isRowTitle type={CellType.h2Sticky}>
            Team
          </ChargeabilityCell>
          <ChargeabilityCell isRowTitle isFirstInGroup type={CellType.h2} cellWidth={cellWidth}></ChargeabilityCell>
          {Object.values(resourceHierarchy).map((group, i) => (
            <Fragment key={i}>
              {Object.entries(group).map(([teamName, teamMembers], j) => (
                <ChargeabilityCellWithRef
                  key={j}
                  colSpan={resourceLevel === 'team' ? 1 : teamMembers.length}
                  isFirstInGroup={j === 0}
                  isFirstInTeam
                  type={CellType.h2}
                  updateMaxWidth={resourceLevel === 'team' ? updateMaxWidth : undefined}
                  cellWidth={cellWidth}
                  sx={{ overflowWrap: 'normal' }}
                >
                  {insertBreaksForSpaces(teamName, 15)}
                </ChargeabilityCellWithRef>
              ))}
            </Fragment>
          ))}
        </TableRow>
      ) : null}
      {resourceLevel === 'person' ? (
        <TableRow>
          <ChargeabilityCell isRowTitle type={CellType.h3Sticky}>
            PERSON
          </ChargeabilityCell>
          <ChargeabilityCell isRowTitle isFirstInGroup type={CellType.h3} cellWidth={cellWidth}></ChargeabilityCell>
          {Object.values(resourceHierarchy).map((group, i) => {
            return (
              <Fragment key={i}>
                {Object.values(group).map((teamMembers, j) => (
                  <Fragment key={j}>
                    {teamMembers.map((person, k) => {
                      return (
                        <ChargeabilityCellWithRef
                          type={CellType.h3}
                          key={k}
                          isFirstInGroup={k === 0 && j === 0}
                          isFirstInTeam={k === 0}
                          updateMaxWidth={updateMaxWidth}
                          cellWidth={cellWidth}
                          sx={{ overflowWrap: 'normal' }}
                        >
                          {insertBreaksForSpaces(person.name, 15)}
                        </ChargeabilityCellWithRef>
                      )
                    })}
                  </Fragment>
                ))}
              </Fragment>
            )
          })}
        </TableRow>
      ) : null}
    </TableHead>
  )
}
export default ChargeabilityHeaders
