import ToggleSwitch from '../primitives/ToggleSwitch'
import ChargeabilityTimeframeSlider from '../views/ChargeabilityTimeframeSlider'
import ChargeabilityResourceLevelSlider from '../views/ChargeabilityResourceLevelSlider'
import { Box } from '@mui/material'

const ChargeabilityControls = ({
  timeFrame,
  onTimeFrameChanged,
  resourceLevel,
  onResourceLevelChanged,
  adjustForLeave,
  onAdjustForLeaveChanged,
  showColours,
  onShowColoursChanged,
  currentStaffOnly,
  onCurrentStaffOnlyChanged,
}) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center' }}>
        <ChargeabilityTimeframeSlider timeframe={timeFrame} onChange={onTimeFrameChanged} />
        <ChargeabilityResourceLevelSlider
          resourceLevel={resourceLevel}
          onChange={onResourceLevelChanged}
          sx={{ mr: 12 }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <ToggleSwitch
          checked={adjustForLeave}
          onChange={(event) => onAdjustForLeaveChanged(event.target.checked)}
          offText=""
          onText="LEAVE ADJUSTED"
          sx={{ mr: 2, mt: 0.5 }}
        />
        <ToggleSwitch
          checked={showColours}
          onChange={(event) => onShowColoursChanged(event.target.checked)}
          offText=""
          onText="COLOURS"
          sx={{ mr: 2, mt: 0.5 }}
        />
        {resourceLevel === 'person' && (
          <ToggleSwitch
            checked={currentStaffOnly}
            onChange={(event) => onCurrentStaffOnlyChanged(event.target.checked)}
            offText=""
            onText="CURRENT STAFF"
            sx={{ mr: 12, mt: 0.5 }}
          />
        )}
      </Box>
    </Box>
  )
}
export default ChargeabilityControls
