import { Slider } from '@mui/material'

const sliderSx = {
  float: 'left',
  ml: 3,
  mr: 5,
  width: 110,
  '& .MuiSlider-markLabel': {
    fontSize: 12,
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
  },
}

const CategorySlider = ({ sliderValues, value, name, onChange, sx }) => {

  function setValueByLabel(label) {
    const valuesIndex = sliderValues.findIndex(
      (row) => row.label.toLowerCase() === label
    )
    return sliderValues[valuesIndex].value
  }

  return (
    <Slider
      aria-label={name}
      color="secondary"
      marks={sliderValues}
      max={sliderValues.length - 1}
      min={0}
      onChange={(_, value) => onChange(sliderValues[value].label.toLowerCase())}
      step={null}
      sx={{...sliderSx, ...sx}}
      track={false}
      value={setValueByLabel(value)}
      valueLabelDisplay="off"
    />
  )
}

export default CategorySlider
