import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import api from '../utils/api'
import LoadingState from '../utils/LoadingState'

const initialState = {
  loadingState: LoadingState.idle,
  data: null,
}

export const fetchProjects = createAsyncThunk(
  'fetchProjects',
  async ({ getTokenCallback }, { getState }) => {
    return await api.getData(
      api.endpoints(getState().client.name).projects.v1.all(),
      getTokenCallback
    )
  }
)

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
  },
  extraReducers: {
    [fetchProjects.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchProjects.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error
    },
    [fetchProjects.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled

      var projectMap = {}
      const projectList = action.payload.projectList
      if (projectList != null) {
        for (var project of projectList) {
          projectMap[project.projectId] = project
        }
      }
      state.data = projectMap
    },
  },
})

export const { reset } = slice.actions

export const selectLoadingState = (state) => state.projects.loadingState

export const selectProjectsError = (state) =>
  state.projects.loadingState === LoadingState.rejected
    ? state.projects.data
    : null

export const selectProjectsMap = (state) => state.projects.data

export const selectProjectsArray = createSelector(
  selectProjectsMap,
  (projectsMap) => (projectsMap ? Object.values(projectsMap) : null)
)

export default slice.reducer
