import { Box, Button } from '@mui/material'
import colours from '../styles/colours'
import { AnimatedTickButton } from '../primitives/AnimatedButton'

const SaveAndResetButtons = ({
  errorMessage,
  onReset,
  onSave,
  onSubmit,
  resetDisabled,
  saveDisabled,
  submitDisabled,
  submitValidationMessage,
  saveState,
  submitState,
}) => {
  return (<>
    <AnimatedTickButton
      handleClick={onSubmit}
      loadingState={submitState}
      aria-label="submit"
      color="secondary"
      disabled={submitDisabled}
      disabledMessage={submitValidationMessage}
      size="small"
      sx={{ float: 'right', ml: 1, mt: 1, minHeight: '30px' }}
      variant="contained"
      idleText="Submit"
      />
    <AnimatedTickButton
      handleClick={onSave}
      loadingState={saveState}
      aria-label="save"
      color="secondary"
      disabled={saveDisabled}
      size="small"
      sx={{ float: 'right', ml: 1, mt: 1, minHeight: '30px' }}
      variant="contained"
      idleText="Save"
      />
    <Button
      aria-label="reset"
      disabled={resetDisabled}
      onClick={onReset}
      size="small"
      sx={{ color: colours.muiDefaultBlack, float: 'right', ml: 1, mt: 1, minHeight: '30px' }}
    >
      Reset
    </Button>
    {errorMessage ? (
      <Box
        component="span"
        sx={{
          color: 'error.main',
          clear: 'both',
          display: 'block',
          float: 'right',
          mt: 0.5,
          verticalAlign: 'center',
          width: "100%"
        }}
      >
        {errorMessage}
      </Box>
    ) : null}
  </>)
}


export default SaveAndResetButtons
