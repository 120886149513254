import { useCallback, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Alert } from '@mui/material'

const ErrorAlert = ({ children }) => (
  <Alert severity="error" sx={{ clear: 'both', m: 1 }}>
    {children}
  </Alert>
)

const Error = ({ error, retryCallback }) => {
  const { getAccessTokenWithPopup, loginWithRedirect } = useAuth0()

  const [errorState, setError] = useState(error)

  const handleConsent = useCallback(async () => {
    try {
      await getAccessTokenWithPopup()
      setError(null)
    } catch (error) {
      setError(error.error)
    }

    if (retryCallback) {
      await retryCallback()
    }
  }, [getAccessTokenWithPopup, retryCallback, setError])

  const handleLoginAgain = useCallback(async () => {
    try {
      await loginWithRedirect()
      setError(null)
    } catch (error) {
      setError(error.error)
    }

    if (retryCallback) {
      await retryCallback()
    }
  }, [loginWithRedirect, retryCallback, setError])

  const handle = (e, fn) => {
    e.preventDefault()
    fn()
  }

  if (errorState === 'consent_required') {
    return (
      <ErrorAlert>
        You need to{' '}
        <a href="#/" onClick={(e) => handle(e, handleConsent)}>
          get consent to get access to users api
        </a>
      </ErrorAlert>
    )
  } else if (errorState === 'login_required') {
    return (
      <ErrorAlert>
        You need to{' '}
        <a href="#/" onClick={(e) => handle(e, handleLoginAgain)}>
          log in
        </a>
      </ErrorAlert>
    )
  } else {
    return (
      <ErrorAlert>
        {!error
          ? 'Something went wrong.'
          : error.message
            ? error.message
            : error}
        {retryCallback ? (
          <>
            {' '}
            <a href="#/" onClick={(e) => handle(e, retryCallback)}>
              Retry
            </a>
          </>
        ) : null}
      </ErrorAlert>
    )
  }
}

export default Error
