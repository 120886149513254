const config = {
  apiOrigin: {
    awa: process.env.REACT_APP_API_ORIGIN,
    flip: process.env.REACT_APP_API_ORIGIN_FLIP,
    testClient: 'https://some-testing-url.awa.kiwi',
  },
  auth0: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
  },
  auth0ClientId: {
    awa: process.env.REACT_APP_AUTH0_CLIENT_ID,
    flip: process.env.REACT_APP_AUTH0_CLIENT_ID_FLIP,
  },
  jira: { baseUrl: process.env.REACT_APP_JIRA_BASE_URL },
  tempo: { baseUrl: process.env.REACT_APP_TEMPO_BASE_URL },
  proposalLineItemName: 'Proposal Development',
  unassignedLineItemName: 'Unassigned',
  viewerTypes: {
    none: 'none',
    clientManager: 'clientManager',
    projectManager: 'projectManager',
    projectDirector: 'projectDirector',
  },
  googleTagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  isDevLook: process.env.REACT_APP_LOOK === 'development',
  isTestLook: process.env.REACT_APP_LOOK === 'test',
  muixproLicenseKey: process.env.REACT_APP_MUIXPRO_LICENSE_KEY,
  namespace: process.env.REACT_APP_NAMESPACE,
  auth0Roles: {
    basicAccess: 'Project Hub User',
    leadership: 'PH Leadership',
    operations: 'PH Operations',
    reconciliation: 'PH Reconciliation',
  },
}

export default config
