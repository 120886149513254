import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'

import ProjectEditBudgetDialog from './ProjectEditBudgetDialog'

import {
  fetchProject,
  selectProject,
  selectLoadingState,
} from '../slices/project'
import { formatMoney } from '../utils/format'
import LoadingState from '../utils/LoadingState'

const BudgetConfigurationContainer = ({ projectId, view: GridRow }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const loadingState = useSelector(selectLoadingState)

  const [budgetDialogOpen, setBudgetDialogOpen] = useState(false)

  // fetch project on load
  useEffect(() => {
    if (projectId !== null && loadingState === LoadingState.idle) {
      dispatch(fetchProject({ getTokenCallback, projectId }))
    }
  }, [loadingState, dispatch, getTokenCallback, projectId])

  const label =
    loadingState === LoadingState.idle || loadingState === LoadingState.pending
      ? 'loading...'
      : loadingState === LoadingState.rejected
        ? 'Error. Failed to load.'
        : formatMoney(project.initialBudget)

  const gridRowProps = {
    buttonProps: {
      children: 'Budget',
      disabled: loadingState !== LoadingState.fulfilled,
      onClick: () => setBudgetDialogOpen(true),
    },
    dialogComponent: () =>
      <ProjectEditBudgetDialog
        open={budgetDialogOpen}
        onClose={() => setBudgetDialogOpen(false)}
      />,
    label,
  }

  return <GridRow {...gridRowProps} />
}

export default BudgetConfigurationContainer
