import { useCallback, useEffect, useRef, useState } from 'react'
import { InputAdornment, MenuItem, TextField } from '@mui/material'

const FilterInput = ({
  applyValue,
  item,
  options = {},
  type,
  startAdornment,
}) => {
  const inputRef = useRef()
  const [timeoutId, setTimeoutId] = useState(0)
  const [value, setValue] = useState(item.value)

  const handleValueChange = useCallback(
    (event) => {
      setValue(event.target.value)
      clearTimeout(timeoutId)
      setTimeoutId(
        setTimeout(() => {
          applyValue({ ...item, value: event.target.value })
        }, 500)
      )
    },
    [applyValue, item, timeoutId]
  )

  useEffect(() => {
    if (item.value === value && inputRef.current) {
      inputRef.current.focus()
    }
  }, [item, inputRef, value])

  return (
    <TextField
      name="value"
      label="Value"
      type={type}
      inputRef={inputRef}
      onChange={handleValueChange}
      select={type === 'select'}
      value={value || ''}
      variant="standard"
      InputLabelProps={{
        disableAnimation: true,
      }}
      InputProps={{
        startAdornment: startAdornment,
      }}
    >
      {Object.entries(options).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      ))}
    </TextField>
  )
}

const FilterDateInput = ({ applyValue, item }) => (
  <FilterInput applyValue={applyValue} item={item} type="date" />
)

const FilterMoneyInput = ({ applyValue, item }) => (
  <FilterInput
    applyValue={applyValue}
    item={item}
    type="number"
    startAdornment={<InputAdornment position="start">$</InputAdornment>}
  />
)

const FilterSelectInput = (options) => (props) =>
  <FilterInput type="select" options={options} {...props} />

export {
  FilterInput as default,
  FilterDateInput,
  FilterMoneyInput,
  FilterSelectInput,
}
