import { useCallback } from 'react'

import { Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import colours from '../styles/colours'

const StringListEditorAddButton = ({ disabled, list, onChange }) => {

  const handleClickAdd = useCallback(() => {
    onChange([...list, ''])
  }, [list, onChange])

  return (
    <Button
      aria-label="add"
      disabled={disabled}
      onClick={handleClickAdd}
      style={{ color: colours.muiDefaultBlack, float: 'left' }}
    >
      <AddIcon />
      <Typography>Add</Typography>
    </Button>
  )
}

export default StringListEditorAddButton
