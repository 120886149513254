const MuiAppBar = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.appLook.light,
      color: theme.palette.text.dark,
      height: 60,
    }),
  },
}

export default MuiAppBar
