import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { Dialog, DialogActions, DialogContent } from '@mui/material'

import {
  reset,
  resetUpdateError,
  updateProject,
  selectLoadingState,
  selectProject,
  selectUpdateError as selectUpdateProjectError,
  selectUpdateState as selectUpdateProjectState,
} from '../slices/project'

import LoadingState from '../utils/LoadingState'
import { validateEmail } from '../utils/validation'

import DialogTitleWithClose from '../views/DialogTitleWithClose'
import InvoiceRecipientsEditor from '../views/InvoiceRecipientsEditor'
import SaveEditsControl from '../views/SaveEditsControl'
import StringListEditorAddButton from '../views/StringListEditorAddButton'

const ProjectInvoiceRecipientsDialog = ({ onClose, open }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const loadingState = useSelector(selectLoadingState)
  const project = useSelector(selectProject)
  const updateError = useSelector(selectUpdateProjectError)
  const updateState = useSelector(selectUpdateProjectState)

  const [invoiceRecipients, setInvoiceRecipients] = useState([])

  const handleChangeInvoiceRecipients = useCallback(
    (value) => {
      setInvoiceRecipients(value)
      dispatch(resetUpdateError())
    },
    [dispatch, setInvoiceRecipients]
  )

  const saveProject = useCallback(() => {
    dispatch(
      updateProject({
        project: { ...project, invoiceRecipients: invoiceRecipients },
        getTokenCallback,
      })
    )
  }, [dispatch, project, invoiceRecipients, getTokenCallback])

  // update project values when project changes
  useEffect(() => {
    if (
      loadingState === LoadingState.fulfilled &&
      project &&
      project.invoiceRecipients &&
      project.invoiceRecipients.length !== 0 &&
      invoiceRecipients.length === 0
    ) {
      handleChangeInvoiceRecipients(
        project.invoiceRecipients ? project.invoiceRecipients : []
      )
    }
  }, [loadingState, project, invoiceRecipients, handleChangeInvoiceRecipients])

  // reset to refresh projects when changes are saved
  useEffect(() => {
    if (updateState === LoadingState.fulfilled) {
      dispatch(reset())
    }
  }, [dispatch, updateState])

  const loadingOrSaving = useMemo(() => {
    return (
      loadingState === LoadingState.pending ||
      updateState === LoadingState.pending
    )
  }, [loadingState, updateState])

  const isEdited = useMemo(() => {
    if (project && project.invoiceRecipients) {
      if (invoiceRecipients.length !== project.invoiceRecipients.length) {
        return true
      }

      for (const idx in invoiceRecipients) {
        if (project.invoiceRecipients[idx] !== invoiceRecipients[idx]) {
          return true
        }
      }
    } else if (invoiceRecipients.length > 0) {
      return true
    }

    return false
  }, [project, invoiceRecipients])

  const isValid = useMemo(() => {
    for (const email of invoiceRecipients) {
      if (!validateEmail(email)) {
        return false
      }
    }

    return true
  }, [invoiceRecipients])

  if (!project) {
    return null
  } else {
    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitleWithClose onClose={onClose} title="Invoice Recipients" />
        <DialogContent>
          <InvoiceRecipientsEditor
            disabled={loadingOrSaving}
            originalInvoiceRecipients={project.invoiceRecipients}
            invoiceRecipients={invoiceRecipients}
            onChangeInvoiceRecipients={handleChangeInvoiceRecipients}
          />
        </DialogContent>
        <DialogActions>
          <div style={{ clear: 'both', width: '100%' }}>
            <StringListEditorAddButton
              disabled={loadingOrSaving || invoiceRecipients.length >= 3}
              list={invoiceRecipients}
              onChange={handleChangeInvoiceRecipients}
            />
            <SaveEditsControl
              isEdited={isEdited}
              isValid={isValid}
              loadingState={loadingState}
              onReset={() => handleChangeInvoiceRecipients([])}
              onSave={saveProject}
              updateError={updateError}
              updateState={updateState}
            />
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ProjectInvoiceRecipientsDialog
