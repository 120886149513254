import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { NumberParam, useQueryParam } from 'use-query-params'

import { Box, Typography, Paper } from '@mui/material'

import { useClientContext } from '../clientContext'
import InvoiceProjectDetailsTable from './InvoiceProjectDetailsTable'

import Error from '../primitives/Error'
import Info from '../primitives/Info'
import Loading from '../primitives/Loading'

import {
  fetchInvoiceDetails,
  saveInvoice as saveInvoiceAction,
  submitInvoice as submitInvoiceAction,
  selectInvoices,
  selectInvoiceTemplate,
  selectLoadingState,
  addInvoice,
  setActiveInvoiceId,
  replaceInvoice as replaceInvoiceStoreRecord,
  selectActiveInvoice,
  selectIsDraftInvoicePresent,
  selectLoadingError,
  selectActiveReferenceInvoice,
  selectIsActiveInvoiceModified,
  selectIsInvoicingAllowed,
  selectInvoicePeriodId,
  selectSaveState,
  selectSaveError,
  selectSubmitError,
  selectSubmitState,
  selectInvoiceClientName,
} from '../slices/invoiceDetails'
import { fetchProject, selectLoadingState as selectProjectLoadingState, selectProject } from '../slices/project'

import { InvoiceStatus, validateForSave, validateForSubmit } from '../utils/invoiceUtils'
import LoadingState from '../utils/LoadingState'

import CommentField from '../views/CommentField'
import InvoiceTable from '../views/InvoiceTable'
import SaveSubmitEditsControl from '../views/SaveSubmitEditsControl'
import TotalOnlyCheckbox from '../views/TotalOnlyCheckbox'
import InvoiceTabSelector from '../views/InvoiceTabSelector'
import { INVOICE_DRAFT_ID } from '../views/constants/InvoiceConstants'
import { SubmittedInvoiceDetailsTable } from '../views/SubmittedInvoiceDetailsTable'
import { PurchaseOrderSelector } from '../views/PurchaseOrderSelector'

// Constants --------------------------------------------------------------------
const DEFAULT_CONTAINER_WIDTH = 'calc(100vw - 113px)'
const DEFAULT_MIN_WIDTH = '400px'

const BORDER = '1px solid'
const BORDER_COLOUR = 'editable.main'

// ------------------------------------------------------------------------------

const InvoicingError = ({ errorMessage, periodId, projectId, retryCallback }) => {
  var hasSpecialError =
    typeof errorMessage === 'string' && errorMessage.includes('No billable contract line items found for this project.')
  if (hasSpecialError) {
    errorMessage = (
      <p style={{ margin: 0, padding: 0 }}>
        This project has no billable contract line items.
        <br />
        <b>You need to have at least one billable Contract Line Item.</b>
        <br />
        You can manage these in the{' '}
        <Link to={`/project-details?periodId=${periodId}&projectId=${projectId}`}>Project Details</Link> page by
        pressing on the <b>Contract</b> button.
      </p>
    )
  }

  return <Error error={errorMessage} retryCallback={hasSpecialError ? null : retryCallback} />
}

const NoInvoicesForPeriodInvoicingInfo = ({ isInvoicingAllowedInPeriod }) => (
  <Box boxShadow={0} my={2.5}>
    <Box boxShadow={0} my={0}>
      <Info sx={{ m: 0 }}>
        No invoices exist for this project in the set period.
        {isInvoicingAllowedInPeriod && (
          <>
            <br />
            <b>Click the + icon above to create a new invoice for this period</b>.
          </>
        )}
      </Info>
    </Box>
  </Box>
)

const InvoicingContent = ({
  fetchInvoice,
  invoice,
  clientComment,
  onChangeClientComment,
  opsComment,
  onChangeOpsComment,
  onEditInvoiceLines,
  totalOnly,
  onChangeTotalOnly,
  disabled,
  defaultWidth,
  purchaseOrder,
  onChangePurchaseOrder,
  projectId,
  project,
  projectLoadingState,

  isEdited,
  isValidForSave,
  isValidForSubmit,
  submitValidationMessage,
  loadingState,
  onReset,
  onSave,
  onSubmit,
  isSubmitted,
  saveState,
  saveError,
  submitState,
  submitError,
  isInvoicingAllowed,
  invoicesToDisplay,
}) => {
  if (!invoice || !invoice.invoiceLines) {
    return <Error error="Something went wrong. Invoice data is missing." retryCallback={fetchInvoice} />
  }

  return (
    <Box boxShadow={1} maxWidth={'100%'}>
      <Paper
        sx={{
          borderRadius: 0,
          display: 'flex',
          flexDirection: 'column',
          p: 3,
          boxShadow: 'none',
          maxWidth: '100%',
          borderLeft: BORDER,
          borderRight: BORDER,
          borderBottom: BORDER,
          borderColor: BORDER_COLOUR,
        }}
      >
        <Box display="flex" flexDirection="row" gap={2} pb={2} flexWrap="wrap">
          {isSubmitted ? (
            <SubmittedInvoiceDetailsTable
              invoiceNumber={invoice?.xeroNumber}
              invoiceSent={invoice?.invoiceSent}
              invoiceStatusNumber={invoice?.xeroStatus}
              xeroLink={invoice?.xeroLink}
              amountOwing={invoice?.amountOwing}
              netTotal={invoice?.netValue}
              grossTotal={invoice?.grossValue}
              purchaseOrder={purchaseOrder}
              maxWidth={'100%'}
            />
          ) : (
            <PurchaseOrderSelector
              purchaseOrder={purchaseOrder}
              onChangePurchaseOrder={onChangePurchaseOrder}
              disabled={disabled}
              projectId={projectId}
              project={project}
              projectLoadingState={projectLoadingState}
            />
          )}
        </Box>
        <InvoiceTable
          defaultWidth={defaultWidth}
          editEnabled={!disabled}
          invoiceLines={invoice?.invoiceLines}
          onEditInvoiceLines={onEditInvoiceLines}
        />
        <TotalOnlyCheckbox disabled={disabled} checked={totalOnly ? true : false} onChange={onChangeTotalOnly} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: { sm: 'row', xs: 'column' },
            maxWidth: '100%',
            columnGap: 2,
            rowGap: 2,
            flexWrap: 'wrap',
          }}
        >
          <CommentField
            disabled={disabled}
            label={'Project Status Comments for Invoice' + (isSubmitted ? '' : ' *')}
            name="clientComment"
            onChange={onChangeClientComment}
            value={clientComment}
            minWidth={DEFAULT_MIN_WIDTH}
          />
          <CommentField
            clickableUrls
            disabled={disabled}
            label={'Instructions for Operations' + (isSubmitted ? '' : ' *')}
            name="opsComment"
            onChange={onChangeOpsComment}
            value={opsComment}
            minWidth={DEFAULT_MIN_WIDTH}
          />
          {isSubmitted || !isInvoicingAllowed || !invoicesToDisplay ? null : (
            <Box marginLeft="auto" alignSelf="flex-end" display="flex" flexDirection="column" sx={{ pt: 1.5 }}>
              <Typography
                alignSelf="flex-end"
                sx={{
                  clear: 'both',
                  color: 'text.light',
                  float: 'right',
                  mb: 0,
                  mt: 1,
                  fontSize: 11,
                }}
              >
                * required
              </Typography>
              <Box display="flex" flexDirection="row-reverse">
                <SaveSubmitEditsControl
                  isEdited={isEdited}
                  isValidForSave={isValidForSave}
                  isValidForSubmit={isValidForSubmit}
                  submitValidationMessage={submitValidationMessage}
                  loadingState={loadingState}
                  onReset={onReset}
                  onSave={onSave}
                  onSubmit={onSubmit}
                  submitted={isSubmitted}
                  saveState={saveState}
                  saveError={saveError}
                  submitState={submitState}
                  submitError={submitError}
                />
                <Box sx={{ minWidth: '10px' }} /> {/* spacer*/}
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

const InvoicingContainer = ({ periodId, projectId }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const projectLoadingState = useSelector(selectProjectLoadingState)

  // All invoices for this project and period
  const invoices = useSelector(selectInvoices)
  const invoiceTemplate = useSelector(selectInvoiceTemplate)
  const invoiceDraftInProgress = useSelector(selectIsDraftInvoicePresent)
  const invoicePeriodId = useSelector(selectInvoicePeriodId)

  // The active original invoice (as is stored in the database). This doesn't include any unsaved edits
  const activeReferenceInvoice = useSelector(selectActiveReferenceInvoice)

  // The active invoice with any edits made
  const activeInvoice = useSelector(selectActiveInvoice)

  // True if there is a deviation between the active reference invoice and the editable version of the invoice
  const isActiveInvoiceModified = useSelector(selectIsActiveInvoiceModified)

  // True if invoicing is allowed for the current period
  const isInvoicingAllowedInPeriod = useSelector(selectIsInvoicingAllowed)

  const clientName = useSelector(selectInvoiceClientName)

  const invoiceLoadingError = useSelector(selectLoadingError)
  const invoiceLoadingState = useSelector(selectLoadingState)
  const saveState = useSelector(selectSaveState)
  const saveError = useSelector(selectSaveError)
  const submitState = useSelector(selectSubmitState)
  const submitError = useSelector(selectSubmitError)

  // Varies depending on client config
  const isClientLevelInvoicingAllowed = useClientContext()?.invoiceSubmissionEnabled

  // The store acts as the source of truth for the active invoice ID. It is, however, synchronised with the query param
  // invoice ID.
  const [activeInvoiceIdQueryParam, setActiveInvoiceIdQueryParam] = useQueryParam('invoiceId', NumberParam)

  const isInvoicingAllowed = isInvoicingAllowedInPeriod && isClientLevelInvoicingAllowed

  // Callbacks ---------------------------------------------------------------------------------------------------------
  const replaceInvoiceInStore = useCallback(
    ({ invoiceToReplaceId, replacementInvoice }) => {
      dispatch(
        replaceInvoiceStoreRecord({
          invoiceToReplaceId,
          updatedInvoice: replacementInvoice,
        })
      )
    },
    [dispatch]
  )

  const clearEdits = useCallback(() => {
    // Replace the active invoice with the original unmodified invoice
    replaceInvoiceInStore({
      invoiceToReplaceId: activeInvoice?.id,
      replacementInvoice: activeReferenceInvoice,
    })
  }, [activeReferenceInvoice, activeInvoice?.id, replaceInvoiceInStore])

  const handleInvoiceEdit = useCallback(
    (editedFields) => {
      const editedInvoice = { ...activeInvoice, ...editedFields }
      replaceInvoiceInStore({
        invoiceToReplaceId: editedInvoice.id,
        replacementInvoice: editedInvoice,
      })
    },
    [activeInvoice, replaceInvoiceInStore]
  )

  const fetchInvoice = useCallback(() => {
    if (periodId > 0 && projectId > 0) {
      dispatch(fetchInvoiceDetails({ projectId, periodId, getTokenCallback }))
    }
  }, [dispatch, periodId, projectId, getTokenCallback])

  const setActiveInvoiceById = useCallback(
    (invoiceId) => {
      // Update the query parameter to match the source of truth (the store)
      setActiveInvoiceIdQueryParam(invoiceId)
      dispatch(setActiveInvoiceId(invoiceId))
    },
    [dispatch, setActiveInvoiceIdQueryParam]
  )

  const saveInvoice = useCallback(() => {
    dispatch(saveInvoiceAction({ invoice: activeInvoice, getTokenCallback }))
  }, [dispatch, getTokenCallback, activeInvoice])

  const submitInvoice = useCallback(() => {
    dispatch(submitInvoiceAction({ invoice: activeInvoice, getTokenCallback }))
  }, [dispatch, getTokenCallback, activeInvoice])

  const createDraftInvoice = useCallback(() => {
    // Backend identified the saved invoice as a draft based on this ID and will create a new record for it
    dispatch(addInvoice({ newInvoice: { ...invoiceTemplate, id: INVOICE_DRAFT_ID } }))
    setActiveInvoiceById(INVOICE_DRAFT_ID)
  }, [dispatch, setActiveInvoiceById, invoiceTemplate])

  // -------------------------------------------------------------------------------------------------------------------

  // Effects -----------------------------------------------------------------------------------------------------------
  // This check accounts for initial loading and also reopening the page with a different period (changed in a different page)
  useEffect(() => {
    if (periodId !== invoicePeriodId && [LoadingState.fulfilled, LoadingState.idle].includes(invoiceLoadingState)) {
      clearEdits()
      fetchInvoice()
    }
  }, [clearEdits, fetchInvoice, invoiceLoadingState, periodId, invoicePeriodId])

  // Invoice retrieval logic on page load
  useEffect(() => {
    if (projectLoadingState === LoadingState.idle && projectId) {
      dispatch(fetchProject({ getTokenCallback, projectId }))
    }
  }, [dispatch, getTokenCallback, projectLoadingState, projectId])

  // Purchase order logic
  // If a project doesn't have a set purchase order, get the first project purchase order and apply it to the invoice
  // so that on save, it will be recorded in the DB
  useEffect(() => {
    if (activeInvoice && project) {
      // true if no purchase order has been assigned to the current invoice
      const invoicePurchaseOrderUnset =
        activeInvoice.purchaseOrder === undefined || activeInvoice.purchaseOrder === null

      // Used for checking if the purchase order associated with an invoice is no longer valid (e.g. deleted
      // from the project )
      const invoicePurchaseOrderInvalid = !project?.purchaseOrders?.includes(activeInvoice.purchaseOrder)

      const invoiceEditable = activeInvoice.status !== InvoiceStatus.final

      // If the existing invoice purchase order hasn't been set or is invalid, the invoice hasn't yet been submitted, and invoicing is
      // allowed for the period and for the client
      if ((invoicePurchaseOrderUnset || invoicePurchaseOrderInvalid) && invoiceEditable && isInvoicingAllowed) {
        const newPurchaseOrder = project?.purchaseOrders?.[0]
        if (newPurchaseOrder) {
          handleInvoiceEdit({ purchaseOrder: newPurchaseOrder })
        }
      }
    }
  }, [handleInvoiceEdit, activeInvoice, project, isInvoicingAllowed])

  // Invoice selection logic on page loading and period / project change
  useEffect(() => {
    if (invoiceLoadingState === LoadingState.fulfilled) {
      // If the invoice ID query param is set and the invoice ID is in the list of available invoices, select that invoice
      if (activeInvoiceIdQueryParam && invoices.some((invoice) => invoice.id === activeInvoiceIdQueryParam)) {
        setActiveInvoiceById(activeInvoiceIdQueryParam)
      }

      // If no query param is set or the set value isn't available, yet invoices are available, select the most recent invoice
      else if (
        invoices?.length > 0 &&
        (activeInvoice === null || // If no invoice has been selected on page load yet invoices are available
          !invoices.some((invoice) => invoice.id === activeInvoice?.id)) // If the selected invoice is not in the list of available invoices (e.g. time period was changed)
      ) {
        const invoiceIndex = invoices.length - 1
        setActiveInvoiceById(invoices[invoiceIndex].id) // Select the most recent invoice
      }

      // No invoices are available, reset selected invoice
      else if (invoices.length === 0) {
        setActiveInvoiceById(null)
      }
    }
  }, [setActiveInvoiceById, activeInvoice, invoices, activeInvoiceIdQueryParam, invoiceLoadingState])

  // Default the total only checkbox box to true if the invoice does not have a total only value explicitly set
  useEffect(() => {
    const invoiceTotalOnly = activeInvoice?.totalOnly

    if (invoiceTotalOnly === undefined || invoiceTotalOnly === null) {
      handleInvoiceEdit({ totalOnly: true })
    }
  }, [activeInvoice, handleInvoiceEdit])

  // -------------------------------------------------------------------------------------------------------------------

  if (invoiceLoadingState === LoadingState.idle) {
    return <Info>Please select a project.</Info>
  } else if (invoiceLoadingState === LoadingState.pending) {
    return <Loading />
  } else if (invoiceLoadingState === LoadingState.rejected) {
    return (
      <InvoicingError
        errorMessage={invoiceLoadingError}
        periodId={periodId}
        projectId={projectId}
        retryCallback={fetchInvoice}
      />
    )
  } else {
    const isFinal = activeInvoice?.status === InvoiceStatus.final
    const isSaving = saveState === LoadingState.pending || submitState === LoadingState.pending
    const disabled = isFinal || isSaving || !isInvoicingAllowedInPeriod
    const isUnsyncedDraft = activeInvoice?.id === INVOICE_DRAFT_ID

    const invoicesToDisplay = invoices.length > 0 && activeInvoice !== null

    const isValidForSave = validateForSave(activeInvoice)
    const { isValidForSubmit, submitValidationMessage } = validateForSubmit(activeInvoice)

    const isEdited = isActiveInvoiceModified || isUnsyncedDraft

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: DEFAULT_CONTAINER_WIDTH }}>
        <InvoiceProjectDetailsTable
          clientName={clientName}
          projectLoadingState={projectLoadingState}
          project={project}
          border={BORDER}
          borderColour={BORDER_COLOUR}
        />
        <Box sx={{ display: 'inline-flex', flexDirection: 'column', mt: 1.5 }}>
          {invoicesToDisplay || isInvoicingAllowed ? (
            <InvoiceTabSelector
              displayTabs={invoicesToDisplay}
              invoices={invoices}
              selectedInvoiceId={activeInvoice?.id}
              setInvoiceId={setActiveInvoiceById}
              newInvoiceButtonAction={createDraftInvoice}
              disableNewInvoiceButton={invoiceDraftInProgress || !isInvoicingAllowed}
              border={BORDER}
              borderColour={BORDER_COLOUR}
            />
          ) : null}

          {invoicesToDisplay ? (
            <InvoicingContent
              fetchInvoice={fetchInvoice}
              invoice={activeInvoice}
              clientComment={activeInvoice?.clientComment || null}
              onChangeClientComment={(clientComment) => handleInvoiceEdit({ clientComment })}
              opsComment={activeInvoice?.opsComment || null}
              onChangeOpsComment={(opsComment) => handleInvoiceEdit({ opsComment })}
              onEditInvoiceLines={(invoiceLines) => handleInvoiceEdit({ invoiceLines })}
              totalOnly={activeInvoice?.totalOnly}
              onChangeTotalOnly={(totalOnly) => handleInvoiceEdit({ totalOnly })}
              disabled={disabled}
              defaultWidth={DEFAULT_CONTAINER_WIDTH}
              purchaseOrder={activeInvoice?.purchaseOrder || '-'}
              onChangePurchaseOrder={(purchaseOrder) => handleInvoiceEdit({ purchaseOrder })}
              projectId={projectId}
              project={project}
              projectLoadingState={projectLoadingState}
              /* Save and submit controls logic*/
              isEdited={isEdited}
              isValidForSave={isValidForSave}
              isValidForSubmit={isValidForSubmit}
              submitValidationMessage={submitValidationMessage}
              loadingState={invoiceLoadingState}
              onReset={clearEdits}
              onSave={saveInvoice}
              onSubmit={submitInvoice}
              isSubmitted={isFinal}
              saveState={saveState}
              saveError={saveError}
              submitState={submitState}
              submitError={submitError}
              isInvoicingAllowed={isInvoicingAllowed}
              invoicesToDisplay={invoicesToDisplay}
            />
          ) : null}
        </Box>
        {invoicesToDisplay ? null : (
          <NoInvoicesForPeriodInvoicingInfo isInvoicingAllowedInPeriod={isInvoicingAllowedInPeriod} />
        )}
      </Box>
    )
  }
}

export default InvoicingContainer
