import moment from 'moment'

import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid'

import config from '../config'

import {
  default as FilterInput,
  FilterDateInput,
  FilterMoneyInput,
  FilterSelectInput,
} from '../primitives/FilterInput'

import GridCellExpand from '../views/GridCellExpand'
import MoneyEditCell from '../views/MoneyEditCell'
import PercentEditCell from '../views/PercentEditCell'
import { Box, Tooltip } from '@mui/material'

function isValidString(value) {
  return value !== undefined && value !== null && value !== ''
}

function isValidNumberString(value) {
  return isValidString(value) && !isNaN(Number(value))
}

function numberToCurrencyString(value) {
  return value.toLocaleString('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}

function renderBudgetCell(params) {
  const stringVal = renderMoneyCell(params)

  if (params.row.priceModel === 'Time and Expense') {
    return <span style={{ fontStyle: 'italic' }}>{`${stringVal}*`}</span>
  } else {
    return stringVal
  }
}

function renderDateCell(params) {
  if (isValidString(params.value)) {
    return moment(params.value).format('YYYY-MM-DD')
  }

  return ''
}

function renderJiraKeyCell(params) {
  if (isValidString(params.value)) {
    return (
      <GridCellExpand
        value={params.value}
        width={params.colDef.width}
        linkUrl={`${config.jira.baseUrl}/browse/${params.row.jiraKey}`}
      />
    )
  }

  return ''
}

function renderMoneyCell(params) {
  if (isValidNumberString(params.value)) {
    var numberValue = Number(params.value)
    if (numberValue < 0) {
      return `(${numberToCurrencyString(-numberValue)})`
    } else {
      return numberToCurrencyString(numberValue)
    }
  }

  return ''
}

function renderMoneyEditCell(params) {
  return <MoneyEditCell {...params} />
}

function renderPercentCell(params) {
  if (isValidNumberString(params.value)) {
    return (Number(params.value) / 100).toLocaleString('en-NZ', {
      style: 'percent',
      maximumSignificantDigits: 2,
    })
  }

  return ''
}

function renderPercentEditCell(params) {
  return <PercentEditCell {...params} />
}

function renderProjectNameCell(params) {
  if (isValidString(params.value)) {
    return (
      <GridCellExpand
        value={params.value}
        width={params.colDef.width}
        linkUrl={`/project-details?projectId=${params.row.projectId}`}
      />
    )
  }

  return ''
}

/**
 * Render a single select cell (dropdown cell)
 * This is intended to be used with the MUI renderCell function.
 * NB: Currently specific to rendering approach method cells based on the lookup of values within approach objects
 * @param {*} valueMap Object with lookup IDs as keys that map to values that should be displayed in the cell
 * @param {*} tooltipMap Object with lookup IDs as keys that map tooltip strings displayed whole hovering over the cell
 * @returns Lambda function that takes a param object. This param.value will be used to find the associated key in the
 *    objects within the lookupArr array. The lambda function will then return JSX based on this
 */
function renderSingleSelectCell(valueMap = {}, tooltipMap = {}) {
  if (Object.keys(valueMap).length > 0) {
    return (params) => {
      const lookupKey = params.value // Underlying key representing the value being displayed
      return (
        <Tooltip title={tooltipMap[lookupKey]}>
          <Box>{valueMap[lookupKey]}</Box>
        </Tooltip>
      )
    }
  }
  return () => ''
}

/**
 * Converts a given key to a given value based on the input lookup array and parameter. For use with MUI's DataGrid valueFormatter field.
 * @param {*} valueMap Object with lookup IDs as keys that map to formatted values
 * @returns lambda function. This Lambda function takes a params object as an argument and returns string of the formatted value
 */
function valueFormatSingleSelectCell(valueMap) {
  // Lambda function is called by the mui valueFormatter and passed a params object.
  // Value to format is represented in the .value parameter
  return (params) => {
    const lookupKey = params.value
    if (lookupKey === undefined) {
      return ''
    }
    return valueMap[lookupKey]
  }
}

function renderTextCell(params) {
  if (isValidString(params.value)) {
    return <GridCellExpand value={params.value} width={params.colDef.width} />
  }

  return ''
}

// const isnullFilterOperator = {
//   value: 'isnull',
//   getApplyFilterFn: (filterItem) => {
//     if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
//       return null;
//     }
//     return ({ value }) => value === null;
//   },
// }

const defaultColumns = [
  'jiraKey',
  'clientName',
  'projectName',
  'projectManager',
  'projectDirector',
  'clientManager',
  'initialBudget',
  'historicVariations',
  'projectBudget',
  'billedToDate',
  'historicExpenses',
  'wipForPeriod',
  'historicUnbilledWip',
  'totalWip',
  'periodExpenses',
  'invoiceAmount',
  'expectedVariations',
  'percentComplete',
  'provisionalAmount',
  'writeUpOffAmount',
  'writeUpOffToDate',
  'agedDebt',
  'agedDebt3Months',
  'projectStartDate',
  'projectEndDate',
]

const makeColumns = (
  classes,
  {
    cwbColumns = defaultColumns,
    enableEditWriteOff = false,
    hiddenColumns = [], // user-defined and adjustable via UI
    editable = true,
    sortable = false,
  },
  {
    invoicingApproaches: {
      valueMap: invoicingApproachValues = {},
      tooltipMap: invoicingApproachTooltips = {},
      valueOptions: invoicingApproachOptions = [],
    } = {},
  } = {}
) => {
  const getCellClassNameCallback =
    ({ displayPositiveAsRed = false } = {}) =>
    (params) => {
      var classNames = ''
      if (params.isEditable) {
        classNames = 'editableCell'
      }
      if (displayPositiveAsRed && params.value > 0) {
        classNames += ' redCell'
      } else {
        classNames += ' normalCell'
      }

      return classNames
    }

  const commonColumnParams = {
    headerClassName: classes.header,
    cellClassName: getCellClassNameCallback(),
    sortable,
    filterable: true,
  }

  const textColumnParams = {
    ...commonColumnParams,
    type: 'string',
    renderCell: renderTextCell,
    filterOperators: [
      ...getGridStringOperators()
        .filter(({ value }) => ['contains'].includes(value))
        .map((operator) => ({
          ...operator,
          InputComponent: FilterInput,
        })),
      // isnullFilterOperator
    ],
  }

  const nameColumnParams = {
    ...textColumnParams,
    width: 130,
  }

  const moneyColumnParams = {
    ...commonColumnParams,
    type: 'number',
    renderCell: renderMoneyCell,
    filterOperators: [
      ...getGridNumericOperators()
        .filter(({ value }) => ['=', '>', '<', '>=', '<='].includes(value))
        .map((operator) => ({
          ...operator,
          InputComponent: FilterMoneyInput,
        })),
      // isnullFilterOperator
    ],
    width: 105,
  }

  const percentColumnParams = {
    ...commonColumnParams,
    type: 'number',
    renderCell: renderPercentCell,
    valueParser: (value) => value,
    width: 105,
    filterOperators: [
      ...getGridNumericOperators()
        .filter(({ value }) => ['=', '>', '<', '>=', '<='].includes(value))
        .map((operator) => ({ ...operator, InputComponent: FilterInput })),
    ],
  }

  const immutableMoneyColumnParams = {
    ...moneyColumnParams,
  }

  const editableMoneyColumnParams = {
    ...moneyColumnParams,
    renderEditCell: renderMoneyEditCell,
    valueParser: (value) => value,
    editable,
  }

  const editablePercentColumnParams = {
    ...commonColumnParams,
    type: 'number',
    renderCell: renderPercentCell,
    renderEditCell: renderPercentEditCell,
    valueParser: (value) => value,
    editable,
    width: 105,
    filterOperators: [
      ...getGridNumericOperators()
        .filter(({ value }) => ['=', '>', '<', '>=', '<='].includes(value))
        .map((operator) => ({ ...operator, InputComponent: FilterInput })),
      // isnullFilterOperator,
    ],
  }

  const dateColumnParams = {
    ...commonColumnParams,
    type: 'date',
    filterOperators: [
      ...getGridDateOperators(false)
        .filter(({ value }) => ['before', 'after'].includes(value))
        .map((operator) => ({
          ...operator,
          InputComponent: FilterDateInput,
        })),
      // isnullFilterOperator
    ],
    renderCell: renderDateCell,
    width: 85,
  }

  const getSingleSelectColumnParams = (valueMap, tooltipMap) => ({
    ...commonColumnParams,
    editable,
    type: 'singleSelect',
    valueFormatter: valueFormatSingleSelectCell(valueMap),
    renderCell: renderSingleSelectCell(valueMap, tooltipMap),
    filterOperators: [
      ...getGridSingleSelectOperators()
        .filter(({ value }) => value === 'is')
        .map((operator) => ({
          ...operator,
          InputComponent: FilterSelectInput(valueMap),
        })),
    ],
  })

  var columnDefs = {
    jiraKey: {
      ...textColumnParams,
      headerName: 'Jira Key',
      width: 70,
      renderCell: renderJiraKeyCell,
    },
    clientName: {
      ...textColumnParams,
      headerName: 'Client',
      width: 165,
    },
    projectName: {
      ...textColumnParams,
      headerName: 'Project',
      width: 350,
      renderCell: renderProjectNameCell,
    },
    projectManager: {
      ...nameColumnParams,
      headerName: 'Project Manager',
    },
    projectDirector: {
      ...nameColumnParams,
      headerName: 'Project Director',
    },
    clientManager: {
      ...nameColumnParams,
      headerName: 'Client Manager',
    },
    initialBudget: {
      ...immutableMoneyColumnParams,
      headerName: 'Initial Budget',
      description:
        'The initial budget allocated to the project. Agreed upon in contract with the client at the start of the project - including on-charged expenses, but excluding GST.',
      width: 90,
    },
    historicVariations: {
      ...immutableMoneyColumnParams,
      headerName: 'Variations',
      description:
        'All budget variations on the project up until the beginning of this financial period, including any expected variations from the previous period - including on-charged expenses, but excluding GST.',
      width: 110,
    },
    projectBudget: {
      ...immutableMoneyColumnParams,
      headerName: 'Total Budget',
      description:
        'The total budget allocated to this project, over the course of its life. Includes initial budget, plus any variations - including on-charged expenses, but excluding GST.',
      renderCell: renderBudgetCell,
      width: 90,
    },
    billedToDate: {
      ...immutableMoneyColumnParams,
      headerName: 'Billed To Date',
      description:
        'The total amount invoiced to the client since the project began, but before this period, including on-charged expenses but excluding GST.',
    },
    historicExpenses: {
      ...immutableMoneyColumnParams,
      headerName: 'Historic Expenses',
      description:
        'All on-charged expenses invoiced to the client since the project began, but before this period, excluding GST.',
    },
    wipForPeriod: {
      ...immutableMoneyColumnParams,
      headerName: 'Period WIP',
      description:
        'Cost of work completed this billing period. Calculated from work logs and hourly rates.',
      width: 90,
    },
    historicUnbilledWip: {
      ...immutableMoneyColumnParams,
      headerName: 'Historic WIP',
      description:
        'Cost of work performed and expenses from previous periods, less amounts billed to date or written off.',
    },
    totalWip: {
      ...immutableMoneyColumnParams,
      headerName: 'Total WIP',
      description:
        "'Period WIP' plus 'Historic WIP'. Total cost of work that has not been billed or written off to date.",
      width: 90,
    },
    periodExpenses: {
      ...editableMoneyColumnParams,
      headerName: 'Period Expenses',
      description:
        'On-charged expenses invoiced to the client this period, excluding GST.',
    },
    invoiceAmount: {
      ...editableMoneyColumnParams,
      headerName: 'Period Invoices',
      description:
        'The total amount invoiced to the client on this project for this period, including on-charged expenses but excluding GST. This can be the total of one or more invoices.',
    },
    wipBalance: {
      ...moneyColumnParams,
      headerName: 'WIP Balance',
      description:
        "'Total WIP' plus 'Period Expenses' minus 'Period Invoices'. This is the remaining WIP balance on the project after the invoicing stage.",
    },
    invoicingApproach: {
      ...getSingleSelectColumnParams(
        invoicingApproachValues,
        invoicingApproachTooltips
      ),
      headerName: 'Approach',
      description: 'To indicate the approach for invoicing for the project.',
      valueOptions: invoicingApproachOptions,
      width: 105,
    },
    expectedVariations: {
      ...editableMoneyColumnParams,
      headerName: 'Expected Variations',
      description:
        'The best estimate by the project manager of what budget variation will be received this period. This does not require the variation to be submitted or approved and the estimate can be less than what is asked for.',
      width: 110,
    },
    percentComplete: {
      ...editablePercentColumnParams,
      headerName: 'Percent Complete',
      description:
        'Best estimate of the percentage of the project complete so far.',
    },
    provisionalAmount: {
      ...editableMoneyColumnParams,
      headerName: 'Provision',
      description:
        'The requested write up (negative values) or write off (positive values) that the PD has approved for this period.',
      cellClassName: getCellClassNameCallback({ displayPositiveAsRed: true }),
    },
    writeUpOffAmount: {
      ...editableMoneyColumnParams,
      editable: enableEditWriteOff && editable,
      headerName: 'Period Write Off',
      description:
        'The write up (negative values) or write off (positive values) that has been officially accepted by Awa for the project for this period.',
      cellClassName: getCellClassNameCallback({ displayPositiveAsRed: true }),
    },
    writeUpOffToDate: {
      ...immutableMoneyColumnParams,
      headerName: 'Historic Write Off',
      description:
        'The total amount written up (negative values) or written off (positive values) for the project for all previous periods.',
      cellClassName: getCellClassNameCallback({ displayPositiveAsRed: true }),
    },
    agedDebt: {
      ...immutableMoneyColumnParams,
      headerName: 'Aged Debt 1 Month',
      description: 'Debt unpaid over 1 month past due.',
      width: 110,
      cellClassName: getCellClassNameCallback({ displayPositiveAsRed: true }),
    },
    agedDebt3Months: {
      ...immutableMoneyColumnParams,
      headerName: 'Aged Debt 3 Months',
      description: 'Debt unpaid over 3 months past due.',
      width: 110,
      cellClassName: getCellClassNameCallback({ displayPositiveAsRed: true }),
    },
    projectStartDate: {
      ...dateColumnParams,
      headerName: 'Start Date',
      description: 'The date this project began.',
    },
    projectEndDate: {
      ...dateColumnParams,
      headerName: 'End Date',
      description: 'The date this project is expected to finish.',
    },
    internalBudget: {
      ...immutableMoneyColumnParams,
      headerName: 'Internal Budget',
    },
    projectedProfit: {
      ...immutableMoneyColumnParams,
      headerName: 'Projected Profit',
      cellClassName: 'calculatedCell',
      width: 110,
    },
    projectedMargin: {
      ...percentColumnParams,
      headerName: 'Projected Margin',
      cellClassName: 'calculatedCell',
      width: 110,
    },
    profitToDate: {
      ...immutableMoneyColumnParams,
      headerName: 'Profit to Date',
      cellClassName: 'calculatedCell',
    },
    marginToDate: {
      ...percentColumnParams,
      headerName: 'Margin to Date',
      cellClassName: 'calculatedCell',
    },
    periodProfit: {
      ...immutableMoneyColumnParams,
      headerName: 'Period Profit',
      cellClassName: 'calculatedCell',
    },
    periodMargin: {
      ...percentColumnParams,
      headerName: 'Period Margin',
      cellClassName: 'calculatedCell',
    },
  }

  // 'hidden' columns sets the hide property to true, but retains column definition in the dataGrid
  const columns = cwbColumns.map((columnName) => ({
    ...columnDefs[columnName],
    field: columnName,
    hide: hiddenColumns.includes(columnName),
  }))

  return columns
}

export { makeColumns }

/**
 * These functions are not intended to be used outside of column.js
 * They should only be imported within unit tests
 */
export const exportedForTesting = {
  valueFormatSingleSelectCell,
}
