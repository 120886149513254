import moment from 'moment'

export function getCurrentAndPreviousPeriods(periods) {
  var today = moment()
  var relevantPeriods = []

  for (var i in periods.sort((a, b) => a.periodStart - b.periodStart)) {
    var p = periods[i]
    if (moment(p.periodStart).isSameOrBefore(today, 'day')) {
      relevantPeriods.push(p)
    }
  }

  return relevantPeriods
}

export function getPrettyName(periods, periodId) {
  if (periods && periods.length > 0 && periodId) {
    const period = periods.find((period) => period.id === periodId)
    return moment(period.periodEnd).format('MMM YYYY')
  }

  return ''
}
