import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { Dialog, DialogActions, DialogContent } from '@mui/material'

import {
  reset,
  resetUpdateError,
  updateProject,
  selectLoadingState,
  selectProject,
  selectUpdateError as selectUpdateProjectError,
  selectUpdateState as selectUpdateProjectState,
} from '../slices/project'

import LoadingState from '../utils/LoadingState'
import DialogTitleWithClose from '../views/DialogTitleWithClose'
import SaveEditsControl from '../views/SaveEditsControl'
import StringListEditor from '../views/StringListEditor'
import StringListEditorAddButton from '../views/StringListEditorAddButton'

const ProjectPurchaseOrdersDialog = ({ onClose, open }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const loadingState = useSelector(selectLoadingState)
  const project = useSelector(selectProject)
  const updateError = useSelector(selectUpdateProjectError)
  const updateState = useSelector(selectUpdateProjectState)

  const [purchaseOrders, setPurchaseOrders] = useState([])

  const handleChangePurchaseOrders = useCallback(
    (value) => {
      setPurchaseOrders(value)
      dispatch(resetUpdateError())
    },
    [dispatch, setPurchaseOrders]
  )

  const saveProject = useCallback(() => {
    dispatch(
      updateProject({
        project: { ...project, purchaseOrders: purchaseOrders },
        getTokenCallback,
      })
    )
  }, [dispatch, project, purchaseOrders, getTokenCallback])

  // update purchase orders when project value changes
  useEffect(() => {
    if (
      loadingState === LoadingState.fulfilled &&
      project &&
      project.purchaseOrders &&
      project.purchaseOrders.length !== 0 &&
      purchaseOrders.length === 0
    ) {
      handleChangePurchaseOrders(
        project.purchaseOrders ? project.purchaseOrders : []
      )
    }
  }, [loadingState, project, purchaseOrders, handleChangePurchaseOrders])

  // reset to refresh projects when changes are saved
  useEffect(() => {
    if (updateState === LoadingState.fulfilled) {
      dispatch(reset())
    }
  }, [dispatch, updateState])

  const loadingOrSaving = useMemo(() => {
    return (
      loadingState === LoadingState.pending ||
      updateState === LoadingState.pending
    )
  }, [loadingState, updateState])

  const isEdited = useMemo(() => {
    if (project && project.purchaseOrders) {
      if (purchaseOrders.length !== project.purchaseOrders.length) {
        return true
      }

      for (const idx in purchaseOrders) {
        if (project.purchaseOrders[idx] !== purchaseOrders[idx]) {
          return true
        }
      }
    } else if (purchaseOrders.length > 0) {
      return true
    }

    return false
  }, [project, purchaseOrders])

  const isValid = useMemo(() => {
    for (const value of purchaseOrders) {
      if (!value) {
        return false
      }
    }

    return true
  }, [purchaseOrders])

  if (!project) {
    return null
  } else {
    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitleWithClose
          onClose={onClose}
          title="Purchase Order Numbers"
        />
        <DialogContent sx={{ px: 3, py: 1 }}>
          <StringListEditor
            disabled={loadingOrSaving}
            originalList={project.purchaseOrders}
            list={purchaseOrders}
            onChange={handleChangePurchaseOrders}
          />
        </DialogContent>
        <DialogActions>
          <div style={{ clear: 'both', width: '100%' }}>
            <StringListEditorAddButton
              disabled={loadingOrSaving}
              list={purchaseOrders}
              onChange={handleChangePurchaseOrders}
            />
            <SaveEditsControl
              isEdited={isEdited}
              isValid={isValid}
              loadingState={loadingState}
              onReset={() => handleChangePurchaseOrders([])}
              onSave={saveProject}
              updateError={updateError}
              updateState={updateState}
            />
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ProjectPurchaseOrdersDialog
