import SelectorButtons from "../primitives/SelectorButtons"

export const scopes = {
  all: 'all',
  billable: 'billable',
  unbillable: 'unbillable',
}

const TimeCostReportScopeSelector = ({
  onScopeChange,
  scope,
}) => {

  const buttonLabels = {
    [scopes.all]: 'All',
    [scopes.billable]: 'Billable',
    [scopes.unbillable]: 'Non-Billable',
  }

  const buttons = Object.entries(buttonLabels).map(([scopeName, label]) => ({
    children: label,
    onClick: () => onScopeChange(scopeName),
    selected: scope === scopeName,
  }))

  return <SelectorButtons buttons={buttons} sx={{ float: 'left', mr: 2 }} />
}

export default TimeCostReportScopeSelector
