const MuiInputBase = {
  styleOverrides: {
    root: {
      color: 'inherit',
      font: 'inherit',
      ":hover": {
        ":before": {
          borderBottom: "0px solid rgba(0, 0, 0, 0.42) !important"
        }
      }
    }
  }
}

export default MuiInputBase