import incompleteIcon from '../assets/progress-incomplete.png'
import completeIcon from '../assets/progress-complete.png'
import SelectorButtons from '../primitives/SelectorButtons'

const COMPLETED = "COMPLETED"
const IN_PROGRESS = "IN-PROGRESS"
const statusTypes = { COMPLETED, IN_PROGRESS }

// returns a filter function
const getCompletionStatusFilter = (completionStatusTypes = []) =>
  ({ percentComplete = 0 }) => {
    if (completionStatusTypes.length === 0) { return true }
    let filter = false
    if (completionStatusTypes.includes(COMPLETED)) {
      filter = filter || (percentComplete === 100)
    }
    if (completionStatusTypes.includes(IN_PROGRESS)) {
      filter = filter || (percentComplete < 100 && percentComplete > 0)
    }
    return filter
  }


const CompanyWorkbookCompletionStatusSelector = ({
  onToggleStatusType,
  statusTypes,
}) => {

  const viewModeButtons = [
    {
      children: <img
        src={incompleteIcon}
        alt="Progress Bar - Incomplete"
        style={{ filter: 'invert(100%)', width: 50 }}
      />,
      onClick: () => { onToggleStatusType(IN_PROGRESS) },
      selected: statusTypes.includes(IN_PROGRESS),
    },
    {
      children: <img
        src={completeIcon}
        alt="Progress Bar - Complete"
        style={{ filter: 'invert(100%)', width: 50 }}
      />,
      onClick: () => { onToggleStatusType(COMPLETED) },
      selected: statusTypes.includes(COMPLETED),
    },
  ]

  return <SelectorButtons buttons={viewModeButtons} />
}

export {
  CompanyWorkbookCompletionStatusSelector as default,
  statusTypes,
  getCompletionStatusFilter,
}
