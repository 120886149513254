import moment from 'moment'

export function diff(start, finish) {
  var seconds = moment(finish).diff(moment(start), 'seconds')
  if (seconds < 60) {
    return `${seconds} seconds`
  } else {
    const minutes = Math.floor(seconds / 60)
    seconds = seconds % 60

    if (minutes === 0) {
      return `${seconds} ${seconds > 1 ? 'seconds' : 'second'}`
    } else if (seconds === 0) {
      return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`
    } else {
      return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} and ${seconds} ${seconds > 1 ? 'seconds' : 'second'
        }`
    }
  }
}

export function formatHours(value) {
  return value
    ? value.toLocaleString('en-NZ', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    : '-'
}

export function formatMoney(money) {
  return money
    ? money.toLocaleString('en-NZ', {
      style: 'currency',
      currency: 'NZD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    : '-'
}

export function timestamp(timestamp) {
  return moment(timestamp).format('D MMMM YYYY H:mm')
}

export const formatKeyString = (date) => moment(date).format('yyyy-MM-DDT00:00:00')