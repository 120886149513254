import { useCallback, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParam, NumberParam, withDefault } from 'use-query-params'

import Error from '../primitives/Error'
import Loading from '../primitives/Loading'
import {
  fetchPeriods as fetchPeriodsAction,
  selectCurrentAndPastPeriods,
  selectLoadingState as selectPeriodsLoadingState,
} from '../slices/periods'
import PeriodSelector from '../views/PeriodSelector'
import LoadingState from '../utils/LoadingState'

const PeriodSelectorContainer = ({ onChange, ...props }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const [periodId, setPeriodId] = useQueryParam(
    'periodId',
    withDefault(NumberParam, -1)
  )

  const periods = useSelector(selectCurrentAndPastPeriods)
  const loadingState = useSelector(selectPeriodsLoadingState)

  const handleChangePeriod = useCallback(
    (newPeriodId) => {
      setPeriodId(newPeriodId)
      onChange()
    },
    [onChange, setPeriodId]
  )

  const fetchPeriods = useCallback(() => {
    dispatch(fetchPeriodsAction({ getTokenCallback }))
  }, [dispatch, getTokenCallback])

  useEffect(() => {
    if (loadingState === LoadingState.idle) {
      fetchPeriods()
    }
  }, [fetchPeriods, loadingState])

  useEffect(() => {
    if (loadingState === LoadingState.fulfilled && periodId === -1 && periods) {
      if (periods.length === 1) {
        setPeriodId(periods[0].id)
      } else if (periods.length >= 2) {
        setPeriodId(periods[periods.length - 2].id)
      }
    }
  }, [fetchPeriods, periodId, periods, loadingState, setPeriodId])

  if (
    loadingState === LoadingState.idle ||
    loadingState === LoadingState.pending
  ) {
    return <Loading />
  } else if (loadingState === LoadingState.rejected) {
    return <Error error={periods} retryCallback={fetchPeriods} />
  } else {
    return (
      <PeriodSelector
        periods={periods}
        value={periodId === -1 ? '' : periodId}
        onChange={(event) => handleChangePeriod(event.target.value)}
        {...props}
      />
    )
  }
}

export default PeriodSelectorContainer
