import { useCallback } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Box } from '@mui/material'

import LineItemDroppable from './LineItemDroppable'
import UnmappedItemsDroppable from './UnmappedItemsDroppable'
import {
  getMappedWorkItems,
  getUnmappedWorkItems,
  getUnassignedItem,
} from '../utils/mappingUtils'

const WorkItemMapper = ({
  disabled,
  mappingData,
  setMappingData,
  onUpdate,
}) => {

  const handleDragEnd = useCallback(
    (result) => {
      const { destination, source, draggableId } = result

      if (!destination) {
        return
      } else if (source.droppableId === destination.droppableId) {
        if (destination.index === source.index) {
          return
        }
        const sourceLineItem = mappingData.lineItems[source.droppableId]
        const updatedJiraKeys = Array.from(sourceLineItem.jiraKeys)
        updatedJiraKeys.splice(source.index, 1)
        updatedJiraKeys.splice(destination.index, 0, draggableId)

        const updatedLineItem = {
          ...sourceLineItem,
          jiraKeys: updatedJiraKeys,
        }

        const updatedState = {
          ...mappingData,
          lineItems: {
            ...mappingData.lineItems,
            [updatedLineItem.id]: updatedLineItem,
          },
        }

        setMappingData(updatedState)
      } else {
        const sourceLineItem = mappingData.lineItems[source.droppableId]
        const destLineItem = mappingData.lineItems[destination.droppableId]
        const updatedSourceJiraKeys = Array.from(sourceLineItem.jiraKeys)
        updatedSourceJiraKeys.splice(source.index, 1)
        const updatedSourceLineItem = {
          ...sourceLineItem,
          jiraKeys: updatedSourceJiraKeys,
        }

        const updatedDestJiraKeys = Array.from(destLineItem.jiraKeys)
        updatedDestJiraKeys.splice(destination.index, 0, draggableId)
        const updatedDestLineItem = {
          ...destLineItem,
          jiraKeys: updatedDestJiraKeys,
        }

        const updatedState = {
          ...mappingData,
          lineItems: {
            ...mappingData.lineItems,
            [updatedSourceLineItem.id]: updatedSourceLineItem,
            [updatedDestLineItem.id]: updatedDestLineItem,
          },
        }

        setMappingData(updatedState)
        onUpdate(updatedState)
      }
    },
    [mappingData, setMappingData, onUpdate]
  )

  const handleChangeName = useCallback(
    (id, newName) => {
      const newMappingData = {
        ...mappingData,
        lineItems: {
          ...mappingData.lineItems,
          [id]: {
            ...mappingData.lineItems[id],
            name: newName,
          },
        },
      }

      setMappingData(newMappingData)
    },
    [mappingData, setMappingData]
  )

  const handleChangeSaved = useCallback(() => {
    onUpdate(mappingData)
  }, [mappingData, onUpdate])

  const handleDelete = useCallback(
    (idToDelete) => {
      var newLineItemIds = Array.from(mappingData.lineItemIds)
      newLineItemIds.splice(
        newLineItemIds.findIndex((id) => id === idToDelete),
        1
      )

      const unassignedItem = getUnassignedItem(mappingData.lineItems)

      var newMappingData = {
        ...mappingData,
        lineItems: {
          ...mappingData.lineItems,
          // move items from deleted item to 'Unassigned' item
          [unassignedItem.id]: {
            ...unassignedItem,
            jiraKeys: [
              ...unassignedItem.jiraKeys,
              ...mappingData.lineItems[idToDelete].jiraKeys,
            ],
          },
        },
        lineItemIds: newLineItemIds,
      }
      delete newMappingData.lineItems[idToDelete]

      setMappingData(newMappingData)
      onUpdate(newMappingData)
    },
    [mappingData, setMappingData, onUpdate]
  )

  const handleExpandCollapse = useCallback(
    (id, expanded) => {
      const updatedLineItem = {
        ...mappingData.lineItems[id],
        isExpanded: expanded,
      }

      const updatedState = {
        ...mappingData,
        lineItems: {
          ...mappingData.lineItems,
          [updatedLineItem.id]: updatedLineItem,
        },
      }

      setMappingData(updatedState)
    },
    [mappingData, setMappingData]
  )

  const handleToggleBillable = useCallback(
    (id, billable) => {
      const updatedLineItem = {
        ...mappingData.lineItems[id],
        billable: billable,
      }

      const updatedState = {
        ...mappingData,
        lineItems: {
          ...mappingData.lineItems,
          [updatedLineItem.id]: updatedLineItem,
        },
      }

      setMappingData(updatedState)
    },
    [mappingData, setMappingData]
  )

  const handleToggleEditing = useCallback(
    (id, editing) => {
      const updatedLineItem = {
        ...mappingData.lineItems[id],
        isEditing: editing,
      }

      const updatedState = {
        ...mappingData,
        lineItems: {
          ...mappingData.lineItems,
          [updatedLineItem.id]: updatedLineItem,
        },
      }

      setMappingData(updatedState)
    },
    [mappingData, setMappingData]
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      }}>
        <UnmappedItemsDroppable
          disabled={disabled}
          lineItem={getUnassignedItem(mappingData.lineItems)}
          workItems={getUnmappedWorkItems(
            mappingData.lineItems,
            mappingData.jiraIssues
          )}
        />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
          sx={{ ml: 1, }}
        >
          {mappingData.lineItemIds.map((columnId) => {
            const lineItem = mappingData.lineItems[columnId]
            if (lineItem && !lineItem.isUnassignedItem) {
              return (
                <LineItemDroppable
                  key={lineItem.id}
                  disabled={disabled}
                  lineItem={lineItem}
                  workItems={getMappedWorkItems(
                    lineItem,
                    mappingData.jiraIssues
                  )}
                  onChangeName={handleChangeName}
                  onChangeSaved={handleChangeSaved}
                  onDelete={handleDelete}
                  onExpandCollapse={handleExpandCollapse}
                  onToggleBillable={handleToggleBillable}
                  onToggleEditing={handleToggleEditing}
                />
              )
            }

            return null
          })}
        </Box>
      </div>
    </DragDropContext>
  )
}

export default WorkItemMapper
