import { useMemo, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import SaveSubmitResetButtons from './SaveSubmitResetButtons'
import LoadingState from '../utils/LoadingState'

const SaveSubmitEditsControl = ({
  isEdited,
  isValidForSave,
  isValidForSubmit,
  submitValidationMessage,
  loadingState,
  onReset,
  onSave,
  onSubmit,
  submitted,
  saveState,
  saveError,
  submitState,
  submitError,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleClose = () => setDialogOpen(false)
  const handleSubmit = () => {
    onSubmit()
    setDialogOpen(false)
  }

  const loadingOrSaving = useMemo(() => {
    return loadingState === LoadingState.pending || saveState === LoadingState.pending
  }, [loadingState, saveState])

  const resetDisabled = useMemo(() => {
    return loadingOrSaving || !isEdited || submitted
  }, [loadingOrSaving, isEdited, submitted])

  const saveDisabled = useMemo(() => {
    return loadingOrSaving || !isEdited || !isValidForSave || submitted
  }, [loadingOrSaving, isEdited, isValidForSave, submitted])

  const submitDisabled = useMemo(() => {
    return loadingOrSaving || !isValidForSubmit || submitted
  }, [loadingOrSaving, isValidForSubmit, submitted])

  let errorMessage = undefined
  if (saveError) {
    errorMessage = `${saveError}`
  }
  if (submitError) {
    if (saveError) {
      errorMessage = `${errorMessage} | ${submitError}`
    }
    errorMessage =  `${errorMessage === undefined ? "" : errorMessage + " "}${submitError}`
  }

  return (
    <>
      <SaveSubmitResetButtons
        errorMessage={errorMessage}
        onReset={onReset}
        onSave={onSave}
        onSubmit={() => setDialogOpen(true)}
        resetDisabled={resetDisabled}
        saveDisabled={saveDisabled}
        submitDisabled={submitDisabled}
        submitValidationMessage={submitValidationMessage}
        saveState={saveState}
        submitState={submitState}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Changes cannot be made after you submit.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button aria-label="cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button aria-label="confirm" onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SaveSubmitEditsControl
