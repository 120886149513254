import SelectorButtons from "../primitives/SelectorButtons"

export const viewModes = {
  expanded: 'expanded',
  collapsed: 'collapsed',
  people: 'people',
}

const TimeCostReportViewModeSelector = ({
  onViewModeChange,
  viewMode,
}) => {

  const buttonLabels = {
    [viewModes.collapsed]: 'Items',
    [viewModes.expanded]: 'Details',
    [viewModes.people]: 'People',
  }

  const buttons = Object.entries(buttonLabels).map(([mode, label]) => ({
    children: label,
    onClick: () => onViewModeChange(mode),
    selected: viewMode === mode
  }))

  return <SelectorButtons buttons={buttons} sx={{ float: 'left', mr: 2 }} />
}

export default TimeCostReportViewModeSelector
