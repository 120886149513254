import Page from '../primitives/Page'

const PageNotAuthorised = () => (
  <Page>
    <div>
      You are not authorised to access the requested page. Please contact
      support at <a href="support@awa.kiwi">support@awa.kiwi</a>.
    </div>
  </Page>
)

export default PageNotAuthorised
