import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import resetProjectState from './resetProjectState'
import api from '../utils/api'
import LoadingState from '../utils/LoadingState'

const initialState = {
  loadingState: LoadingState.idle,
  data: null,
  updateState: LoadingState.idle,
  updateError: null,
}

export const fetchWorkItemMapping = createAsyncThunk(
  'fetchWorkItemMapping',
  async ({ projectId, getTokenCallback }, { getState }) => {
    return await api.getData(
      api.endpoints(getState().client.name).projects.v2.mapping(projectId),
      getTokenCallback
    )
  }
)

export const updateWorkItemMapping = createAsyncThunk(
  'updateWorkItemMapping',
  async ({ payload, projectId, getTokenCallback }, { getState }) => {
    return await api.put(
      api.endpoints(getState().client.name).projects.v2.mapping(projectId),
      payload,
      getTokenCallback
    )
  }
)

const slice = createSlice({
  name: 'workItemMapping',
  initialState,
  reducers: {
    reset(state, action) {
      return initialState
    },
    resetSaveError(state, action) {
      state.updateState = initialState.updateState
      state.updateError = initialState.updateError
    },
  },
  extraReducers: {
    [fetchWorkItemMapping.pending]: (state, action) => {
      state.loadingState = LoadingState.pending
    },
    [fetchWorkItemMapping.rejected]: (state, action) => {
      state.loadingState = LoadingState.rejected
      state.data = action.error.message
    },
    [fetchWorkItemMapping.fulfilled]: (state, action) => {
      state.loadingState = LoadingState.fulfilled
      state.data = action.payload
    },
    [updateWorkItemMapping.pending]: (state, action) => {
      state.updateState = LoadingState.pending
    },
    [updateWorkItemMapping.rejected]: (state, action) => {
      state.updateState = LoadingState.rejected
      state.updateError = 'Failed to save changes.'
    },
    [updateWorkItemMapping.fulfilled]: (state, action) => {
      state.updateState = LoadingState.fulfilled
    },
    [resetProjectState]: (state, action) => {
      return initialState
    },
  },
})

export const { reset, resetSaveError } = slice.actions

export const selectMappingData = (state) => state.workItemMapping.data
export const selectLoadingState = (state) => state.workItemMapping.loadingState
export const selectUpdateError = (state) => state.workItemMapping.updateError
export const selectUpdateState = (state) => state.workItemMapping.updateState

export default slice.reducer
