import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import ProjectClientContactDialog from './ProjectClientContactDialog'
import ProjectInvoiceRecipientsDialog from './ProjectInvoiceRecipientsDialog'

import {
  fetchProject,
  selectProject,
  selectLoadingState,
} from '../slices/project'
import LoadingState from '../utils/LoadingState'

const ContractConfigurationContainer = ({ projectId, view: GridRow }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const loadingState = useSelector(selectLoadingState)

  const [clientContactDialogOpen, setClientContactDialogOpen] = useState(false)
  const [invoiceRecipientsDialogOpen, setInvoiceRecipientsDialogOpen] =
    useState(false)

  useEffect(() => {
    if (projectId !== null && loadingState === LoadingState.idle) {
      dispatch(fetchProject({ getTokenCallback, projectId }))
    }
  }, [loadingState, dispatch, getTokenCallback, projectId])

  const clientContactLabel =
    loadingState === LoadingState.idle || loadingState === LoadingState.pending
      ? 'loading...'
      : loadingState === LoadingState.rejected
        ? 'Error. Failed to load.'
        : project && project.clientContact
          ? project.clientContact
          : 'None'

  const invoiceRecipientsLabel =
    loadingState === LoadingState.idle || loadingState === LoadingState.pending
      ? 'loading...'
      : loadingState === LoadingState.rejected
        ? 'Error. Failed to load.'
        : project && project.invoiceRecipients
          ? `${project.invoiceRecipients.length} invoice ${project.invoiceRecipients.length === 1 ? 'email' : 'emails'
          }`
          : '0 invoice emails'

  const clientContactRowProps = {
    buttonProps: {
      children: 'Contact',
      disabled: loadingState !== LoadingState.fulfilled,
      onClick: () => setClientContactDialogOpen(true),
    },
    dialogComponent: () =>
      <ProjectClientContactDialog
        open={clientContactDialogOpen}
        onClose={() => setClientContactDialogOpen(false)}
      />,
    label: clientContactLabel,
  }

  const invoiceRecipientRowProps = {
    buttonProps: {
      children: 'Emails',
      disabled: loadingState !== LoadingState.fulfilled,
      onClick: () => setInvoiceRecipientsDialogOpen(true),
    },
    dialogComponent: () =>
      <ProjectInvoiceRecipientsDialog
        open={invoiceRecipientsDialogOpen}
        onClose={() => setInvoiceRecipientsDialogOpen(false)}
      />,
    label: invoiceRecipientsLabel,
  }

  return <>
    <GridRow {...clientContactRowProps} />
    <GridRow {...invoiceRecipientRowProps} />
  </>
}

export default ContractConfigurationContainer
