import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NumberParam, useQueryParam } from 'use-query-params'
import { Box, Container, Divider } from '@mui/material'

import InvoicingContainer from '../containers/InvoicingContainer'
import PeriodSelector from '../containers/PeriodSelector'
import ProjectSelector from '../containers/ProjectSelector'

import Page from '../primitives/Page'
import PageTitle from '../primitives/PageTitle'

import { selectCurrentAndPastPeriods, selectLoadingState as selectPeriodsLoadingState } from '../slices/periods'
import { selectLoadingState as selectProjectsLoadingState, selectProjectsMap } from '../slices/projects'
import resetProjectState from '../slices/resetProjectState'

import LoadingState from '../utils/LoadingState'
import { getPrettyName } from '../utils/periodUtils'
import { selectActiveInvoiceLabel, selectIsActiveInvoiceADraft } from '../slices/invoiceDetails'

const InvoicingPageContentContainer = () => {
  const dispatch = useDispatch()

  const periods = useSelector(selectCurrentAndPastPeriods)
  const projects = useSelector(selectProjectsMap)
  const periodsLoadingState = useSelector(selectPeriodsLoadingState)
  const projectsLoadingState = useSelector(selectProjectsLoadingState)
  const activeInvoiceLabel = useSelector(selectActiveInvoiceLabel)
  const isDraftInvoice = useSelector(selectIsActiveInvoiceADraft)

  const [periodId] = useQueryParam('periodId', NumberParam)
  const [projectId] = useQueryParam('projectId', NumberParam)

  const handleChangePeriod = useCallback(() => {
    dispatch(resetProjectState())
  }, [dispatch])
  const handleChangeProject = useCallback(() => {
    dispatch(resetProjectState())
  }, [dispatch])

  useEffect(() => {
    if (periodsLoadingState === LoadingState.fulfilled && periods && periodId) {
      if (isDraftInvoice) {
        document.title = `New Invoice - ${getPrettyName(periods, periodId)}`
      } else {
        document.title = `Invoice ${activeInvoiceLabel} - ${getPrettyName(periods, periodId)}`
      }
    } else {
      document.title = 'Invoicing'
    }
  }, [
    isDraftInvoice,
    periodsLoadingState,
    projectsLoadingState,
    periods,
    projects,
    periodId,
    projectId,
    activeInvoiceLabel,
  ])

  useEffect(() => {
    if (!projectId) {
      dispatch(resetProjectState())
    }
  }, [dispatch, projectId])

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
        <ProjectSelector
          onChange={handleChangeProject}
          hideSpinner
          sx={{
            display: 'inline-flex',
            float: 'left',
            maxWidth: 700,
          }}
        />
        <Box sx={{ mt: '4.5px' }}>
          {' '}
          {/* Included to account for weird css on period selector - TODO investigate */}
          <PeriodSelector onChange={handleChangePeriod} />
        </Box>
      </Box>
      <Divider sx={{ my: 1.5 }} />
      {projectsLoadingState === LoadingState.fulfilled && periodsLoadingState === LoadingState.fulfilled && periodId ? (
        <Box sx={{ clear: 'both', display: 'block', pt: 1 }}>
          <InvoicingContainer periodId={periodId} projectId={projectId} />
        </Box>
      ) : null}
    </>
  )
}

const InvoicingPage = () => (
  <Page>
    <Container
      disableGutters
      maxWidth="xl"
      // Remove SX to recenter page - This will be done as one change across all pages.
      sx={{ marginLeft: 0, marginRight: 'auto', paddingLeft: 0, paddingRight: 'auto' }}
    >
      <PageTitle>Invoicing</PageTitle>
      <InvoicingPageContentContainer />
    </Container>
  </Page>
)

export default InvoicingPage
