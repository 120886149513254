import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { Dialog, DialogActions, DialogContent } from '@mui/material'

import { selectProjectCandidates } from '../slices/projectCandidates'
import {
  updateProjectRates,
  selectProjectRates,
  selectLoadingState as selectProjectRatesLoadingState,
  selectUpdateError,
  selectUpdateState,
  resetSaveError,
  reset,
} from '../slices/projectRates'

import LoadingState from '../utils/LoadingState'
import {
  areAllRatesValid,
  areAnyRatesChanged,
  convertToSaveRatesRequestBody,
} from '../utils/rateUtils'

import DialogTitleWithClose from '../views/DialogTitleWithClose'
import RatesEditor from '../views/RatesEditor'
import SaveEditsControl from '../views/SaveEditsControl'

const ProjectStaffRatesDialog = ({
  projectJiraKey,
  projectId,
  onClose,
  open,
}) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const projectCandidates = useSelector(selectProjectCandidates)
  const projectRates = useSelector(selectProjectRates)
  const projectRatesLoadingState = useSelector(selectProjectRatesLoadingState)
  const updateError = useSelector(selectUpdateError)
  const updateState = useSelector(selectUpdateState)

  const [editedRates, setEditedRates] = useState({})

  const handleChangeEditedRates = useCallback(
    (newEditedRates) => {
      setEditedRates(newEditedRates)
      dispatch(resetSaveError())
    },
    [dispatch, setEditedRates]
  )

  const resetEdits = useCallback(() => {
    setEditedRates({})
    dispatch(resetSaveError())
  }, [dispatch, setEditedRates])

  const saveRates = useCallback(() => {
    dispatch(
      updateProjectRates({
        projectId: projectId,
        getTokenCallback,
        projectRates: convertToSaveRatesRequestBody(
          editedRates,
          projectJiraKey
        ),
      })
    )
  }, [
    dispatch,
    projectId,
    projectJiraKey,
    getTokenCallback,
    editedRates,
  ])

  // reset to refresh project rates after update
  useEffect(() => {
    if (updateState === LoadingState.fulfilled) {
      setEditedRates({})
      dispatch(reset())
    }
  }, [dispatch, updateState])

  const isEdited = useMemo(() => areAnyRatesChanged(projectRates, editedRates), [editedRates, projectRates])
  const isSaving = useMemo(() => updateState === LoadingState.pending, [updateState])
  const isValid = useMemo(() => areAllRatesValid(editedRates), [editedRates])

  if (projectId === null) {
    return null
  } else {
    return (
      <Dialog onClose={onClose} open={open} maxWidth={false}>
        <DialogTitleWithClose onClose={onClose} title="Staff Rates" />
        <DialogContent dividers>
          <RatesEditor
            disabled={isSaving}
            editedRates={editedRates}
            onChangeEditedRates={handleChangeEditedRates}
            projectRates={projectRates}
            projectCandidates={projectCandidates}
          />
        </DialogContent>
        <DialogActions sx={{ py: 1.5 }}>
          <SaveEditsControl
            isEdited={isEdited}
            isValid={isValid}
            loadingState={projectRatesLoadingState}
            onReset={resetEdits}
            onSave={saveRates}
            updateError={updateError}
            updateState={updateState}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default ProjectStaffRatesDialog
