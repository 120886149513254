import { validateDateString, validateStaffRate } from '../utils/validation'

export const areAllRatesValid = (rates) =>
  Object.keys(rates).every(name =>
    Object.keys(rates[name]).every(date =>
      validateDateString(date) &&
      validateStaffRate(rates[name][date])
    )
  )

export const areAnyRatesChanged = (projectRates, editedRates) =>
  Object.keys(editedRates).some(name =>
    Object.keys(editedRates[name]).some(date =>
      !projectRates[name]
      || projectRates[name][date] !== editedRates[name][date]
    )
  )

export const convertToSaveRatesRequestBody = (editedRates, projectJiraKey) => {
  const requestBody = []

  Object.keys(editedRates).forEach(name => {
    Object.keys(editedRates[name]).forEach(date => {
      requestBody.push({
        jiraKey: projectJiraKey,
        staffMember: name,
        fromDate: date,
        projectRate: parseFloat(editedRates[name][date]),
      })
    })
  })

  return requestBody
}

export const getAllRates = (projectRates, editedRates) => {
  const allRates = {}
  const allStaff = new Set([...Object.keys(projectRates), ...Object.keys(editedRates)])
  allStaff.forEach(name => {
    allRates[name] = {}
    const existingDates = projectRates[name] || {}
    const editedDates = editedRates[name] || {}
    const allDates = new Set([...Object.keys(existingDates), ...Object.keys(editedDates)])
    const allDatesSorted = Array.from(allDates).sort()
    allDatesSorted.forEach(date => { allRates[name][date] = editedDates[date] || existingDates[date] })
  })
  return allRates
}

export const getNewStaff = (projectRates, editedRates) => {
  const projectStaff = Object.keys(projectRates)
  const editedStaff = Object.keys(editedRates)
  return editedStaff.filter((staffName) => !projectStaff.includes(staffName))
}

export const getStaffWithNoRates = (candidateStaff, projectRates) => {
  const projectStaff = Object.keys(projectRates)
  return candidateStaff.filter((staffName) => !projectStaff.includes(staffName))
}
