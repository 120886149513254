import { Box, Tab, Tabs } from '@mui/material'
import colours from '../styles/colours'

const ProjectDetailsTabs = ({ tab, setTab }) => (
  <Box sx={{ backgroundColor: colours.awaGrayUltralight, mt: 1 }}>
    <Tabs
      value={tab || 0}
      indicatorColor="primary"
      textColor="primary"
      TabIndicatorProps={{ style: { display: 'none' } }}
      onChange={(_event, value) => setTab(value)}
    >
      <Tab label="Proposal" />
      <Tab label="Delivery" />
      <Tab label="Closure" disabled />
    </Tabs>
  </Box>
)

export default ProjectDetailsTabs
