import { useMemo } from 'react'

import LoadingState from '../utils/LoadingState'
import InvoiceDetailsTable from '../views/InvoicingDetailsTable'

const InvoiceProjectDetailsTable = ({
  projectLoadingState,
  project,
  clientName,
  ...otherProps
}) => {
  const contact = useMemo(() => {
    if (projectLoadingState === LoadingState.fulfilled && project) {
      return project.clientContact
    } else if (
      projectLoadingState === LoadingState.idle ||
      projectLoadingState === LoadingState.pending
    ) {
      return 'loading...'
    } else {
      return ''
    }
  }, [projectLoadingState, project])

  const invoiceRecipients = useMemo(() => {
    if (projectLoadingState === LoadingState.fulfilled && project) {
      return project.invoiceRecipients
    } else if (
      projectLoadingState === LoadingState.idle ||
      projectLoadingState === LoadingState.pending
    ) {
      return ['loading...']
    } else {
      return ['']
    }
  }, [projectLoadingState, project])



  return (
    <InvoiceDetailsTable
      clientName={clientName}
      clientContact={contact}
      invoiceRecipients={invoiceRecipients}
      {...otherProps}
    />
  )
}

export default InvoiceProjectDetailsTable
