import config from '../config'

export function anyEdits(localMapping, originalMapping) {
  let strippedOriginalMapping = [
    ...originalMapping.lineItems.map((lineItem) => {
      let strippedItem = { ...lineItem }
      delete strippedItem.deletable
      return strippedItem
    }),
  ]
  let strippedChanges = transformToUpdateMappingPayload(localMapping)
  strippedChanges = strippedChanges.map((item) => ({
    ...item,
    workItems: item.workItems.map((workItem) => workItem.jiraKey),
  }))

  strippedOriginalMapping.sort((a, b) => a.id - b.id)
  strippedChanges.sort((a, b) => a.id - b.id)

  return (
    JSON.stringify(strippedOriginalMapping) !== JSON.stringify(strippedChanges)
  )
}

export function findErrors(data) {
  var anyErrors = false

  for (let lineItem of Object.values(data.lineItems)) {
    var error = !lineItem.name && !lineItem.isEditing

    if (!error) {
      for (let otherLineItem of Object.values(data.lineItems).filter(
        (item) => item.id !== lineItem.id
      )) {
        if (lineItem.name === otherLineItem.name) {
          error = true
          break
        }
      }
    }

    if (error) {
      anyErrors = true
    }

    lineItem.error = error
  }

  return anyErrors
}

export function getMappedWorkItems(lineItem, jiraIssues) {
  return lineItem.jiraKeys
    ? lineItem.jiraKeys.map((jiraKey) => jiraIssues[jiraKey])
    : []
}

export function getUnassignedItem(lineItems) {
  const lineItemList = Object.values(lineItems)
  return lineItemList && lineItemList.length > 0
    ? lineItemList.find((item) => item.isUnassignedItem)
    : null
}

export function getUnmappedWorkItems(lineItems, jiraIssues) {
  const unassignedLineItem = getUnassignedItem(lineItems)
  return unassignedLineItem
    ? getMappedWorkItems(getUnassignedItem(lineItems), jiraIssues)
    : []
}

export function isMappingValid(data) {
  let hasProposalItem = false
  let hasUnassignedItem = false

  for (let item of Object.values(data.lineItems)) {
    if (item.name === config.proposalLineItemName) {
      hasProposalItem = true
    } else if (item.name === config.unassignedLineItemName) {
      hasUnassignedItem = true
    }

    if (hasProposalItem && hasUnassignedItem) {
      return true
    }
  }

  return false
}

export function transformToLocalMapping(data) {
  var local = {
    jiraIssues: {},
    lineItems: {},
    lineItemIds: [],
  }

  for (let item of data.lineItems) {
    var localItem = {
      ...item,
      id: item.id.toString(),
      jiraKeys: item.workItems,
      isExpanded: true,
      isProposalItem: item.name === config.proposalLineItemName,
      isUnassignedItem: item.name === config.unassignedLineItemName,
    }
    delete localItem.workItems

    local.lineItems[item.id.toString()] = localItem
    local.lineItemIds.push(item.id.toString())
  }

  local.lineItemIds.sort((a, b) => {
    let proposalDevelopmentSort =
      (local.lineItems[b].name === config.proposalLineItemName ? 1 : 0) -
      (local.lineItems[a].name === config.proposalLineItemName ? 1 : 0)
    let billableSort =
      (local.lineItems[a].billable ? 1 : 0) -
      (local.lineItems[b].billable ? 1 : 0)
    let idSort = Number(a) - Number(b)
    return proposalDevelopmentSort || billableSort || idSort
  })

  for (let workItem of data.workItems) {
    let jiraKey = workItem.jiraKey
    local.jiraIssues[jiraKey] = workItem
  }

  return local
}

export function transformToUpdateMappingPayload(data) {
  let payload = []
  for (let lineItem of Object.values(data.lineItems)) {
    let id = Number(lineItem.id)
    payload.push({
      id: lineItem.new ? null : id,
      name: lineItem.name,
      billable: lineItem.billable,
      workItems: lineItem.jiraKeys.map((key) => data.jiraIssues[key]),
    })
  }
  return payload
}
