import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Input, InputAdornment } from '@mui/material'

import colours from '../styles/colours'
import { sanitise, validate } from '../utils/moneyEditUtils'

const MoneyEditCell = ({ id, value, api, field }) => {
  const ref = useRef()
  const [error, setError] = useState(!validate(value))

  const handleChange = useCallback(
    (event) => {
      const value = sanitise(event.target.value)
      var isValid = validate(value)
      setError(!isValid)

      const editProps = {
        value: value,
        error: !isValid,
      }
      api.unstable_setEditCellProps({ id, field, props: editProps })
    },
    [api, field, id]
  )

  useEffect(() => {
    // put cursor into element on component mount.
    if (ref.current) {
      ref.current.focus()
    }
  }, [])

  return (
    <Box
      data-testid="money-edit-cell"
      sx={{
        alignItems: 'center',
        backgroundColor: error ? colours.lightRed : null,
        color: error ? colours.darkRed : null,
        display: 'flex',
        px: 1,
      }}
    >
      <Input
        inputRef={ref}
        value={value !== null ? value : ''}
        onChange={handleChange}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
      />
    </Box>
  )
}

export default MoneyEditCell
