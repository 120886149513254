import config from '../config'

function addSums(dest, src, numWeeks) {
  dest.rateError = dest.rateError || src.rateError
  dest.totalHours += src.totalHours
  dest.totalWip += src.totalWip
  dest.periodHours += src.periodHours
  dest.periodWip += src.periodWip
  for (let i = 0; i < numWeeks; i++) {
    dest.weeklyHours[i] += src.weeklyHours[i]
    dest.weeklyWips[i] += src.weeklyWips[i]
  }
  return dest
}

function newSums(name, rate, numWeeks) {
  return {
    name: name,
    rate: rate,
    rateError: false,
    mappingError: false,
    totalHours: 0,
    totalWip: 0,
    periodHours: 0,
    periodWip: 0,
    weeklyHours: Array(numWeeks).fill(0),
    weeklyWips: Array(numWeeks).fill(0),
  }
}

function calculateSums(lineItems, workItemLogs, numWeeks) {
  let totalSums = newSums('Project Totals', '', numWeeks)

  // make line items list
  let lineItemsSums = lineItems.map((lineItem) => {
    const sums = newSums(lineItem.name, '', numWeeks)
    sums.id = lineItem.id
    sums.workItemsSums = []
    sums.mappingError = lineItem.name === config.unassignedLineItemName
    return sums
  })

  // Put line item for unasssigned work items at the end
  lineItemsSums.sort((a, b) =>
    a.name === config.unassignedLineItemName
      ? 1
      : b.name === config.unassignedLineItemName
      ? -1
      : 0
  )

  let peopleSums = []
  let workItemsSums = []
  workItemLogs.forEach((workItem) => {
    // define a work item sums for this work item
    let workItemSums = newSums(
      `${workItem.jiraKey} ${workItem.name}`,
      '',
      numWeeks
    )
    workItemSums.workLogsSums = []

    workItem.timeCostLogs.forEach((workLog) => {
      // calculate work log sums
      const workLogPeriodHours = workLog.hours.reduce(
        (sum = 0, hourValue) => sum + hourValue
      )
      const workLogPeriodWip = workLogPeriodHours * workLog.rate
      const weeklyHours = workLog.hours
      const weeklyWips = weeklyHours.map(
        (hourValue) => workLog.rate * hourValue
      )
      let workLogSums = {
        name: workLog.person,
        rate: workLog.rate,
        rateError: workLog.rate == null,
        totalHours: workLog.totalHours,
        totalWip: workLog.totalWip,
        periodHours: workLogPeriodHours,
        periodWip: workLogPeriodWip,
        weeklyHours: weeklyHours,
        weeklyWips: weeklyWips,
      }

      // define a personSums for any new people, or fetch existing
      let personSums = peopleSums.find(
        (personSums) =>
          personSums.name === workLog.person && personSums.rate === workLog.rate
      )
      if (!personSums) {
        personSums = newSums(workLog.person, workLog.rate, numWeeks)
        peopleSums.push(personSums)
      }

      // add work log sums to person sums
      personSums = addSums(personSums, workLogSums, numWeeks)

      // add work log sums to work item sums
      workItemSums = addSums(workItemSums, workLogSums, numWeeks)

      // add work log sums to work item's list of work log sums.
      workItemSums.workLogsSums.push(workLogSums)
    })

    // find the line item sums (generated above) for the work item
    let lineItemSums = lineItemsSums.find(
      (lineItemSums) => lineItemSums.id === workItem.lineItemId
    )

    // add work item sums to line item sums
    lineItemSums = addSums(lineItemSums, workItemSums, numWeeks)
    lineItemSums.workItemsSums.push(workItemSums)

    // work item sums to list of work item sums
    workItemsSums.push(workItemSums)

    // add work item sums to total sums
    totalSums = addSums(totalSums, workItemSums, numWeeks)
  })

  // if none are unassigned or there are no hours recorded against them, remove unassigned line item from sums
  const unassignedLineItemIndex = lineItemsSums.findIndex(
    (sums) => sums.name === config.unassignedLineItemName
  )
  if (
    unassignedLineItemIndex >= 0 &&
    lineItemsSums[unassignedLineItemIndex].totalHours === 0
  ) {
    lineItemsSums.splice(unassignedLineItemIndex, 1)
  }

  return { lineItemsSums, peopleSums, totalSums, workItemsSums }
}

export default calculateSums
