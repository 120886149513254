import { Button, Grid, Typography } from '@mui/material'
import { useClientContext } from '../clientContext'
import colours from '../styles/colours'

import BudgetConfigurationContainer from '../containers/BudgetConfigurationContainer'
import ContactsConfigurationContainer from '../containers/ContactsConfigurationContainer'
import ContractConfigurationContainer from '../containers/ContractConfigurationContainer'
import PurchaseOrdersConfigurationContainer from '../containers/PurchaseOrdersConfigurationContainer'
import RatesConfigurationContainer from '../containers/RatesConfigurationContainer'

const ProjectConfigGridButton = (props) => (
  <Button
    color="secondary"
    size="small"
    sx={{
      border: 'none',
      borderRadius: 0,
      ml: 2,
      mr: 1,
      width: 90,
    }}
    variant="contained"
    {...props}
  />
)

const ProjectConfigGridText = (props) => (
  <Typography
    sx={{
      backgroundColor: colours.awaGrayUltralight,
      borderBottom: `1px solid ${colours.awaGray75}`,
      color: colours.awaGray75,
      textAlign: 'right',
      height: 30,
      pt: .5,
      pr: 1,
      overflow: 'hidden',
      width: 250,
    }}
    {...props}
  />
)

// TODO: refactor this whole project config grid container
// as multiple sub-containers reuse the same components and same api calls
export const ProjectConfigGridRow = ({
  buttonProps,
  dialogComponent: Dialog,
  label,
}) => <>
    <Grid item xs={3}>
      <ProjectConfigGridButton {...buttonProps} />
      <Dialog />
    </Grid>
    <Grid item xs={9}>
      <ProjectConfigGridText>{label}</ProjectConfigGridText>
    </Grid>
  </>

const ProjectConfigurationGrid = ({ isProposal, project }) => {
  const { purchaseOrdersEnabled } = useClientContext()

  return (
    <Grid container spacing={2} sx={{ maxWidth: 460, mt: 1 }}>
      <RatesConfigurationContainer
        projectId={project.projectId}
        projectJiraKey={project.jiraKey}
        view={ProjectConfigGridRow}
      />
      {isProposal ? null : (
        <>
          <BudgetConfigurationContainer projectId={project.projectId} view={ProjectConfigGridRow} />
          {purchaseOrdersEnabled &&
            <PurchaseOrdersConfigurationContainer projectId={project.projectId} view={ProjectConfigGridRow} />
          }
          <ContractConfigurationContainer projectId={project.projectId} view={ProjectConfigGridRow} />
          <ContactsConfigurationContainer projectId={project.projectId} view={ProjectConfigGridRow} />
        </>
      )}
    </Grid>
  )
}

export default ProjectConfigurationGrid
