import colours from "../colours";

const MuiTab = {
  styleOverrides: {
    root: ({theme}) => ({
      backgroundColor: theme.palette.editable.light, 
      transition: 'background-color 0.12s ease',
      ':hover': {
        backgroundColor: theme.palette.editable.main,
      },
      ':hover&.Mui-selected': {
        backgroundColor: colours.white,
      },
      '&.Mui-selected': {
        backgroundColor: colours.white,
      }
    }),
    scrollButtons: ({theme}) => ({
      backgroundColor: theme.palette.editable.light, 
    })
  }
}

export default MuiTab