import flipLogo from '../assets/flip-logo.png'

const flipConfig = {
  clientAccess: 'FLiP Staff',
  devName: 'Project Hub (Development)',
  testName: 'Project Hub (Test)',
  prodName: 'Project Hub',
  devLook: {
    light: 'flipLightBlue',
    main: 'flipBlue',
  },
  testLook: {
    light: 'flipLightBlue',
    main: 'flipBlue',
  },
  prodLook: {
    light: 'flipLightBlue',
    main: 'flipBlue',
  },
  logoProps: {
    alt: 'FLiP Homes Logo',
    src: flipLogo,
  },
  cwbColumns: [
    'jiraKey',
    'clientName',
    'projectName',
    'projectManager',
    'projectDirector',
    'clientManager',
    'initialBudget',
    'historicVariations',
    'projectBudget',
    'internalBudget',
    'projectedProfit',
    'projectedMargin',
    'billedToDate',
    'historicExpenses',
    'profitToDate',
    'marginToDate',
    'invoiceAmount',
    'periodExpenses',
    'periodProfit',
    'periodMargin',
    'expectedVariations',
    'percentComplete',
    'provisionalAmount',
    'writeUpOffAmount',
    'writeUpOffToDate',
    'agedDebt',
    'agedDebt3Months',
    'projectStartDate',
    'projectEndDate',
  ],
  colours: {
    flipBlue: '#00329E',
    flipBlue150: '#00194F',
    flipBlue25: '#BFCCE7',
    grey25: '#C0C0C0',
    grey50: '#808080',
    grey75: '#404040',
    white: '#FFFFFF',
  },
  palette: {
    primary: {
      light: 'grey25',
      main: 'grey50',
      dark: 'grey75',
    },
    secondary: {
      light: 'flipBlue25',
      main: 'flipBlue',
      dark: 'flipBlue150',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
        }),
      },
    },
  },
}

export default flipConfig
