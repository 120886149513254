const MissingRateIcon = ({ color }) => (
  <svg
    width="24"
    height="26"
    viewBox="0 0 24 26"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.2102 2.36364C17.8618 2.36364 21.6023 6.07455 21.6023 10.6364C21.6023 13.9455 19.6481 16.77 16.8068 18.0936V23.6364H8.41477V20.0909H7.21591C5.88517 20.0909 4.81819 19.0391 4.81819 17.7273V14.1818H3.01989C2.51637 14.1818 2.22864 13.5909 2.51637 13.2245L4.81819 10.2345C5.04597 5.85 8.69051 2.36364 13.2102 2.36364ZM9.99727 7.55182H12.3111C12.3231 7.19727 12.431 6.92545 12.6468 6.73636C12.8626 6.55909 13.1263 6.46454 13.438 6.46454C13.8097 6.46454 14.1214 6.58273 14.3372 6.79545C14.553 7.02 14.6489 7.32727 14.6489 7.68182C14.6489 8.06 14.5649 8.37909 14.3731 8.65091C14.2053 8.92273 13.9535 9.15909 13.6418 9.34818C13.0304 9.75 12.6108 10.1045 12.383 10.4236C12.1432 10.7309 12.0114 11.2273 12.0114 11.8182H14.4091C14.4091 11.4518 14.457 11.1564 14.5649 10.9436C14.6728 10.7191 14.8766 10.5182 15.1764 10.3291C15.7159 10.0455 16.1594 9.70273 16.5071 9.23C16.8548 8.75727 17.0346 8.27273 17.0346 7.68182C17.0346 6.78364 16.7109 6.06273 16.0635 5.53091C15.4281 4.99909 14.553 4.72727 13.438 4.72727C12.395 4.72727 11.5558 4.96364 10.9084 5.42455C10.285 5.90909 9.9733 6.58273 9.98529 7.51636L9.99727 7.55182ZM12.0114 15.3636H14.4091V13H12.0114V15.3636ZM13.2102 0C7.70745 0 3.15176 4.04182 2.49239 9.33636L0.622163 11.8182H0.586197L0.56222 11.8536C-0.0971546 12.7518 -0.181075 13.9336 0.334436 14.9145C0.766027 15.73 1.5333 16.2973 2.42046 16.4745V17.7273C2.42046 19.9136 3.955 21.7691 6.01705 22.3009V26H19.2045V19.5C22.2017 17.5264 24 14.2527 24 10.6364C24 4.76273 19.1566 0 13.2102 0Z" />
  </svg>
)

export default MissingRateIcon
