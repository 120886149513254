import { useCallback, useState } from 'react'
import { Box, Button, Popover } from '@mui/material'

const CompanyWorkbookStepButton = ({
  className,
  color,
  disabled,
  label,
  popoverLabel,
  onConfirm,
  variant,
  unsavedChanges,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleConfirm = useCallback(() => {
    onConfirm()
    handleClose()
  }, [onConfirm])

  const open = Boolean(anchorEl)

  // If a popover label is defined, use that. Otherwise, default to the standard label
  // This allows a more detailed action button in the popover than a simple one shown (such as 'next' or 'back')
  const displayedPopoverLabel = popoverLabel || label

  return (
    <Box sx={{ float: 'left', mr: 1 }}>
      <Button
        color={color}
        className={className}
        disabled={disabled}
        onClick={handleClick}
        size="small"
        variant={variant}
      >
        {label}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ pb: 0.5, pr: 1, pt: 1 }}>
          <Box sx={{ pl: 1 }}>
            Are you sure?
            {unsavedChanges ? (
              <Box component="span" sx={{ color: 'error.dark' }}>
                {' '}
                You have unsaved changes.
              </Box>
            ) : null}
          </Box>
          <Button
            size="small"
            onClick={handleClose}
            sx={{ ml: 0.5 }}
            data-testid="cancelButton"
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={handleConfirm}
            sx={{ ml: 0.5 }}
            data-testid="confirmButton"
          >
            {displayedPopoverLabel}
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

export default CompanyWorkbookStepButton
