const MuiInput = {
  styleOverrides: {
    root: {
      fontSize: '0.875em',
      '& .MuiSelect-select': {
        height: 19,
      },
    },
    input: ({ theme }) => ({
      paddingLeft: theme.spacing(0.5),
    }),
  },
}

export default MuiInput
