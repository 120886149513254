import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Typography } from '@mui/material'

import {
  generateCompanyWorkbook,
  selectAreAnyLinesEdited,
  selectIsWorkbookReady,
  selectStatusChangeError,
  selectStatusChangeState,
  setCompanyWorkbookDatabaseStatus,
} from '../slices/companyWorkbook'

import LoadingState from '../utils/LoadingState'
import {
  convertWorkbookFrontendStatusToDatabaseStatus,
  workbookFrontendStatus,
} from '../utils/steps'

import CompanyWorkbookGenerationMonitor from '../views/CompanyWorkbookGenerationMonitor'
import CompanyWorkbookStepButton from '../views/CompanyWorkbookStepButton'
import {
  selectActiveWorkbookStep,
  selectCurrentlyAvailableWorkbookSteps,
} from '../slices/compoundSelectors/activeStepSelector'

const CompanyWorkbookStepController = ({ periodId }) => {
  const { getAccessTokenSilently: getTokenCallback } = useAuth0()
  const dispatch = useDispatch()

  const isReady = useSelector(selectIsWorkbookReady)
  const unsavedChanges = useSelector(selectAreAnyLinesEdited)

  const statusChangeState = useSelector(selectStatusChangeState)
  const statusChangeError = useSelector(selectStatusChangeError)
  const stepMap = useSelector(selectCurrentlyAvailableWorkbookSteps)
  const activeStep = useSelector(selectActiveWorkbookStep)

  const generateWorkbook = useCallback(() => {
    dispatch(
      generateCompanyWorkbook({
        periodId,
        getTokenCallback,
      })
    )
  }, [dispatch, getTokenCallback, periodId])

  const setWorkbookStatus = useCallback(
    (newDatabaseStatus) => {
      dispatch(
        setCompanyWorkbookDatabaseStatus({
          newDatabaseStatus,
          getTokenCallback,
        })
      )
    },
    [dispatch, getTokenCallback]
  )

  /**
   * Attempt to change the current workbook status to the provided new frontend workbook status
   */
  const changeCurrentStatus = useCallback(
    (newFrontendStatus) => {
      // Generation logic is handled differently from status changing logic, hence
      // call a different function that results in a different backend API call
      if (newFrontendStatus === workbookFrontendStatus.building) {
        generateWorkbook()
      }
      // For all other cases, statuses are set with the common endpoint exposed by setWorkbookStatus
      else {
        // Need to provide a database status rather than a frontend status as setWorkbookStatus is calling the backend
        const newDatabaseStatus = convertWorkbookFrontendStatusToDatabaseStatus(
          stepMap,
          newFrontendStatus
        )
        setWorkbookStatus(newDatabaseStatus)
      }
    },
    [generateWorkbook, setWorkbookStatus, stepMap]
  )

  /**
   * Attempt to transition the current workbook step to the next step
   */
  const handleNextStep = useCallback(() => {
    const newFrontendStatus = activeStep?.forwardAction?.newFrontendStatus
    // Ensure the current step does have a next action
    if (typeof newFrontendStatus !== 'string') {
      console.error(
        `Attempted to transition to the next status from ${activeStep.status} but frontend status an invalid type.`
      )
    } else {
      changeCurrentStatus(newFrontendStatus)
    }
  }, [activeStep, changeCurrentStatus])

  /**
   * Attempt to transition the current workbook back to the previous step
   */
  const handlePreviousStep = useCallback(() => {
    const newFrontendStatus = activeStep?.backAction?.newFrontendStatus
    // Ensure the current step does have a back action
    if (typeof newFrontendStatus !== 'string') {
      console.error(
        `Attempted to transition to the previous status from ${activeStep.status} but frontend status an invalid type.`
      )
    } else {
      changeCurrentStatus(newFrontendStatus)
    }
  }, [activeStep, changeCurrentStatus])

  if (!isReady || !activeStep || !stepMap) {
    return null
  }

  const isSaving = statusChangeState === LoadingState.pending

  return (
    <Box sx={{ my: 2 }}>
      <CompanyWorkbookStepButton
        disabled={isSaving || activeStep?.backAction === undefined}
        label={
          // If a back action label is defined, use this. Otherwise default to 'Back'
          typeof activeStep?.backAction?.buttonLabel !== 'string'
            ? 'Back'
            : activeStep.backAction.buttonLabel
        }
        popoverLabel={activeStep?.backAction?.confirmButtonLabel}
        onConfirm={handlePreviousStep}
        unsavedChanges={unsavedChanges}
      />
      <CompanyWorkbookStepButton
        color="secondary"
        variant="contained"
        disabled={isSaving || activeStep?.forwardAction === undefined}
        label={
          // If a back action label is defined, use this. Otherwise default to 'Next'
          typeof activeStep?.forwardAction?.buttonLabel !== 'string'
            ? 'Next'
            : activeStep.forwardAction.buttonLabel
        }
        popoverLabel={activeStep?.forwardAction?.confirmButtonLabel}
        onConfirm={handleNextStep}
        unsavedChanges={unsavedChanges}
      />
      {statusChangeState === LoadingState.rejected ? (
        <Typography
          sx={{
            color: 'error.main',
            paddingTop: 0.5,
          }}
        >
          {statusChangeError || 'Failed to update status.'}
        </Typography>
      ) : null}
      <CompanyWorkbookGenerationMonitor
        periodId={periodId}
        sx={{ float: 'left', mt: 1 }}
      />
    </Box>
  )
}

export default CompanyWorkbookStepController
