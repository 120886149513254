import { TableRow } from '@mui/material'
import { ChargeabilityRowHeaderCells } from './ChargeabilityRowHeaderCells'
import { ChargeabilityRowDataCells } from './ChargeabilityRowDataCells'

const ChargeabilityDataRow = ({
  rowHeaderValues,
  rowDataValues,
  resourceInfo,
  hideColours,
  currentTimeSpan,
  enableTooltips,
}) => {
  return (
    <TableRow>
      <ChargeabilityRowHeaderCells rowHeaderValues={rowHeaderValues} enableTooltips={enableTooltips} />
      <ChargeabilityRowDataCells
        rowDataValues={rowDataValues}
        resourceInfo={resourceInfo}
        hideColours={hideColours}
        currentTimeSpan={currentTimeSpan}
        enableTooltips={enableTooltips}
      />
    </TableRow>
  )
}
export default ChargeabilityDataRow
