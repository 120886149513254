import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'

import WorkItemDroppable from './WorkItemDroppable'
import colours from '../styles/colours'

const UnmappedItemsDroppable = ({ disabled, lineItem, workItems }) => {

  const anyTimeUnmapped = useMemo(() => {
    for (let item of workItems) {
      if (item.hours > 0) {
        return true
      }
    }
    return false
  }, [workItems])

  return (
    <Box sx={{
      backgroundColor: anyTimeUnmapped ? 'warning.light' : 'inherit',
      border: anyTimeUnmapped ? 'none' : '1px solid',
      borderColor: colours.awaGray30,
      borderRadius: 1,
      display: 'flex',
      flexDirection: 'column',
      mt: 1,
      p: 1,
    }}>
      <Typography
        fontSize={14}
        textAlign='center'
        sx={{ m: 0, p: 1, pt: 0.5 }}
      >
        Unassigned{' '}
        <span style={{ fontWeight: 'normal' }}>
          ({workItems.length} {workItems.length === 1 ? 'item' : 'items'})
        </span>
      </Typography>
      <WorkItemDroppable
        disabled={disabled}
        direction="vertical"
        lineItem={lineItem}
        workItems={workItems}
      />
    </Box>
  )
}

export default UnmappedItemsDroppable
