import { Box, Button } from '@mui/material'

const SaveAndResetButtons = ({
  errorMessage,
  onReset,
  onSave,
  resetDisabled,
  saveDisabled,
}) => (
  <>
    <Button
      aria-label="save"
      color="secondary"
      disabled={saveDisabled}
      onClick={onSave}
      size="small"
      sx={{ float: 'right', ml: 1, mt: 1 }}
      variant="contained"
    >
      Save
    </Button>
    <Button
      aria-label="reset"
      disabled={resetDisabled}
      onClick={onReset}
      size="small"
      sx={{ float: 'right', ml: 1, mt: 1 }}
    >
      Reset
    </Button>
    {errorMessage && (
      <Box
        component="span"
        sx={{
          color: 'error.main',
          clear: 'both',
          display: 'block',
          float: 'right',
          mt: 0.5,
          verticalAlign: 'center',
        }}
      >
        {errorMessage}
      </Box>
    )}
  </>
)

export default SaveAndResetButtons
