import { useCallback } from 'react'
import { Typography } from '@mui/material'
import InvoiceRecipientControl from './InvoiceRecipientControl'

const InvoiceRecipientsEditor = ({
  disabled,
  originalInvoiceRecipients,
  invoiceRecipients,
  onChangeInvoiceRecipients,
}) => {

  const handleChangeEmail = useCallback((idx, value) => {
    var newList = [...invoiceRecipients]
    newList[idx] = value
    onChangeInvoiceRecipients(newList)
  }, [invoiceRecipients, onChangeInvoiceRecipients])

  const handleDeleteEmail = useCallback((idx) => {
    var newList = [...invoiceRecipients]
    newList.splice(idx, 1)
    onChangeInvoiceRecipients(newList)
  }, [invoiceRecipients, onChangeInvoiceRecipients])

  return (
    <div style={{ width: 380 }}>
      <div style={{ width: "100%" }}>
        <Typography
          sx={{
            color: 'rgba(0, 0, 0, 0.54) ',
            float: 'left',
            fontSize: '16px',
            m: 1,
            mb: 0,
            width: 250,
          }}
        >
          Email
        </Typography>
      </div>
      <div style={{ clear: 'both' }}>
        {invoiceRecipients.map((email, idx) => (
          <InvoiceRecipientControl
            key={idx}
            id={idx}
            disabled={disabled}
            originalEmail={
              originalInvoiceRecipients &&
                originalInvoiceRecipients.length > idx
                ? originalInvoiceRecipients[idx]
                : null
            }
            email={email}
            onChangeEmail={(value) => handleChangeEmail(idx, value)}
            onDelete={() => handleDeleteEmail(idx)}
          />
        ))}
      </div>
    </div >
  )
}

export default InvoiceRecipientsEditor
