import SelectorDropdown from '../primitives/SelectorDropdown'
import { presetViewLabels } from '../utils/dataGridPresetViewUtils'

const CompanyWorkbookPresetViewSelector = ({
  handlePresetViewSelect,
  presetView,
  presetViewList,
  ...props
}) => {

  const options = presetViewList.map(viewName => ({
    children: presetViewLabels[viewName],
    value: viewName,
  }))

  const dropdownProps = {
    label: 'View',
    onChange: (event) => handlePresetViewSelect(event.target.value),
    options,
    sx: { float: 'right' },
    value: presetView,
    ...props,
  }

  return <SelectorDropdown {...dropdownProps} />
}

export default CompanyWorkbookPresetViewSelector
