import { CssBaseline } from '@mui/material'
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material/styles'

import { useClientContext } from './clientContext'
import components from './styles/components'

const theme = createTheme({ components })

const createPalette = (colours = {}, customPalette = {}) => {
  const palette = {}
  Object.entries(customPalette).forEach(([key, tokens = {}]) => {
    const updatedTokens = {}

    Object.entries(tokens).forEach(([tokenName, tokenValue]) => {
      Object.assign(updatedTokens, { [tokenName]: colours[tokenValue] })
    })

    Object.assign(palette, { [key]: updatedTokens })
  })
  return palette
}

const ClientThemeProvider = ({ children }) => {
  const {
    colours,
    palette: clientPalette = {},
    components: clientComponents = {},
  } = useClientContext()

  const clientTheme = createTheme(theme, {
    palette: createPalette(colours, clientPalette),
    components: clientComponents,
  })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={clientTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
export default ClientThemeProvider
