import { useState } from 'react'

import {
  Box,
  Paper,
  Typography
} from '@mui/material'

import ProjectBasicInfo from './ProjectBasicInfo'
import ProjectConfigurationGrid from './ProjectConfigurationGrid'
import ProjectDetailsTabs from './ProjectDetailsTabs'
import colours from '../styles/colours'

const ProjectDetails = ({ project }) => {

  const [tab, setTab] = useState(1)

  return (
    <Box sx={{ ml: 1, mt: 1 }}>
      <ProjectBasicInfo project={project} />
      <Paper sx={{ mb: 1, mr: 2, mt: 2 }}>
        <ProjectDetailsTabs tab={tab} setTab={setTab} />
        <Box sx={{ p: 1 }}>
          <Typography
            color={colours.awaGray}
            sx={{
              borderBottom: '1px solid',
              borderColor: 'secondary.main',
              boxShadow: `0 4px ${colours.awaGray15}`,
              maxWidth: 500,
              ml: 2, mr: 1, mt: 1
            }}
          >
            PROJECT CONFIGURATION
          </Typography>
          <ProjectConfigurationGrid isProposal={tab === 0} project={project} />
        </Box>
      </Paper>
    </Box>
  )
}

export default ProjectDetails
