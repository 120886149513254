import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import ReportTable from './ReportTable'
import TimeCostReportScopeSelector, {
  scopes,
} from './TimeCostReportScopeSelector'
import TimeCostReportViewModeSelector, {
  viewModes,
} from './TimeCostReportViewModeSelector'
import ToggleSwitch from '../primitives/ToggleSwitch'
import transformTimeCostReport from '../utils/transformTimeCostReport'
import validateTimeCostReport from '../utils/validateTimeCostReport'

const TimeCostReport = ({ data }) => {
  const [table, setTable] = useState(null)
  const [displayWipNotHours, setDisplayWip] = useState(false)
  const [showHistoricWork, setShowHistoricWork] = useState(true)
  const [scope, setScope] = useState(scopes.all)
  const [viewMode, setViewMode] = useState(viewModes.collapsed)

  useEffect(() => {
    if (validateTimeCostReport(data)) {
      setTable(
        transformTimeCostReport(data, {
          displayWipNotHours,
          hideHistoricWork: !showHistoricWork,
          scope,
          viewMode,
        })
      )
    }
  }, [data, displayWipNotHours, showHistoricWork, scope, viewMode])

  if (table === null) {
    return null
  }

  return (
    <>
      <Box sx={{
        clear: 'both',
        float: 'left',
        mt: 1,
      }}>
        <TimeCostReportViewModeSelector
          onViewModeChange={(mode) => setViewMode(mode)}
          viewMode={viewMode}
        />
        <TimeCostReportScopeSelector
          onScopeChange={(scope) => setScope(scope)}
          scope={scope}
        />
        <ToggleSwitch
          checked={showHistoricWork}
          onChange={(event) => setShowHistoricWork(event.target.checked)}
          onText="ALL"
          offText="PERIOD"
          testId="hideHistoricWorkSwitch"
          sx={{ float: 'left', mr: 2 }}
        />
        <ToggleSwitch
          checked={displayWipNotHours}
          onChange={(event) => setDisplayWip(event.target.checked)}
          onText="FEES"
          offText="HOURS"
          testId="toggleDisplayWipSwitch"
          sx={{ float: 'left', mr: 2 }}
        />
      </Box>
      <ReportTable
        alternateRowColours={viewMode !== viewModes.expanded}
        data={table}
      />
    </>
  )
}

export default TimeCostReport
